import styles from './styles.module.scss';

type Props = {
  title: string;
  description?: string;
  step?: string;
};

export default function LibraryStep({
  title = '',
  description = '',
  step,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.stepWrapper}>
        <h3>{step}</h3>
      </div>
      <div className={styles.textWrapper}>
        {title && <h4>{title}</h4>}
        {description && <p>{description}</p>}
      </div>
    </div>
  );
}
