// import { useTranslation } from 'react-i18next';
import image from '@assets/img/signIn.jpg';
import styles from './styles.module.scss';

const SignUpRightSide = () => {
  // const { t } = useTranslation('signInContent');
  const styleProps = `linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  ), url(${image})`;
  return (
    <>
      <div className={styles.imageWrap} style={{ backgroundImage: styleProps }}>
        {/* <div className={styles.textWrap}>
          <h4>{t('quote')}</h4>
          <p> {t('donator')}</p>
        </div> */}
      </div>
    </>
  );
};

export default SignUpRightSide;
