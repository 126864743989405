/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import vectorUnderline from '@assets/img/vector-underline.svg';
import { collection } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire';
import styles from './styles.module.scss';
import LibraryStep from '@components/LibraryStep';
import LibraryCaption from '@components/LibraryCaption';
import UserCard from '@components/UserCard';
import Button, { BUTTON_TYPE } from '@components/Button';
import { useWindowSize } from '@hooks/useWindowResize';
import { getCssVariable } from '@utils/index';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import Footer from '@components/Footer';

export default function Library() {
  const { t } = useTranslation('library');
  const db = useFirestore();
  const navigate = useNavigate();
  const publicUsers = collection(db, 'publicUsers');
  const windowSize = useWindowSize();
  const [chunk, setChunk] = React.useState<any[]>([]);
  const [chunk2, setChunk2] = React.useState<any[]>([]);
  const { data: user } = useUser();
  const { data: users } = useFirestoreCollectionData(publicUsers, {
    initialData: [],
    idField: 'id',
  });

  const memoUsers = React.useMemo(
    () => (users?.length ? users.filter((el) => el.isHidden === false) : []),
    [users]
  );
  const memoUsers2 = React.useMemo(
    () => (users?.length ? users.filter((el) => el.isHidden === true) : []),
    [users]
  );

  React.useEffect(() => {
    const screenResolution = windowSize.width;
    if (screenResolution > 1200) {
      setChunk(memoUsers.slice());
      setChunk2(memoUsers2.slice());
    }
    if (screenResolution < 1200) {
      setChunk(memoUsers.slice(0));
      setChunk2(memoUsers2.slice(0, 2));
    }
    if (screenResolution < 580) {
      setChunk2(memoUsers2.slice(0, 1));
    }
  }, [windowSize.width, memoUsers, memoUsers2]);

  function goToSignUp() {
    navigate(ROUTES.CHOOSE_PROFILE);
  }

  return (
    <>
      <p className={styles.caption}>{t('publicUser.caption.discover')}</p>
      <LibraryCaption
        beginPhrase={t('publicUser.caption.title.beginPhrase')}
        underlineWords={t('publicUser.caption.title.underline')}
        closingPhrase={t('publicUser.caption.title.closingPhrase')}
        vector={vectorUnderline}
      />
      <div className={styles.stepContainer}>
        <LibraryStep
          step="1"
          title={t('publicUser.steps.step1.title')}
          description={t('publicUser.steps.step1.description')}
        />
        <LibraryStep
          step="2"
          title={t('publicUser.steps.step2.title')}
          description={t('publicUser.steps.step2.description')}
        />
        <LibraryStep
          step="3"
          title={t('publicUser.steps.step3.title')}
          description={t('publicUser.steps.step3.description')}
        />
      </div>

      <div className={`${styles.cardContainer}`}>
        {chunk.map((userCardInfo: any, index: number) => {
          return (
            <UserCard
              firstname={userCardInfo?.firstname}
              describeYourself={userCardInfo?.describeYourself}
              voiceType={userCardInfo?.voiceType}
              key={index}
              // key={userCardInfo?.URL_recording}
              language={userCardInfo?.language}
              urlRec={userCardInfo?.URL_recording}
              profilePic={userCardInfo?.profile_photo}
              isLogged={user && user}
              avatarPhoto={userCardInfo?.avatar_photo}
              isModelReady={userCardInfo?.isModelReady}
            />
          );
        })}
      </div>
      <div className={`${styles.cardContainer} ${styles.hidden}`}>
        <div className={styles.bannerBlocker}>
          <h3>{t('publicUser.bannerBlocker.title')}</h3>
          <Button
            onClick={goToSignUp}
            style={{
              backgroundColor: getCssVariable('white'),
              color: getCssVariable('neutral600'),
            }}
            type={BUTTON_TYPE.PRIMARY}
          >
            {t('publicUser.bannerBlocker.label')}
          </Button>
        </div>
        {chunk2.map((userCardInfo: any) => {
          return (
            <UserCard
              firstname={userCardInfo?.firstname}
              describeYourself={userCardInfo?.describeYourself}
              voiceType={userCardInfo?.voiceType}
              key={Math.random()}
              language={userCardInfo?.language}
              urlRec={userCardInfo?.URL_recording}
              profilePic={userCardInfo?.profile_photo}
              isHidden
              avatarPhoto={userCardInfo?.avatar_photo}
              isModelReady={userCardInfo?.isModelReady}
            />
          );
        })}
      </div>
      <Footer />
    </>
  );
}
