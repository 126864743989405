import * as React from 'react';
import FeatherIcon from 'feather-icons-react';
import styles from './styles.module.scss';
type IProps = {
  audioElem: any;
  isPlaying: boolean;
  currentSong: any;
  setIsPlaying: (value: boolean) => void;
};

export default function Player({
  currentSong,
  audioElem,
  isPlaying,
  setIsPlaying,
}: IProps) {
  const clickRef = React.useRef<HTMLDivElement>(null);
  const PlayPause = (e: any) => {
    e.stopPropagation();
    setIsPlaying(!isPlaying);
  };

  const [totalTime, setTotalTime] = React.useState(0);
  const checkWidth = (e: any) => {
    if (
      clickRef?.current &&
      audioElem?.current &&
      audioElem?.current?.currentTime
    ) {
      const width = clickRef?.current?.clientWidth as any;
      const offset = e.nativeEvent.offsetX;
      const divprogress = (offset / width) * 100;

      audioElem.current.currentTime = ((divprogress / 100) *
        currentSong.length) as any;
    }
  };

  const getDuration = React.useCallback(() => {
    if (audioElem?.current && audioElem?.current?.currentTime) {
      audioElem.current.currentTime = 0;
      audioElem.current.removeEventListener('timeupdate', getDuration);
      setTotalTime(audioElem?.current?.duration);
    }
  }, [audioElem]);

  React.useEffect(() => {
    audioElem.current.ondurationchange = function () {
      audioElem.current.duration !== Infinity &&
        setTotalTime(audioElem.current.duration);
    };

    audioElem.current.addEventListener('loadedmetadata', () => {
      if (audioElem.current && audioElem.current.duration === Infinity) {
        audioElem.current.currentTime = 1e101;
        audioElem.current.addEventListener('timeupdate', getDuration);
      }
    });
  }, [audioElem, getDuration]);

  function convertTime() {
    if (audioElem && audioElem?.current && audioElem?.current?.duration) {
      let seconds = (totalTime % 60) as any;
      const foo = totalTime - seconds;
      const minutes = foo / 60;
      if (seconds < 10) {
        seconds = '0' + seconds.toFixed().toString();
      } else {
        seconds = seconds.toFixed().toString();
      }
      const fixedCurrentTime = minutes + ':' + seconds;
      return fixedCurrentTime;
    }
  }

  function stopProp(e: any) {
    e.stopPropagation();
  }

  return (
    <div className={styles.container} onClick={stopProp}>
      <div className={styles.controls}>
        {!isPlaying || currentSong.progress === 100 ? (
          <FeatherIcon
            strokeWidth="2px"
            aria-hidden="true"
            color={'#000'}
            onClick={PlayPause}
            icon="play"
            size="18"
            className={`${styles.btnAction} ${styles.pp}`}
          />
        ) : (
          <FeatherIcon
            strokeWidth="2px"
            aria-hidden="true"
            color={'#000'}
            onClick={PlayPause}
            icon="pause"
            size="18"
            className={`${styles.btnAction} ${styles.pp}`}
          />
        )}
      </div>
      <div className={styles.navigation}>
        <div
          className={styles.navigationWrapper}
          onClick={checkWidth}
          ref={clickRef}
        >
          <div
            className={styles.seekBar}
            style={{
              width:
                currentSong.progress === 100
                  ? '0%'
                  : `${currentSong.progress + '%'}`,
            }}
          ></div>
        </div>
      </div>
      <div className={styles.time}>{convertTime()}</div>
    </div>
  );
}
