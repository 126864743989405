import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import ChangePassword from '@components/ChangePassword';
import DeleteAccount from '@components/DeleteAccount';
import Header from '@components/Header';
import Loader from '@components/Loader';
import PersonalDetails from '@components/PersonalDetails';
import { ROUTES } from '@routes/routes';
import { signOut } from 'firebase/auth';
import FeatherIcon from 'feather-icons-react';
import { doc, getDoc } from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useFirestore, useAuth } from 'reactfire';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import WhatsNext from '@components/WhatsNext';
import RequestedVoices from '@components/RequestedVoices';
// import { UserCardContext } from '@contexts/UserCardContext';

export default function Dashboard() {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();
  const auth = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const [privateData, setPrivateData] = React.useState<any>({});
  const [avatarPhoto, setAvatarPhoto] = React.useState('');
  const [userLang, setUserLang] = React.useState('');
  const [userType, setUserType] = React.useState('');

  const getUserData = React.useCallback(async () => {
    if (user) {
      // USER DATA
      const userRef = doc(db, 'users', user?.uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setUserLang(userData?.language);
        setUserType(userData?.userType);
        setAvatarPhoto(userData?.avatar_photo);
        setIsLoading(false);

        if (userData?.userType === 'donor') {
          navigate(ROUTES.FINISH_REGISTRATION);
        }
      } else {
        console.log('No such document!');
      }

      const libraryRef = doc(db, `users/${user.uid}/private`, 'library');
      const docLibrarySnap = await getDoc(libraryRef);
      if (docLibrarySnap.exists()) {
        const userPrivateData = docLibrarySnap.data();
        setPrivateData(userPrivateData);
        // setUserData(userPrivateData);
      }
    }
  }, [user, db, navigate]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleSignOut = async (auth: any) => {
    await signOut(auth);
    navigate(ROUTES.SIGN_IN);
  };

  function goToFav() {
    navigate(ROUTES.FAVORITE);
  }
  function goToLibrary() {
    navigate(ROUTES.BASE);
  }
  function goToSignIn() {
    navigate(ROUTES.SIGN_IN);
  }
  function goToSignUp() {
    navigate(ROUTES.CHOOSE_PROFILE);
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
        style={{ alignItems: 'unset' }}
        firstButton={
          user ? (
            <Button
              style={{
                color: 'var(--neutral400)',
              }}
              onClick={goToLibrary}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.MENU}
              size={BUTTON_SIZE.WITH_ICON}
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  icon="message-square"
                  color={'var(--neutral400)'}
                  size="20"
                />
              }
            >
              {t('header.loggedUser.library')}
            </Button>
          ) : (
            <Button
              onClick={goToSignIn}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
            >
              {t('header.unLoggedUser.signIn')}
            </Button>
          )
        }
        secondButton={
          user ? (
            <Button
              style={{
                color: 'var(--neutral400)',
              }}
              onClick={goToFav}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.MENU}
              size={BUTTON_SIZE.WITH_ICON}
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  icon="heart"
                  color={'var(--neutral400)'}
                  size="20"
                />
              }
            >
              {t('header.loggedUser.favorite')}
            </Button>
          ) : (
            <Button
              onClick={goToSignUp}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.OUTLINE}
            >
              {t('header.unLoggedUser.getVoice')}
            </Button>
          )
        }
      />

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.user}>
            <h3>
              {t('welcome', {
                value: user?.displayName?.split(' ')[0],
              })}
            </h3>

            <Button
              onClick={() => handleSignOut(auth)}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
            >
              {t('logOut')}
            </Button>
          </div>
          <div className={styles.blockTitle}>
            <h4>{t('whatsNext.title')}</h4>
          </div>
          <WhatsNext
            user={user}
            userRequestedData={privateData?.requestedVoices}
          />

          <div className={styles.blockTitle}>
            <h4>{t('requestedVoices.title')}</h4>
          </div>
          <RequestedVoices user={user} db={db} />

          <div className={styles.blockTitle}>
            <h4>{t('personalDetails.title')}</h4>
          </div>
          <PersonalDetails
            userData={privateData}
            db={db}
            user={user}
            auth={auth}
            avatarPhoto={avatarPhoto}
            userType={userType}
          />
          <div className={styles.blockTitle}>
            <h4>{t('changePassword.title')}</h4>
          </div>
          <ChangePassword db={db} user={user} auth={auth} />
          <div className={styles.blockTitle}>
            <h4>{t('deleteAccount.title')}</h4>
          </div>
          <DeleteAccount
            requestedDeletionAccount={privateData?.requestedDeletionAccount}
            db={db}
            user={user}
            auth={auth}
            userLang={userLang}
            userType={userType}
          />
        </div>
      </div>
    </>
  );
}
