import * as React from 'react';
import styles from './styles.module.scss';
type IProps = {
  left: React.ReactNode;
  right: React.ReactNode;
};

const SplitLayout = ({ left, right }: IProps) => {
  return (
    <main className={styles.splitLayout}>
      <section className={styles.wrapper}>
        <div className={styles.content}>{left}</div>
      </section>

      <section className={styles.wrapper}>
        <div className={styles.content}>{right}</div>
      </section>
    </main>
  );
};

export default SplitLayout;
