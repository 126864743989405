import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Avatar, { AVATAR_SIZE } from '@components/Avatar';
import FeatherIcon from 'feather-icons-react';
import Player from '@components/Player';
// import { getCssVariable } from '@utils/index';

export type UserCardProps = {
  voiceType: string;
  describeYourself?: string;
  firstname: string;
  language: string;
  profilePic?: string;
  openModal?: () => void;
  urlRec: string;
  userId?: string;
  setCardToRemove?: any;
  avatarPhoto: string;
  isModelReady: boolean;
};

export default function UserCardPreview({
  firstname,
  describeYourself,
  voiceType,
  profilePic,
  language,
  urlRec,
  userId,
  setCardToRemove,
  isModelReady,
  avatarPhoto,
  openModal,
}: UserCardProps) {
  const { t } = useTranslation('library');
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentSong, setCurrentSong] = React.useState<any>({ url: urlRec });

  function renderLanguage(lang: string) {
    switch (lang) {
      case 'en-EN':
        return t('userCard.language.en');
      case 'it-IT':
        return t('userCard.language.it');
      case 'es-ES':
        return t('userCard.language.es');
      case 'de-DE':
        return t('userCard.language.de');
      case 'fr-FR':
        return t('userCard.language.fr');
      default:
        return '';
    }
  }

  const audioElem = React.useRef<HTMLAudioElement>(null);

  // function openModalAndSet() {
  //   if (setCardToRemove) {
  //     setCardToRemove(userId);
  //     openModal();
  //   }
  // }

  React.useEffect(() => {
    if (isPlaying) {
      audioElem?.current?.play();
    } else {
      audioElem?.current?.pause();
    }
  }, [isPlaying]);

  const onPlaying = () => {
    if (audioElem && audioElem?.current && audioElem?.current?.duration) {
      const duration = audioElem?.current?.duration as any;
      const ct = audioElem?.current?.currentTime as any;

      setCurrentSong({
        ...currentSong,
        progress: (ct / duration) * 100,
        length: duration,
      });
    }
  };

  function translateVoiceTypeLabel(voice: string) {
    switch (voice) {
      case 'middle':
        return t('userCard.voiceType.middle');
      case 'young':
        return t('userCard.voiceType.young');
      case 'senior':
        return t('userCard.voiceType.senior');
      default:
        return voice;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.dataContainer}>
        <div className={styles.userDataWrapper}>
          <div className={styles.avatarContainer}>
            <Avatar
              name={`${firstname}`}
              image={profilePic ? profilePic : avatarPhoto}
              size={AVATAR_SIZE.MEDIUM}
            />
          </div>
          <div className={styles.userData}>
            {firstname && <h4>{firstname}</h4>}
            <div className={styles.userInfo}>
              <FeatherIcon
                strokeWidth={'2px'}
                aria-hidden="true"
                icon="message-circle"
                size="18"
              />
              <span className={`small ${styles.voiceType}`}>
                {translateVoiceTypeLabel(voiceType)}
              </span>
              <span className={`small ${styles.separator}`}>·</span>

              <FeatherIcon
                strokeWidth={'2px'}
                aria-hidden="true"
                icon="globe"
                size="18"
              />
              <span className="small">{renderLanguage(language)}</span>
            </div>
          </div>

          {/* <div className={styles.like}>
            <FeatherIcon
              strokeWidth={'2px'}
              aria-hidden="true"
              color={getCssVariable('neutral400')}
              fill={'transparent'}
              onClick={openModalAndSet}
              icon="trash-2"
              size="22"
            />
          </div> */}
        </div>
        <div>
          {describeYourself && <p className="small">{describeYourself}</p>}
        </div>
      </div>
      <div className={styles.footerCard}>
        <audio onTimeUpdate={onPlaying} ref={audioElem} src={urlRec} />
        <Player
          currentSong={currentSong}
          audioElem={audioElem}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
      </div>
    </div>
  );
}
