/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
  getAuth,
  sendPasswordResetEmail,
  applyActionCode,
  checkActionCode,
} from 'firebase/auth';
import FeatherIcon from 'feather-icons-react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '@routes/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '@components/Header';
import SplitLayout from '@components/SplitLayout';
import LayoutWrapper from '@components/LayoutWrapper';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import SignUpRightSide from '@components/SignUpRightSide';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import queryString from 'query-string';
import { getCssVariable } from '@utils/index';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import Spinner from '@components/Spinner';
import { useUser } from 'reactfire';
import { Helmet } from 'react-helmet';

export default function RecoverEmail() {
  const auth = getAuth();
  // const url = `${window.location.protocol}//${window.location.host}${ROUTES.RECOVER_EMAIL}`;
  const { t } = useTranslation('recoverEmail');
  const [successfullyRevert, setSuccessfullyRevert] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');
  const [error, setError] = React.useState();
  const location = useLocation();
  const { code }: any = queryString.parse(location.search) ?? {
    code: '',
  };

  const { data: user } = useUser();
  const navigate = useNavigate();

  function goToSignIn() {
    navigate(ROUTES.SIGN_IN);
  }

  function goToLibrary() {
    navigate(ROUTES.BASE);
  }

  function handleRecoverEmail(auth: any, actionCode: any) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    setIsLoading(true);
    let restoredEmail: any = null;
    // Confirm the action code is valid.
    checkActionCode(auth, actionCode)
      .then((info) => {
        // Get the restored email address.
        restoredEmail = info['data']['email'];

        // Revert to the old email.
        return applyActionCode(auth, actionCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail
        setIsLoading(false);
        setSuccessfullyRevert(true);
        // TODO: Display a confirmation message to the user.
        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        setUserEmail(restoredEmail);
        sendPasswordResetEmail(auth, restoredEmail)
          .then(() => {
            console.log('ok');
            // Password reset confirmation sent. Ask user to check their email.
          })
          .catch((error: any) => {
            setError(error);
          });
      })
      .catch((error) => {
        // Invalid code.
        setError(error);
      });
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
        firstButton={
          user ? (
            <Button
              onClick={goToLibrary}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
              size={BUTTON_SIZE.WITH_ICON}
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  icon="message-square"
                  color={'var(--blue)'}
                  size="20"
                />
              }
            >
              Library
            </Button>
          ) : (
            <Button
              onClick={goToSignIn}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
            >
              Sign in
            </Button>
          )
        }
      />

      <LayoutWrapper>
        <SplitLayout
          left={
            <>
              <div className={styles.wrapper}>
                {successfullyRevert ? (
                  <>
                    <SuccessMessageOnBoarding
                      title={t('successMessage.title')}
                      message={t('successMessage.message', {
                        value: userEmail,
                      })}
                      leadingIcon={
                        <Button
                          style={{
                            backgroundColor: getCssVariable('orange-light'),
                            cursor: 'default',
                          }}
                          type={BUTTON_TYPE.RECORDING}
                          size={BUTTON_SIZE.ONLY_ICON}
                          leadingIcon={
                            <FeatherIcon
                              strokeWidth="1px"
                              icon="mail"
                              color={'var(--orange)'}
                              size="40"
                            />
                          }
                        ></Button>
                      }
                    />

                    <Button
                      onClick={goToSignIn}
                      type={BUTTON_TYPE.PRIMARY}
                      fullWidth
                    >
                      {t('backToLogin')}
                    </Button>
                  </>
                ) : (
                  <div className={styles.container}>
                    <HeaderOnBoardingWrapper
                      title={t('titlePage')}
                      description={t('description')}
                    />
                    <Button
                      onClick={() => handleRecoverEmail(auth, code)}
                      type={BUTTON_TYPE.PRIMARY}
                      fullWidth
                    >
                      <div className={!isLoading ? styles.label : styles.hide}>
                        <p>{t('restoreEmail')}</p>
                      </div>
                      {isLoading && (
                        <div className={styles.spinnerContainer}>
                          <Spinner isWhite isMid />
                        </div>
                      )}
                    </Button>
                    {error && (
                      <div className={styles.errorMessage}>
                        <p>{t('errorsState')}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          }
          right={<SignUpRightSide />}
        />
      </LayoutWrapper>
    </>
  );
}
