/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import Header from '@components/Header';
import { ROUTES } from '@routes/routes';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom';
import { useAuth, useUser } from 'reactfire';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import { useWindowSize } from '@hooks/useWindowResize';
import LibraryLoggedContent from '@components/LibraryLoggedContent';
import LibraryUnLoggedContent from '@components/LibraryUnLoggedContent';

export default function Library() {
  const { t } = useTranslation('library');
  const navigate = useNavigate();
  const { data: user } = useUser();

  const windowSize = useWindowSize();

  function goToFav() {
    navigate(ROUTES.FAVORITE);
  }
  function goToLibrary() {
    navigate(ROUTES.BASE);
  }
  function goToSignIn() {
    navigate(ROUTES.SIGN_IN);
  }
  function goToSignUp() {
    navigate(ROUTES.CHOOSE_PROFILE);
  }

  const routes = [{ path: '/' }];
  const location = useLocation();
  const route = matchRoutes(routes, location);

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta
          name="description"
          content={t('descriptionPage')}
          data-react-helmet="true"
        />
      </Helmet>
      <Header
        style={{ alignItems: 'unset' }}
        firstButton={
          user !== null ? (
            <Button
              isActive={route !== null}
              onClick={goToLibrary}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.MENU}
              size={
                windowSize.width > 500
                  ? BUTTON_SIZE.WITH_ICON
                  : BUTTON_SIZE.ONLY_ICON
              }
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  icon="message-square"
                  color={'var(--blue)'}
                  size="20"
                />
              }
            >
              {t('header.loggedUser.library')}
            </Button>
          ) : (
            <Button
              onClick={goToSignIn}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
            >
              {t('header.unLoggedUser.signIn')}
            </Button>
          )
        }
        secondButton={
          user ? (
            <Button
              style={{
                color:
                  route && route[0].pathname === '/favorite'
                    ? 'var(--blue)'
                    : 'var(--neutral400)',
              }}
              onClick={goToFav}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.MENU}
              size={
                windowSize.width > 500
                  ? BUTTON_SIZE.WITH_ICON
                  : BUTTON_SIZE.ONLY_ICON
              }
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  icon="heart"
                  color={
                    route && route[0].pathname === '/favorite'
                      ? 'var(--blue)'
                      : 'var(--neutral400)'
                  }
                  size="20"
                />
              }
            >
              {t('header.loggedUser.favorite')}
            </Button>
          ) : (
            <Button
              onClick={goToSignUp}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.OUTLINE}
            >
              {t('header.unLoggedUser.getVoice')}
            </Button>
          )
        }
      />

      <div className={styles.container}>
        <div className={styles.wrapper}>
          {user ? <LibraryLoggedContent /> : <LibraryUnLoggedContent />}
        </div>
      </div>
    </>
  );
}
