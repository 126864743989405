import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
// import Cookies from 'js-cookie';
// import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const { t } = useTranslation('library');
  // const [currentLang, setCurrentLang] = React.useState('');
  // const navigate = useNavigate();

  // const setCookie = React.useCallback(
  //   (lang: string) => {
  //     const d = new Date();
  //     d.setTime(d.getTime() + 5184000000);
  //     const expires = 'expires=' + d.toUTCString();
  //     document.cookie = 'lang=' + lang + ';' + expires + ';path=/';
  //     navigate(0);
  //   },
  //   [navigate]
  // );

  // const cookie = Cookies.get('lang');
  // React.useEffect(() => {
  //   if (cookie) {
  //     setCurrentLang(cookie);
  //   }
  // }, [currentLang, setCookie, cookie]);

  const urlPrivacy = 'https://translated.com/riservatezza';

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {/* <div className={styles.languages}>
          <ul>
            <li>
              <span
                onClick={() => setCookie('en')}
                style={{
                  textDecoration: currentLang !== 'en' ? 'underline' : 'unset',
                  pointerEvents: currentLang === 'en' ? 'none' : 'unset',
                  cursor: currentLang === 'en' ? '' : 'pointer',
                }}
              >
                {t('footer.languages.english')}
              </span>
            </li>
            <li>
              <span
                onClick={() => setCookie('it')}
                style={{
                  textDecoration: currentLang !== 'it' ? 'underline' : 'unset',
                  pointerEvents: currentLang === 'it' ? 'none' : 'unset',
                  cursor: currentLang === 'it' ? '' : 'pointer',
                }}
              >
                {t('footer.languages.italian')}
              </span>
            </li>

            <li>
              <span
                onClick={() => setCookie('de')}
                style={{
                  textDecoration: currentLang !== 'de' ? 'underline' : 'unset',
                  pointerEvents: currentLang === 'de' ? 'none' : 'unset',
                  cursor: currentLang === 'de' ? '' : 'pointer',
                }}
              >
                {t('footer.languages.german')}
              </span>
            </li>

            <li>
              <span
                onClick={() => setCookie('es')}
                style={{
                  textDecoration: currentLang !== 'es' ? 'underline' : 'unset',
                  pointerEvents: currentLang === 'es' ? 'none' : 'unset',
                  cursor: currentLang === 'es' ? '' : 'pointer',
                }}
              >
                {t('footer.languages.spanish')}
              </span>
            </li>
            <li>
              <span
                onClick={() => setCookie('fr')}
                style={{
                  textDecoration: currentLang !== 'fr' ? 'underline' : 'unset',
                  pointerEvents: currentLang === 'fr' ? 'none' : 'unset',
                  cursor: currentLang === 'fr' ? '' : 'pointer',
                }}
              >
                {t('footer.languages.french')}
              </span>
            </li>
          </ul>
        </div> */}
        <div className={styles.company}>
          <ul>
            <li>
              <span>{t('footer.company.translated')}</span>
            </li>
            <li>
              <span>{t('footer.company.iva')}</span>
            </li>
            <li>
              <a target={'_blank'} href={urlPrivacy}>
                <span>{t('footer.company.privacy')}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
