import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import FeatherIcon from 'feather-icons-react';
import { getCssVariable } from '@utils/index';

type Props = {
  user: any;
  userRequestedData: any;
};
export default function WhatsNext({ userRequestedData, user }: Props) {
  const { t } = useTranslation('dashboard');

  return (
    <div className={styles.container}>
      <div className={styles.step}>
        <div
          className={`${styles.icon} ${
            userRequestedData?.length > 0 ? styles.icon1Selected : styles.icon1
          } `}
        >
          <FeatherIcon
            strokeWidth="2px"
            color={getCssVariable('white')}
            icon={userRequestedData?.length > 0 ? 'check' : 'bookmark'}
            size="20"
          />
        </div>
        <div
          className={`${styles.content} ${
            userRequestedData?.length > 0 ? styles.done : styles.toDo
          }`}
        >
          <p className="sub-header">{t('whatsNext.step1')}</p>
        </div>
      </div>
      <div className={styles.step}>
        <div
          className={`${styles.icon} ${
            userRequestedData?.length > 0 ? styles.icon2Selected : styles.icon2
          }`}
        >
          <FeatherIcon
            strokeWidth="2px"
            color={getCssVariable(
              userRequestedData?.length > 0 ? 'white' : 'orange'
            )}
            icon="message-square"
            size="20"
          />
        </div>
        <div
          className={`${styles.content} ${
            userRequestedData?.length > 0 ? styles.toDo : styles.opacity
          }`}
        >
          <p className="sub-header bolder">{t('whatsNext.step2.title')}</p>
          {userRequestedData?.length > 0 && (
            <p className="extra-small" style={{ fontWeight: '400' }}>
              {t('whatsNext.step2.description', {
                name: user?.displayName?.split(' ')[0],
              })}
            </p>
          )}
        </div>
      </div>
      <div className={styles.step}>
        <div className={`${styles.icon} ${styles.icon3}`}>
          <FeatherIcon
            strokeWidth="2px"
            color={getCssVariable('orange')}
            icon="heart"
            size="20"
          />
        </div>
        <div className={`${styles.content} ${styles.opacity}`}>
          <p className="sub-header">{t('whatsNext.step3')}</p>
        </div>
      </div>
    </div>
  );
}
