import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAuth, useFirestore, useUser } from 'reactfire';
import Logo from '@components/Logo';
import styles from './styles.module.scss';
import Avatar, { AVATAR_SIZE } from '@components/Avatar';
import { ProfileImageContext } from '@contexts/ProfileImageContext';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
// import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@routes/routes';
import { doc, getDoc } from 'firebase/firestore';
interface Props {
  firstButton?: React.ReactNode;
  secondButton?: React.ReactNode;
  style?: any;
}

const Header = ({ style, firstButton, secondButton }: Props) => {
  const { data: user } = useUser();
  const db = useFirestore();
  // const auth = useAuth();
  const [avatarPhoto, setAvatarPhoto] = React.useState('');
  const { imageURLProfile, setImageURLProfile } =
    React.useContext(ProfileImageContext);
  const storage = getStorage();
  const navigate = useNavigate();
  const storageRef = ref(
    storage,
    `users/${user?.uid}/profilePicture/profile.jpg`
  );

  const getUserData = React.useCallback(async () => {
    if (user) {
      const usersRefPublic = doc(db, 'users', user?.uid);
      const docSnapPublic = await getDoc(usersRefPublic);

      const userDataPublic = docSnapPublic?.data();
      const avatarURL = userDataPublic?.avatar_photo;
      setAvatarPhoto(avatarURL);
    }
  }, [user, db]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  React.useEffect(() => {
    getDownloadURL(storageRef)
      .then((url) => {
        if (url) {
          setImageURLProfile(url);
        }
      })
      .catch((error) => {
        if (error.code === 'storage/object-not-found') {
          setImageURLProfile('');
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goToDashboard() {
    navigate(ROUTES.DASHBOARD);
  }

  return (
    <>
      <header className={`${styles.header}`} style={style}>
        <span className={styles.logo}>
          <Logo full={true} />
        </span>

        <div className={styles.navigation}>
          {firstButton && firstButton}
          {secondButton && secondButton}
        </div>
        {/* <span onClick={handleSignOut}>{'logout'}</span> */}
        {user && (
          <Avatar
            onClick={goToDashboard}
            name={user?.displayName ?? ''}
            image={
              imageURLProfile
                ? imageURLProfile
                : user?.photoURL
                ? user?.photoURL
                : avatarPhoto
            }
            size={AVATAR_SIZE.DEFAULT}
          />
        )}
      </header>
    </>
  );
};

export default Header;
