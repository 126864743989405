import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// COUNTRIES
import countriesEN from '@locales/en/countries.json';
import countries from '@locales/it/countries.json';
import countriesFR from '@locales/fr/countries.json';
import countriesES from '@locales/es/countries.json';
import countriesDE from '@locales/de/countries.json';

// CHOOSE PROFILE

import chooseProfileEN from '@locales/en/chooseProfile.json';
import chooseProfile from '@locales/it/chooseProfile.json';
import chooseProfileFR from '@locales/fr/chooseProfile.json';
import chooseProfileES from '@locales/es/chooseProfile.json';
import chooseProfileDE from '@locales/de/chooseProfile.json';

// SIGN IN
import signInEN from '@locales/en/signIn.json';
import signIn from '@locales/it/signIn.json';
import signInFR from '@locales/fr/signIn.json';
import signInES from '@locales/es/signIn.json';
import signInDE from '@locales/de/signIn.json';

// SIGN UP
import signUpEN from '@locales/en/signUp.json';
import signUp from '@locales/it/signUp.json';
import signUpFR from '@locales/fr/signUp.json';
import signUpES from '@locales/es/signUp.json';
import signUpDE from '@locales/de/signUp.json';

// NOT FOUND
import notFoundEN from '@locales/en/notFound.json';
import notFound from '@locales/it/notFound.json';
import notFoundFR from '@locales/fr/notFound.json';
import notFoundES from '@locales/es/notFound.json';
import notFoundDE from '@locales/de/notFound.json';

// PRIVACY POLICY
import privacyPolicyEN from '@locales/en/privacyPolicy.json';
import privacyPolicy from '@locales/it/privacyPolicy.json';
import privacyPolicyFR from '@locales/fr/privacyPolicy.json';
import privacyPolicyES from '@locales/es/privacyPolicy.json';
import privacyPolicyDE from '@locales/de/privacyPolicy.json';

// PASSWORD RESET
import passwordResetEN from '@locales/en/passwordReset.json';
import passwordReset from '@locales/it/passwordReset.json';
import passwordResetFR from '@locales/fr/passwordReset.json';
import passwordResetES from '@locales/es/passwordReset.json';
import passwordResetDE from '@locales/de/passwordReset.json';

// SIGN IN CONTENT
import signInContentEN from '@locales/en/signInContent.json';
import signInContent from '@locales/it/signInContent.json';
import signInContentFR from '@locales/fr/signInContent.json';
import signInContentES from '@locales/es/signInContent.json';
import signInContentDE from '@locales/de/signInContent.json';

// LIBRARY
import libraryEN from '@locales/en/library.json';
import library from '@locales/it/library.json';
import libraryFR from '@locales/fr/library.json';
import libraryES from '@locales/es/library.json';
import libraryDE from '@locales/de/library.json';

// DASHBOARD
import dashboardEN from '@locales/en/dashboard.json';
import dashboard from '@locales/it/dashboard.json';
import dashboardFR from '@locales/fr/dashboard.json';
import dashboardES from '@locales/es/dashboard.json';
import dashboardDE from '@locales/de/dashboard.json';

// ON BOARDING
import onBoardingEN from '@locales/en/onBoarding.json';
import onBoarding from '@locales/it/onBoarding.json';
import onBoardingFR from '@locales/fr/onBoarding.json';
import onBoardingES from '@locales/es/onBoarding.json';
import onBoardingDE from '@locales/de/onBoarding.json';

// NEW PASSWORD
import newPasswordEN from '@locales/en/newPassword.json';
import newPassword from '@locales/it/newPassword.json';
import newPasswordFR from '@locales/fr/newPassword.json';
import newPasswordES from '@locales/es/newPassword.json';
import newPasswordDE from '@locales/de/newPassword.json';

// THANK YOU
import thankYouEN from '@locales/en/thankYou.json';
import thankYou from '@locales/it/thankYou.json';
import thankYouFR from '@locales/fr/thankYou.json';
import thankYouES from '@locales/es/thankYou.json';
import thankYouDE from '@locales/de/thankYou.json';

// RECOVER EMAIL
import recoverEmailEN from '@locales/en/recoverEmail.json';
import recoverEmail from '@locales/it/recoverEmail.json';
import recoverEmailFR from '@locales/fr/recoverEmail.json';
import recoverEmailES from '@locales/es/recoverEmail.json';
import recoverEmailDE from '@locales/de/recoverEmail.json';

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'it';
    resources: {
      countries: typeof countries;
      countriesEN: typeof countriesEN;
      countriesFR: typeof countriesFR;
      countriesES: typeof countriesES;
      countriesDE: typeof countriesDE;

      notFound: typeof notFound;
      notFoundEN: typeof notFoundEN;
      notFoundFR: typeof notFoundFR;
      notFoundES: typeof notFoundES;
      notFoundDE: typeof notFoundDE;

      signIn: typeof signIn;
      signInEN: typeof signInEN;
      signInFR: typeof signInFR;
      signInES: typeof signInES;
      signInDE: typeof signInDE;

      signUp: typeof signUp;
      signUpEN: typeof signUpEN;
      signUpFR: typeof signUpFR;
      signUpES: typeof signUpES;
      signUpDE: typeof signUpDE;

      passwordReset: typeof passwordReset;
      passwordResetEN: typeof passwordResetEN;
      passwordResetFR: typeof passwordResetFR;
      passwordResetES: typeof passwordResetES;
      passwordResetDE: typeof passwordResetDE;

      signInContent: typeof signInContent;
      signInContentEN: typeof signInContentEN;
      signInContentFR: typeof signInContentFR;
      signInContentES: typeof signInContentES;
      signInContentDE: typeof signInContentDE;

      dashboard: typeof dashboard;
      dashboardEN: typeof dashboardEN;
      dashboardFR: typeof dashboardFR;
      dashboardES: typeof dashboardES;
      dashboardDE: typeof dashboardDE;

      library: typeof library;
      libraryEN: typeof libraryEN;
      libraryFR: typeof libraryFR;
      libraryES: typeof libraryES;
      libraryDE: typeof libraryDE;

      onBoarding: typeof onBoarding;
      onBoardingEN: typeof onBoardingEN;
      onBoardingFR: typeof onBoardingFR;
      onBoardingES: typeof onBoardingES;
      onBoardingDE: typeof onBoardingDE;

      newPassword: typeof newPassword;
      newPasswordEN: typeof newPasswordEN;
      newPasswordFR: typeof newPasswordFR;
      newPasswordES: typeof newPasswordES;
      newPasswordDE: typeof newPasswordDE;

      thankYou: typeof thankYou;
      thankYouEN: typeof thankYouEN;
      thankYouFR: typeof thankYouFR;
      thankYouES: typeof thankYouES;
      thankYouDE: typeof thankYouDE;

      recoverEmail: typeof recoverEmail;
      recoverEmailEN: typeof recoverEmailEN;
      recoverEmailFR: typeof recoverEmailFR;
      recoverEmailES: typeof recoverEmailES;
      recoverEmailDE: typeof recoverEmailDE;

      privacyPolicy: typeof privacyPolicy;
      privacyPolicyEN: typeof privacyPolicyEN;
      privacyPolicyFR: typeof privacyPolicyFR;
      privacyPolicyES: typeof privacyPolicyES;
      privacyPolicyDE: typeof privacyPolicyDE;

      chooseProfile: typeof chooseProfile;
      chooseProfileEN: typeof chooseProfileEN;
      chooseProfileFR: typeof chooseProfileFR;
      chooseProfileES: typeof chooseProfileES;
      chooseProfileDE: typeof chooseProfileDE;
    };
  }
}

export const resources = {
  en: {
    signIn: signInEN,
    signUp: signUpEN,
    privacyPolicy: privacyPolicyEN,
    passwordReset: passwordResetEN,
    signInContent: signInContentEN,
    onBoarding: onBoardingEN,
    dashboard: dashboardEN,
    newPassword: newPasswordEN,
    thankYou: thankYouEN,
    recoverEmail: recoverEmailEN,
    countries: countriesEN,
    notFound: notFoundEN,
    library: libraryEN,
    chooseProfile: chooseProfileEN,
  },
  it: {
    signIn: signIn,
    signUp: signUp,
    privacyPolicy: privacyPolicy,
    passwordReset: passwordReset,
    signInContent: signInContent,
    onBoarding: onBoarding,
    dashboard: dashboard,
    newPassword: newPassword,
    thankYou: thankYou,
    recoverEmail: recoverEmail,
    countries: countries,
    notFound: notFound,
    library: library,
    chooseProfile: chooseProfile,
  },
  fr: {
    signIn: signInFR,
    signUp: signUpFR,
    privacyPolicy: privacyPolicyFR,
    passwordReset: passwordResetFR,
    signInContent: signInContentFR,
    onBoarding: onBoardingFR,
    dashboard: dashboardFR,
    newPassword: newPasswordFR,
    thankYou: thankYouFR,
    recoverEmail: recoverEmailFR,
    countries: countriesFR,
    notFound: notFoundFR,
    library: libraryFR,
    chooseProfile: chooseProfileFR,
  },
  es: {
    signIn: signInES,
    signUp: signUpES,
    privacyPolicy: privacyPolicyES,
    passwordReset: passwordResetES,
    signInContent: signInContentES,
    onBoarding: onBoardingES,
    dashboard: dashboardES,
    newPassword: newPasswordES,
    thankYou: thankYouES,
    recoverEmail: recoverEmailES,
    countries: countriesES,
    notFound: notFoundES,
    library: libraryES,
    chooseProfile: chooseProfileES,
  },
  de: {
    signIn: signInDE,
    signUp: signUpDE,
    privacyPolicy: privacyPolicyDE,
    passwordReset: passwordResetDE,
    signInContent: signInContentDE,
    onBoarding: onBoardingDE,
    dashboard: dashboardDE,
    newPassword: newPasswordDE,
    thankYou: thankYouDE,
    recoverEmail: recoverEmailDE,
    countries: countriesDE,
    notFound: notFoundDE,
    library: libraryDE,
    chooseProfile: chooseProfileDE,
  },
} as const;
const supportedLang = ['it-IT', 'fr-FR', 'en-EN', 'de-DE', 'es-ES'];

export const defaultNS = supportedLang.includes(navigator.language)
  ? navigator.language
  : 'it';
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['cookie'],
      lookupCookie: 'lang',
    },
    fallbackLng: defaultNS,
    ns: [
      'signIn',
      'signUp',
      'privacyPolicy',
      'passwordReset',
      'signInContent',
      'onBoarding',
      'dashboard',
      'newPassword',
      'thankYou',
      'recoverEmail',
      'countries',
      'notFound',
      'library',
      'chooseProfile',
    ],
  });

export default i18n;
