import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import SignUpRightSide from '@components/SignUpRightSide';
import CompleteRegistrationForm, {
  User,
} from '@components/CompleteRegistrationForm';
import SplitLayout from '@components/SplitLayout';
import { ROUTES } from '@routes/routes';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { getAuth } from 'firebase/auth';

import { useTranslation } from 'react-i18next';
import { useFirestore, useUser } from 'reactfire';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import Loader from '@components/Loader';
import format from 'date-fns/format';

export enum SIGNUP_ERROR_TYPE {
  EMAIL = 'email',
  GENERIC = 'generic',
  PASSWORD = 'password',
}

export default function CompleteRegistration() {
  const navigate = useNavigate();
  const auth = getAuth();

  const { t } = useTranslation('signUp');
  const [error, setError] = React.useState<{ type: string; message: string }>({
    type: '',
    message: '',
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(false);
  const db = useFirestore();
  const [step, setStep] = React.useState(1);

  const { data: user } = useUser();

  React.useEffect(() => {
    async function getUser() {
      if (user) {
        setIsLoading(true);
        const userRefPublic = doc(db, 'users', user?.uid);
        const docSnapPublic = await getDoc(userRefPublic);

        const userDataPublic = docSnapPublic.data();
        const userType = userDataPublic?.userType;

        if (userType === 'both' || userType === 'library') {
          navigate(`${ROUTES.BASE}`);
        } else {
          setIsLoading(false);
        }
      }
    }
    getUser();
  }, [db, navigate, user]);

  const onSignUp = React.useCallback(
    async ({
      birthday,
      gender,
      whyNeedVoice,
      aacModel,
      otherModel,
      therapist,
      phone,
      language,
      country,
      agreeTerms,
      agreeTerms2,
    }: User) => {
      try {
        const yearBirth = birthday?.split('-')[0];
        const age = yearBirth && new Date().getFullYear() - +yearBirth;

        await setDoc(
          doc(
            db,
            'users',
            auth?.currentUser && (auth?.currentUser?.uid as any)
          ),
          {
            language,
            describeYourself: '',
            id: auth?.currentUser && auth?.currentUser?.uid,
            userType: 'both',
            gender,
          },
          { merge: true }
        );

        await setDoc(
          doc(db, `users/${auth?.currentUser?.uid}/private`, 'library'),
          {
            onBoardingStep: 'profile',
            birthday,
            firstname: auth?.currentUser?.displayName?.split(' ')[0],
            lastname: auth?.currentUser?.displayName?.split(' ')[1],
            email: auth?.currentUser?.email,
            age,
            agreeTerms,
            agreeTerms2,
            country,
            gender,
            whyNeedVoice,
            favorites: [],
            requestedVoices: [],
            currentRequestedVoice: '',
            aacModel: aacModel === 'other' ? otherModel : aacModel,
            therapist,
            phone: phone,
          },
          { merge: true }
        );

        // SEND EMAIL IF NEW USER SIGN UP

        const date = format(new Date(), 'yyyy-MM-dd--HH:mm:ss');

        await setDoc(doc(db, `mail/${user?.uid}/notifications`, date as any), {
          from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
          replyTo: user?.email,
          to: process.env.REACT_APP_FIREBASE_EMAIL_RECIPIENT,
          cc: process.env.REACT_APP_FIREBASE_EMAIL_CC,
          message: {
            subject: `New PATIENT with name ${user?.displayName}`,
            text: `User ${user?.displayName} with ID ${user?.uid} and email ${
              user?.email
            }, has just signed up in the LIBRARY APP \n Patient info: \n First name: ${
              auth?.currentUser?.displayName?.split(' ')[0] ?? ''
            } \n Last name: ${
              auth?.currentUser?.displayName?.split(' ')[1] ?? ''
            } \n Gender: ${gender ?? ''}  \n Aac Model: ${
              aacModel ?? ''
            } \n Therapist: ${therapist ?? ''}  \n Why need voice: ${
              whyNeedVoice ?? ''
            } \n Age: ${age ?? ''} \n Phone: ${phone ?? ''}  \n Birthday: ${
              birthday ?? ''
            } \n Country: ${country ?? ''} \n Email: ${
              auth?.currentUser?.email ?? ''
            }`,
          },
        });

        navigate(`${ROUTES.BASE}`);
      } catch (err: any) {
        if (err instanceof Error) {
          setError({ type: SIGNUP_ERROR_TYPE.GENERIC, message: err.message });
        }
        if (err?.code === 'auth/email-already-in-use') {
          setError({
            type: SIGNUP_ERROR_TYPE.EMAIL,
            message: t('form.errorsState.general.emailAlreadyInUse'),
          });

          setStep(1);
          setTimeout(() => {
            setError({ type: '', message: '' });
            navigate(ROUTES.SIGN_IN);
          }, 5000);
        } else if (err?.code === 'auth/invalid-email') {
          setError({
            type: SIGNUP_ERROR_TYPE.EMAIL,
            message: t('form.errorsState.general.invalidEmail'),
          });
          setStep(1);
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        } else if (err.code === 'auth/weak-password') {
          setStep(1);
          setError({
            type: SIGNUP_ERROR_TYPE.PASSWORD,
            message: t('form.errorsState.general.weakPassword'),
          });
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        } else {
          setError(err?.message);
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [auth, db, navigate]
  );

  function onSubmitForm(user: User): void {
    setIsLoading(true);
    onSignUp(user);
  }

  function goToSignIn() {
    navigate(ROUTES.SIGN_IN);
  }

  return isLoadingPage ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
        firstButton={
          !auth.currentUser && (
            <Button
              onClick={goToSignIn}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
            >
              Sign In
            </Button>
          )
        }
      />

      <LayoutWrapper>
        <SplitLayout
          left={
            <>
              <CompleteRegistrationForm
                onSubmit={onSubmitForm}
                isLoading={isLoading}
                step={step}
                setStep={setStep}
                error={error}
              />
              {error?.message.length ? (
                <p className={styles.errorMessage}>{error?.message}</p>
              ) : null}
            </>
          }
          right={<SignUpRightSide />}
        />
      </LayoutWrapper>
    </>
  );
}
