/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { format } from 'date-fns';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import { useNavigate } from 'react-router-dom';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  deleteUser,
  signOut,
  getAuth,
} from 'firebase/auth';

import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import Spinner from '@components/Spinner';
import { getCssVariable } from '@utils/index';
import { deleteObject, getStorage, ref, listAll } from 'firebase/storage';
import { doc, setDoc, deleteDoc } from '@firebase/firestore';
import { ROUTES } from '@routes/routes';
import { collection, getDocs } from 'firebase/firestore';

type IProps = {
  closeModal: any;
  currentAuthUser: any;
  db: any;
  user: any;
  setDeletion: any;
  userLang: string;
  userType: string;
};

export default function DeleteUserPrompt({
  closeModal,
  currentAuthUser,
  db,
  user,
  userType,
  userLang,
  setDeletion,
}: IProps) {
  const { t } = useTranslation('dashboard');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });
  const auth = getAuth();
  const oldPassValue = watch('oldPassword');
  const whyDeleteWatch = watch('whyDelete');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [deleteAccountSuccessfully, setDeleteAccountSuccessfully] =
    React.useState(false);
  const [error, setError] = React.useState<{ message: string }>({
    message: '',
  });
  const [isDirty, setIsDirty] = React.useState<{
    oldPassword: boolean;
    whyDelete: boolean;
  }>({
    oldPassword: false,
    whyDelete: false,
  });

  const handleSignOut = React.useCallback(
    async (auth: any) => {
      await signOut(auth);
      navigate(`${ROUTES.SIGN_IN}`);
    },
    [navigate]
  );

  const { ref: oldPasswordRef, ...oldPasswordInputProps } = register(
    'oldPassword',
    {
      required: t('personalDetails.form.errorsState.invalid.password'),
    }
  );

  const { ref: whyDeleteRef, ...whyDeleteInputProps } = register('whyDelete', {
    required: t('changePassword.form.errorsState.required.generic'),
  });

  function generateEmailSubject(language: string) {
    switch (language) {
      case 'en-EN':
        return 'Account deletion';
      case 'it-IT':
        return 'Eliminazione account';
      case 'es-ES':
        return 'Eliminación de cuenta';
      case 'de-DE':
        return 'Kontolöschung';
      case 'fr-FR':
        return 'Suppression de compte';
      default:
        return 'Eliminazione account';
    }
  }

  function generateEmailContent(language: string, date: string) {
    switch (language) {
      case 'en-EN':
        return `Dear Voice for Purpose user, \nAs per your request received on ${date} we confirm the deletion of your Voice for Purpose account. \nKind regards \nThe Voice for Purpose team`;
      case 'it-IT':
        return `Gentile utente Voice for Purpose, \nCome da Sua richiesta pervenutaci in data ${date} confermiamo la cancellazione del Suo account da Voice for Purpose. \nCordiali saluti \nIl team Voice for Purpose`;
      case 'es-ES':
        return `Estimado usuario de Voice for Purpose: \nSegún su solicitud recibida el ${date}, confirmamos la eliminación de su cuenta de Voice for Purpose. \nUn cordial saludo \nEl equipo de Voice for Purpose`;
      case 'de-DE':
        return `Sehr geehrter Voice for Purpose-Benutzer, \nAufgrund Ihrer am ${date} erhaltenen Anfrage bestätigen wir die Löschung Ihres Voice for Purpose-Kontos. \nMit freundlichen Grüßen \nThe Voice for Purpose-Team`;
      case 'fr-FR':
        return `Cher utilisateur de Voice for Purpose, \nConformément à votre demande reçue le ${date}, nous confirmons la suppression de votre compte Voice for Purpose. \nCordialement \nL'équipe de Voice for Purpose`;
      default:
        return `Gentile utente Voice for Purpose, \nCome da Sua richiesta pervenutaci in data ${date} confermiamo la cancellazione del Suo account da Voice for Purpose. \nCordiali saluti \nIl team Voice for Purpose`;
    }
  }

  const handleOnSubmit = React.useCallback(
    async ({ whyDelete, oldPassword }: any) => {
      try {
        setIsLoading(true);
        setIsDirty({
          oldPassword: oldPassword.length > 0,
          whyDelete: whyDelete.length > 0,
        });
        const date = format(new Date(), 'yyyy-MM-dd--HH:mm:ss.SSSS');
        const date2 = format(new Date(), 'dd-MM-yyyy');
        const date3 = format(new Date(), 'yyyy-MM-dd--HH:mm:ss');
        const credential = EmailAuthProvider.credential(
          currentAuthUser?.email,
          oldPassword as string
        );

        await reauthenticateWithCredential(currentAuthUser, credential);

        await setDoc(
          doc(db, `deletionReasons/${user?.uid}/notifications`, date as any),
          {
            reason: whyDelete,
          }
        );

        if (userType === 'donor' || userType === 'library') {
          // await deleteFolder(`users/${user?.uid}`);
          const userRef = collection(db, `users/${user?.uid}/private`);
          const docBothSnap = await getDocs(userRef);
          if (!docBothSnap.empty) {
            docBothSnap.docs.forEach(
              async (userData) => await deleteDoc(userData.ref)
            );
          }

          await setDoc(
            doc(db, `mail/${user?.uid}/notifications`, date3 as any),
            {
              from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
              replyTo: user?.email,
              to: process.env.REACT_APP_FIREBASE_EMAIL_RECIPIENT,
              message: {
                subject: `LIBRARY APP: Account deletion request from ${user?.displayName}`,
                text: `User ${user?.displayName}, with ID ${user?.uid}, requires account deletion from LIBRARY APP`,
              },
            }
          );

          await setDoc(
            doc(db, `mail/${user?.uid}/notifications`, date as any),
            {
              from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
              to: user?.email,
              message: {
                subject: generateEmailSubject(userLang),
                text: generateEmailContent(userLang, date2),
              },
            }
          );

          await deleteDoc(doc(db, 'users', user?.uid));
          await deleteUser(currentAuthUser);
        } else {
          await setDoc(
            doc(db, `mail/${user?.uid}/notifications`, date2 as any),
            {
              from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
              replyTo: user?.email,
              to: process.env.REACT_APP_FIREBASE_EMAIL_RECIPIENT,
              message: {
                subject: `LIBRARY APP: Account deletion request from ${user?.displayName}`,
                text: `User ${user?.displayName}, with ID ${user?.uid}, requires account deletion from LIBRARY APP`,
              },
            }
          );

          await setDoc(
            doc(db, `mail/${user?.uid}/notifications`, date as any),
            {
              from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
              to: user?.email,
              message: {
                subject: generateEmailSubject(userLang),
                text: generateEmailContent(userLang, date2),
              },
            }
          );

          await setDoc(
            doc(db, 'users', user.uid),
            {
              userType: 'donor',
            },
            { merge: true }
          );

          const userRefLibrary = doc(db, `users/${user?.uid}/private/library`);
          await deleteDoc(userRefLibrary);
        }

        setDeletion(true);
        setDeleteAccountSuccessfully(true);
        setIsLoading(false);
        // setDeleteAccountSuccessfully(false);
        setTimeout(() => {
          closeModal();
        }, 2000);

        await signOut(auth);
        navigate(`${ROUTES.SIGN_IN}`);
        handleSignOut(auth);
      } catch (error) {
        console.log(error);
        await setDoc(doc(db, 'errorsLog', user?.uid as any), {
          error:
            'Something went wrong when deleting user' +
            user?.displayName +
            'ID: ' +
            user?.uid +
            '. Error: \n:' +
            error,
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (error?.code === 'auth/wrong-password') {
          setError({ message: 'Wrong password' });
          setTimeout(() => {
            setError({ message: '' });
          }, 2000);
        }
        setDeleteAccountSuccessfully(false);
        setError({
          message: t('deleteAccount.modal.form.errorsState.general'),
        });
        setTimeout(() => {
          setError({ message: '' });
        }, 2000);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } finally {
        setIsLoading(false);
      }
    },
    [
      db,
      auth,
      handleSignOut,
      closeModal,
      user?.uid,
      user?.displayName,
      user?.email,
      navigate,
      setDeletion,
      currentAuthUser,
      userLang,
      userType,
      t,
    ]
  );

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <h4>{t('deleteAccount.modal.title')}</h4>
        <Button
          type={BUTTON_TYPE.DEFAULT}
          mode={BUTTON_MODE.GHOST}
          size={BUTTON_SIZE.ONLY_ICON}
          leadingIcon={
            <FeatherIcon
              strokeWidth="2px"
              aria-hidden="true"
              color={getCssVariable('neutral600')}
              onClick={closeModal}
              icon="x"
              size="24"
            />
          }
        ></Button>
      </div>
      <div className={styles.container}>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <Input
            hasValue={isDirty?.whyDelete ?? false}
            label={t('deleteAccount.modal.form.labels.whyDelete')}
            type="text"
            placeholder={t('deleteAccount.modal.form.placeholder.whyDelete')}
            inputRef={whyDeleteRef}
            {...whyDeleteInputProps}
            error={errors?.whyDelete?.message}
          />
          <Input
            hasValue={isDirty?.oldPassword ?? false}
            label={t('deleteAccount.modal.form.labels.password')}
            type="password"
            placeholder={t('deleteAccount.modal.form.placeholder.password')}
            inputRef={oldPasswordRef}
            {...oldPasswordInputProps}
            error={errors?.oldPassword?.message}
          />
          {error?.message && <p className={styles.error}>{error?.message}</p>}
          {userType === 'both' && (
            <p>{t('deleteAccount.modal.form.labels.alert')}</p>
          )}
        </form>

        {deleteAccountSuccessfully && (
          <p className={styles.success}>
            {t('deleteAccount.modal.form.success.accountDeleted')}
          </p>
        )}

        {error?.message && <p className={styles.error}>{error?.message}</p>}
      </div>
      <div className={styles.footerModal}>
        <div className={styles.buttonsWrapper}>
          <Button
            type={BUTTON_TYPE.ERROR}
            mode={BUTTON_MODE.BASIC}
            disabled={!whyDeleteWatch}
            onClick={handleSubmit(handleOnSubmit)}
          >
            {isLoading ? (
              <Spinner isWhite isMid />
            ) : (
              t('deleteAccount.modal.form.labels.delete')
            )}
          </Button>
          <div className={styles.cancel} onClick={closeModal}>
            {t('deleteAccount.modal.form.labels.cancel')}
          </div>
        </div>
      </div>
    </div>
  );
}
