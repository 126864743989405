/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useAuth, useUser } from 'reactfire';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import NewPasswordForm from '@components/NewPasswordForm';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '@routes/routes';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import { confirmPasswordReset } from 'firebase/auth';
import LayoutWrapper from '@components/LayoutWrapper';
import OnBoardingRightSide from '@components/OnBoardingRightSide';
import Header from '@components/Header';
import SplitLayout from '@components/SplitLayout';
import FeatherIcon from 'feather-icons-react';
import Button, { BUTTON_SIZE, BUTTON_TYPE } from '@components/Button';
import { getCssVariable } from '@utils/index';
import { Helmet } from 'react-helmet';
export default function NewPassword(props: any) {
  const auth = useAuth();
  const { data: user } = useUser();
  const { t } = useTranslation('newPassword');
  const navigate = useNavigate();
  const location = useLocation();
  const { code }: any = queryString.parse(location.search) ?? {
    code: '',
  };

  if (!code) {
    navigate(ROUTES.RESET_PASSWORD);
  }

  const [error, setError] = React.useState<string>('');
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [succesfullySent, setFuccesfullySent] = React.useState<boolean>(false);

  const onSetNewPassword = React.useCallback(
    async (password: string) => {
      try {
        await confirmPasswordReset(auth, code, password);
        setFuccesfullySent(true);
      } catch (err) {
        if (err instanceof Error) {
          setError(err?.message);
          setFuccesfullySent(false);
        }
      } finally {
        setIsSending(false);
      }
    },
    [auth, navigate, code]
  );

  function onSubmitNewPasswordForm({ password }: { password: string }): void {
    setIsSending(true);
    onSetNewPassword(password);
  }

  function goToSignIn() {
    navigate(ROUTES.SIGN_IN);
  }
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
      // destination={user ? 'help' : ROUTES.SIGN_IN}
      // buttonText={user ? t('header.logged') : t('header.unlogged')}
      />

      <LayoutWrapper>
        <SplitLayout
          left={
            <div>
              {!succesfullySent && (
                <HeaderOnBoardingWrapper title={t('title')} />
              )}
              {succesfullySent ? (
                <>
                  <SuccessMessageOnBoarding
                    title={t('newPassword')}
                    leadingIcon={
                      <Button
                        style={{
                          backgroundColor: getCssVariable('green-light'),
                          cursor: 'default',
                        }}
                        type={BUTTON_TYPE.RECORDING}
                        size={BUTTON_SIZE.ONLY_ICON}
                        leadingIcon={
                          <FeatherIcon
                            strokeWidth="1px"
                            icon="check"
                            color={'var(--green)'}
                            size="40"
                          />
                        }
                      ></Button>
                    }
                  />
                  <Button
                    onClick={goToSignIn}
                    type={BUTTON_TYPE.PRIMARY}
                    fullWidth
                  >
                    {t('form.labels.backToSignIn')}
                  </Button>
                </>
              ) : (
                <div className={styles.container}>
                  <NewPasswordForm
                    onSubmit={onSubmitNewPasswordForm}
                    isSending={isSending}
                  />

                  {error.length ? (
                    <p className={styles.errorMessage}>{error}</p>
                  ) : null}
                </div>
              )}
            </div>
          }
          right={<OnBoardingRightSide />}
        />
      </LayoutWrapper>
    </div>
  );
}
