import styles from './styles.module.scss';
import { WithChildren } from '@utils/types/helpers';

type Props = WithChildren<{
  message?: string;
  leadingIcon?: React.ReactNode;
  title?: string;
  isCenter?: boolean;
  noPadding?: boolean;
}>;

export default function SuccessMessageOnBoarding({
  message,
  leadingIcon,
  title,
  children,
  isCenter = false,
  noPadding,
}: Props) {
  return (
    <div
      className={
        isCenter
          ? `${styles.container} ${styles.centered}`
          : noPadding
          ? `${styles.container} ${styles.noPadding}`
          : styles.container
      }
    >
      {leadingIcon}
      {title && <h3>{title}</h3>}
      {message && !isCenter ? <p>{message}</p> : <h3>{message}</h3>}
      {children && children}
    </div>
  );
}
