export const ROUTES = {
  BASE: '/',
  USER_LIBRARY: 'users/:id',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  DASHBOARD: '/dashboard',
  FAVORITE: '/favorite',
  RESET_PASSWORD: '/reset-password',
  NEW_PASSWORD: '/new-password',
  RECOVER_EMAIL: '/recover-email',
  EMAIL_VERIFICATION: '/verify-email',
  AUTH: '/auth',
  THANK_YOU: '/thank-you',
  PRIVACY_POLICY: '/privacy-policy',
  ONBOARDING: '/complete-profile',
  FINISH_REGISTRATION: '/complete-registration',
  CHOOSE_PROFILE: '/profile',
};
