/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ROUTES } from '@routes/routes';
import { AuthProvider, useFirebaseApp, useInitPerformance } from 'reactfire';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loader from '@components/Loader';
import { AuthWrapper } from '@components/AuthWrapper';
import { initializeFirestore } from 'firebase/firestore';
import { FirestoreProvider, useInitFirestore } from 'reactfire';
import SignUp from '@pages/SignUp';
import SignIn from '@pages/SignIn';
import Dashboard from '@pages/Dashboard';
import PasswordReset from '@pages/PasswordReset';
import NewPassword from '@pages/NewPassword';

import AuthPage from '@pages/Auth';

import { ProfileImageProvider } from '@contexts/ProfileImageContext';

import RecoverEmail from '@pages/RecoverEmail';

import CookieBanner from '@components/CookieBanner';

import PageNotFound from '@pages/404';
import Library from '@pages/Library';
import Favorite from '@pages/Favorite';
import { UserCardProvider } from '@contexts/UserCardContext';
import UserCardModalWrap from '@components/UserCardModalWrap';
import CompleteRegistration from '@pages/CompleteRegistration';
import ChooseProfile from '@pages/ChooseProfile';

// import Onboarding from '@pages/Onboarding';

const App = () => {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLogged, setIsLogged] = React.useState<boolean>(false);
  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  const { status, data: firestoreInstance } = useInitFirestore(
    async (firebaseApp) => {
      const db = initializeFirestore(firebaseApp, {});
      return db;
    }
  );

  onAuthStateChanged(auth, (user) => {
    setIsLoading(false);
    if (user) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  });

  return status === 'loading' || isLoading ? (
    <Loader />
  ) : (
    <>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreInstance}>
          <ProfileImageProvider>
            <UserCardProvider>
              <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route
                  path={ROUTES.SIGN_IN}
                  element={
                    // isLogged ? <Navigate to={ROUTES.BASE} /> : <SignIn />
                    <SignIn />
                  }
                />
                <Route
                  path={ROUTES.CHOOSE_PROFILE}
                  element={<ChooseProfile />}
                />
                <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
                <Route
                  path={ROUTES.FINISH_REGISTRATION}
                  element={<CompleteRegistration />}
                />
                <Route
                  path={ROUTES.RESET_PASSWORD}
                  element={<PasswordReset />}
                />
                {/* <Route
                  path={ROUTES.PRIVACY_POLICY}
                  element={<PrivacyPolicy />}
                /> */}
                <Route path={ROUTES.NEW_PASSWORD} element={<NewPassword />} />
                <Route path={ROUTES.RECOVER_EMAIL} element={<RecoverEmail />} />

                {/* <Route
                path={ROUTES.ONBOARDING}
                element={
                  <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                    <Onboarding />
                  </AuthWrapper>
                }
              /> */}

                <Route path={ROUTES.BASE} element={<Library />}>
                  <Route
                    path={ROUTES.USER_LIBRARY}
                    element={<UserCardModalWrap />}
                  />
                </Route>

                <Route
                  path={ROUTES.FAVORITE}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <Favorite />
                    </AuthWrapper>
                  }
                />

                <Route
                  path={ROUTES.DASHBOARD}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <Dashboard />
                    </AuthWrapper>
                  }
                />
                {/* <Route
                  path={ROUTES.THANK_YOU}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <ThankYou />
                    </AuthWrapper>
                  }
                /> */}

                <Route path={ROUTES.AUTH} element={<AuthPage />} />
              </Routes>
              <CookieBanner />
            </UserCardProvider>
          </ProfileImageProvider>
        </FirestoreProvider>
      </AuthProvider>
    </>
  );
};
export default App;
