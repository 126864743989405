import { ReactNode } from 'react';
import styles from './styles.module.scss';

export enum BADGE_TYPE {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not_available',
}
type Props = {
  children: ReactNode;
  type: BADGE_TYPE;
};
export default function Badge({
  children,
  type = BADGE_TYPE.NOT_AVAILABLE,
}: Props) {
  return (
    <div className={`${styles.container} ${styles[type]}`}>{children}</div>
  );
}
