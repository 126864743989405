import React from 'react';

const UserCardContext = React.createContext<any>(null);

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}

const UserCardProvider = ({ children }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usersFavorites, setUsersFavorites] = React.useState<any>([]);
  const [requestedVoices, setRequestedVoices] = React.useState<any>([]);
  // const [userData, setUserData] = React.useState<any>({});
  const [currentRequestedVoice, setCurrentRequestedVoice] =
    React.useState<string>();
  return (
    <UserCardContext.Provider
      value={
        {
          usersFavorites,
          setUsersFavorites,
          currentRequestedVoice,
          setCurrentRequestedVoice,
          requestedVoices,
          setRequestedVoices,
          // userData,
          // setUserData,
        } as any
      }
    >
      {children}
    </UserCardContext.Provider>
  );
};

export { UserCardProvider, UserCardContext };
