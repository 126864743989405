import * as React from 'react';
import styles from './styles.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
// import { ROUTES } from '@routes/routes';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useFirestore, useUser } from 'reactfire';

import Spinner from '@components/Spinner';
import UserCardModal from '@components/UserCardModal';

export default function UserCardModalWrap() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState<any>();

  const db = useFirestore();
  const { data: user } = useUser();
  const location = useLocation();
  function closeModal() {
    // navigate(ROUTES.BASE);
    navigate(-1);
  }

  React.useEffect(() => {
    setShowModal(location.pathname.includes('user'));
  }, [location.pathname]);

  const getUserData = React.useCallback(async () => {
    if (user) {
      setIsLoading(true);
      const idMatch = location.pathname.split('/').filter(Boolean)[1];

      const allUsers = query(
        collection(db, 'users'),
        where('id', '==', idMatch)
      );
      const docLibrarySnap = await getDocs(allUsers);
      if (!docLibrarySnap.empty) {
        const userData = docLibrarySnap.docs.map((userData: any) =>
          userData.data()
        );
        setUserDetails(userData && userData[0]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, [user, db, location.pathname]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  Modal.setAppElement('div');

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      {!isLoading ? (
        <UserCardModal
          isModelReady={userDetails?.isModelReady}
          firstname={userDetails?.firstname}
          describeYourself={userDetails?.describeYourself}
          voiceType={userDetails?.voiceType}
          userId={userDetails?.id}
          language={userDetails?.language}
          urlRec={userDetails?.URL_recording}
          profilePic={userDetails?.profile_photo}
          avatarPhoto={userDetails?.avatar_photo}
        />
      ) : (
        <div
          style={{
            minHeight: '276px',
            minWidth: '528',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </Modal>
  );
}
