/* eslint-disable @typescript-eslint/no-unused-vars */
import Header from '@components/Header';
import { ROUTES } from '@routes/routes';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SplitLayout from '@components/SplitLayout';
import SignInRightSide from '@components/SignInRightSide';
import SignInForm from '@components/SignInForm';
import LayoutWrapper from '@components/LayoutWrapper';
import styles from './styles.module.scss';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

import { Helmet } from 'react-helmet';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import { doc, getDoc } from 'firebase/firestore';
import { useFirestore, useUser } from 'reactfire';
import Loader from '@components/Loader';

export default function SignIn() {
  const auth = getAuth();

  const { t } = useTranslation('signIn');
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(false);

  const db = useFirestore();

  const handleSignOut = React.useCallback(async (auth: any) => {
    await signOut(auth);

    window.open(process.env.REACT_APP_VFP_DONATE_URL, '_self');
  }, []);

  const { data: user } = useUser();

  React.useEffect(() => {
    if (user) {
      setIsLoadingPage(true);

      navigate(`${ROUTES.BASE}`);
    } else {
      setIsLoadingPage(false);
    }
  }, [navigate, user]);

  const onSignIn = React.useCallback(
    async (email: string, password: string) => {
      try {
        const { user } = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        const userRefPublic = doc(db, 'users', user?.uid);
        const docSnapPublic = await getDoc(userRefPublic);

        const userDataPublic = docSnapPublic.data();
        const userType = userDataPublic?.userType;
        const isAutodonate = userDataPublic?.isAutodonate;

        if (isAutodonate) {
          handleSignOut(auth);
        } else {
          if (userType === 'library' || userType === 'both') {
            navigate(ROUTES.BASE);
          } else {
            navigate(ROUTES.FINISH_REGISTRATION);
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            err?.code === 'auth/user-not-found'
          ) {
            setError(t('form.errorsState.general.userNotFound'));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          } else if (err.code === 'auth/wrong-password') {
            setError(t('form.errorsState.general.wrongPassword'));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          } else if (err.code === 'auth/too-many-requests') {
            setError(t('form.errorsState.general.tooManyRequest'));
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [auth, navigate, db, handleSignOut, t]
  );

  function onSubmitForm({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): void {
    setIsLoading(true);
    onSignIn(email, password);
  }

  function goToSignUp() {
    navigate(ROUTES.CHOOSE_PROFILE);
  }

  return isLoadingPage ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
        firstButton={
          <Button
            onClick={goToSignUp}
            type={BUTTON_TYPE.PRIMARY}
            mode={BUTTON_MODE.GHOST}
          >
            {t('header.signUp')}
          </Button>
        }
      />

      <LayoutWrapper>
        <SplitLayout
          left={
            <>
              <SignInForm onSubmit={onSubmitForm} isLoading={isLoading} />
              {error.length ? (
                <p className={styles.errorMessage}>{error}</p>
              ) : null}
            </>
          }
          right={<SignInRightSide />}
        />
      </LayoutWrapper>
    </>
  );
}
