import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const COOKIE_NAME = 'cookies-consent';
const COOKIE_TTL_DAYS = 365;

export default function CookieBanner() {
  const { t } = useTranslation('privacyPolicy');
  const [isVisible, setIsVisible] = React.useState(true);

  const scrollAndConsent = React.useCallback(() => {
    if (document.documentElement.scrollTop > 50) {
      consent();
    }
  }, []);

  const cookie = Cookies.get('cookies-consent');
  React.useEffect(() => {
    if (cookie) {
      setIsVisible(false);
      window.removeEventListener('scroll', scrollAndConsent);
      window.addEventListener('click', consent);
    } else {
      setIsVisible(true);
    }
  }, [cookie, scrollAndConsent]);

  const urlPrivacy = 'https://translated.com/riservatezza';

  React.useEffect(() => {
    window.addEventListener('scroll', scrollAndConsent);
    window.addEventListener('click', consent);
    setTimeout(consent, 10000);
    return () => {
      window.removeEventListener('scroll', scrollAndConsent);
      window.removeEventListener('click', consent);
    };
  }, [scrollAndConsent]);

  function consent() {
    setIsVisible(false);
    Cookies.set(COOKIE_NAME, 'true', { expires: COOKIE_TTL_DAYS });
  }
  return !isVisible ? null : (
    <div id="cookies-consent" className={styles.footerCookie}>
      <p className={styles.footerCookieText} onClick={consent}>
        {t('cookieBanner.description')}
        &nbsp;
        <a target={'_blank'} href={urlPrivacy}>
          {t('cookieBanner.cta')}
        </a>
      </p>
      <button
        id="close-cookie-consent"
        className={styles.footerButton}
        title="Close"
        onClick={consent}
      >
        {t('cookieBanner.accept')}
      </button>
    </div>
  );
}
