import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Item, useContextMenu } from 'react-contexify';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import styles from './styles.module.scss';
import FilterChip from '@components/FilterChip';
import FeatherIcon from 'feather-icons-react';
import Checkbox from '@components/Checkbox';
import { xor, noop } from 'lodash';
import { useWindowSize } from '@hooks/useWindowResize';
import AgeInput from '@components/AgeInput';
import Radio from '@components/Radio';
import ModalFilter from '@components/ModalFilter';
enum FACET_TYPE {
  CHECKBOX = 'checkbox',
  RANGE = 'range',
  RADIO = 'radio',
}

interface IGenericCheckbox {
  value: string;
  label?: string;
  type: FACET_TYPE.CHECKBOX;
}

interface IGenericRadio {
  value: string;
  label?: string;
  type: FACET_TYPE.RADIO;
}

interface IProps {
  isActive: boolean;
  currentKey: string;
  setCurrentKey: (key: string) => void;
  id: string;
  label: string;
  icon?: string;
  disabled?: boolean;
  type: FACET_TYPE;
  selectedValues: string[];
  value: IGenericCheckbox[] | IGenericRadio[];
  onSetFilter: (id: string, filters: string[], type: FACET_TYPE) => void;
  onRemoveFilter: (id: string) => void;
  globalState: any;
}

export default function FilterDropdown({
  isActive,
  type,
  disabled,
  currentKey,
  setCurrentKey,
  id,
  icon,
  label,
  value,
  globalState,
  onSetFilter,
  selectedValues,
  onRemoveFilter,
}: IProps) {
  const { show, hideAll } = useContextMenu({ id });
  const MenuPosition = React.useRef({ x: 0, y: 0 });
  function onCloseMenu() {
    hideAll();
    setCurrentKey('');
  }
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 600;
  const { t } = useTranslation('library');
  const [rangeState, setRangeState] = React.useState({ min: 0, max: 0 });
  const [state, setState] = React.useState<string[]>(selectedValues || []);
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);

  function handleOnHidden() {
    setCurrentKey('');
  }

  React.useEffect(() => {
    setState(selectedValues);
  }, [selectedValues]);

  function getMenuPosition(left: number, bottom: number) {
    MenuPosition.current = { x: left, y: bottom + 8 };
    return MenuPosition.current;
  }

  const displayModal = (id: string) => () => {
    setCurrentKey(id);
  };

  function handleOnChangeRange(e: any) {
    const rg = /^0+(?!$)/;
    const value = e.target.value.replace(rg, '');

    setRangeState({
      ...rangeState,
      [e.target.name]: value,
    });
    setState([String(rangeState.min), String(rangeState.max)]);
  }

  function dismissModal() {
    setCurrentKey('');
  }

  function displayDropdown(
    e: any,
    key: string,
    { left, bottom }: { left: number; bottom: number }
  ) {
    setDropdownIsOpen(true);
    window.addEventListener(
      'keydown',
      function (event) {
        event.stopPropagation();
      },
      true
    );
    setTimeout(() => {
      setCurrentKey(key);
    }, 10);
    show(e, {
      position: getMenuPosition(left, bottom),
    });
  }

  function handleItemClick({ event }: any) {
    // this will prevent the Menu from being closed
    event.stopPropagation();
  }

  const handleOnChangeSingleCheckbox = (value: string) => () => {
    setState(xor(state, [value]));
  };

  const handleOnChangeSingleRadio = (value: string) => () => {
    setState([value]);
  };

  function handleOnSetFilter() {
    onSetFilter(id, state, type);
  }

  function handleOnRemoveFilter() {
    onRemoveFilter(id);
    setRangeState({ min: 0, max: 0 });
  }

  React.useEffect(() => {
    if (type === FACET_TYPE.RANGE) {
      if (rangeState.min !== 0 || rangeState.max !== 0) {
        setState([String(rangeState.min), String(rangeState.max)]);
      } else {
        setRangeState({ min: 0, max: 0 });
      }
    }
  }, [rangeState.min, rangeState.max, type]);

  function renderFacetCheckbox(value: any) {
    return (
      <Checkbox
        onChange={handleOnChangeSingleCheckbox(value.value)}
        checked={state.includes(value.value)}
        key={value.value}
      >
        <>{value?.label ? value.label : value.value} </>
      </Checkbox>
    );
  }

  function renderFacetRadio(value: any) {
    return (
      <Radio
        onChange={handleOnChangeSingleRadio(value.value)}
        checked={state.includes(value.value)}
        forLabel={value.value}
        key={value.value}
      >
        <>{value?.label ? value.label : value.value} </>
      </Radio>
    );
  }

  function renderInputAge(value: any) {
    return (
      <AgeInput
        val={
          globalState?.age ?? {
            min: 0,
            max: 0,
          }
        }
        key={value.label}
        label={value.label}
        onChange={handleOnChangeRange}
      >
        <>
          {value?.label
            ? value.label +
              ' ' +
              t('filters.labels.age.voiceType').toLocaleLowerCase()
            : value.value}{' '}
        </>
      </AgeInput>
    );
  }

  const renderValueInDropdown = (val: any, key: number) => {
    return (
      <React.Fragment key={key}>
        <Item aria-hidden onClick={handleItemClick}>
          {val.type === FACET_TYPE.CHECKBOX && renderFacetCheckbox(val)}
          {val.type === FACET_TYPE.RADIO && renderFacetRadio(val)}
          <div className={styles.rangeContainer}>
            {val.type === FACET_TYPE.RANGE && val.value.map(renderInputAge)}
          </div>
          {val.type === 'empty' && <p>{val.label}</p>}
        </Item>
      </React.Fragment>
    );
  };

  const renderValueInModal = (val: any, key: number) => {
    return (
      <React.Fragment key={key}>
        <div className={styles.checkboxWrapper}>
          {val.type === FACET_TYPE.CHECKBOX && renderFacetCheckbox(val)}
          {val.type === FACET_TYPE.RADIO && renderFacetRadio(val)}

          {val.type === FACET_TYPE.RANGE && (
            <div className={styles.rangeContainer}>
              {val.value.map(renderInputAge)}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  function renderTitleModal(id: string) {
    switch (id) {
      case 'language':
        return t('filters.labels.language');
      case 'gender':
        return t('filters.labels.gender');
      case 'age':
        return t('filters.labels.age.voiceType');

      default:
        return '';
    }
  }

  return (
    <>
      <div className={styles.item}>
        <FilterChip
          isActive={isActive}
          isOnFocus={id === currentKey}
          id={id}
          label={label}
          disabled={disabled}
          range={globalState?.age ?? [0, 0]}
          onClick={isMobile ? displayModal(id) : displayDropdown}
          leadingIcon={
            <FeatherIcon
              strokeWidth="2px"
              icon={icon ?? ''}
              color={'var(--neutral600)'}
              size="18"
            />
          }
        />
      </div>

      {isMobile ? (
        <ModalFilter
          title={renderTitleModal(id)}
          isActive={isActive}
          isOpen={currentKey === id}
          onDismiss={dismissModal}
          onRemoveFilters={isActive ? handleOnRemoveFilter : onCloseMenu}
          onSetFilters={handleOnSetFilter}
        >
          {value.map(renderValueInModal)}
        </ModalFilter>
      ) : (
        <Menu
          id={id}
          animation={false}
          onHidden={dropdownIsOpen ? handleOnHidden : noop}
        >
          <div className={styles.dropdown}>
            <div className={styles.dropdownContent}>
              {value.length ? (
                value.map(renderValueInDropdown)
              ) : (
                <p> {t('filters.labels.noOptions')}</p>
              )}
            </div>
            <div className={styles.dropdownFooter}>
              <Button
                type={BUTTON_TYPE.PRIMARY}
                mode={BUTTON_MODE.BASIC}
                disabled={!value.length}
                onClick={handleOnSetFilter}
              >
                {t('filters.labels.apply')}
              </Button>
              <Button
                type={BUTTON_TYPE.PRIMARY}
                mode={BUTTON_MODE.GHOST}
                onClick={isActive ? handleOnRemoveFilter : onCloseMenu}
              >
                {isActive
                  ? t('filters.labels.remove')
                  : t('filters.labels.cancel')}
              </Button>
            </div>
          </div>
        </Menu>
      )}
    </>
  );
}
