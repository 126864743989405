/* eslint-disable react/jsx-key */
import styles from './styles.module.scss';
interface IProps {
  children?: React.ReactElement | React.ReactElement[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label?: any;
  val?: any;
}

export default function AgeInput({ val, label, children, onChange }: IProps) {
  return (
    <label className={`${styles.control} ${styles.controlCheckbox}`}>
      {children}
      <input
        name={label.toLowerCase()}
        defaultValue={val[label.toLowerCase()]}
        // value={val[label.toLowerCase()]}
        type="text"
        onChange={onChange}
      />
    </label>
  );
}
