/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import SplitLayout from '@components/SplitLayout';
import OnBoardingRightSide from '@components/OnBoardingRightSide';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import FeatherIcon from 'feather-icons-react';
import { getCssVariable } from '@utils/index';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';

import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import { useUser } from 'reactfire';

export default function PageNotFound() {
  const { t } = useTranslation('notFound');
  const navigate = useNavigate();
  const { data: user } = useUser();
  function goToDashboard() {
    navigate(ROUTES.BASE);
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
      // destination={user ? 'help' : ROUTES.SIGN_IN}
      // buttonText={user ? t('header.logged') : t('header.unlogged')}
      />

      <LayoutWrapper>
        <SplitLayout
          left={
            <div className={styles.container}>
              <SuccessMessageOnBoarding
                title={t('title')}
                message={t('description')}
                leadingIcon={
                  <Button
                    style={{
                      backgroundColor: getCssVariable('red-light'),
                      cursor: 'default',
                    }}
                    type={BUTTON_TYPE.RECORDING}
                    size={BUTTON_SIZE.ONLY_ICON}
                    leadingIcon={
                      <FeatherIcon
                        strokeWidth="1px"
                        icon="alert-triangle"
                        color={'var(--red)'}
                        size="40"
                      />
                    }
                  ></Button>
                }
              >
                <Button
                  onClick={goToDashboard}
                  type={BUTTON_TYPE.PRIMARY}
                  mode={BUTTON_MODE.OUTLINE}
                >
                  {t('buttonLabel')}
                </Button>
              </SuccessMessageOnBoarding>
            </div>
          }
          right={<OnBoardingRightSide />}
        />
      </LayoutWrapper>
    </>
  );
}
