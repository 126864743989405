import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import Header from '@components/Header';
import Loader from '@components/Loader';
import { ROUTES } from '@routes/routes';
import FeatherIcon from 'feather-icons-react';
import { UserCardContext } from '@contexts/UserCardContext';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useUser, useFirestore } from 'reactfire';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import { useWindowSize } from '@hooks/useWindowResize';
import UserCard from '@components/UserCard';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import { getCssVariable } from '@utils/index';

export default function Favorite() {
  const { t } = useTranslation('library');
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();
  const [isLoading, setIsLoading] = React.useState(true);
  const windowSize = useWindowSize();
  const [userFavoritesData, setUserFavoritesData] = React.useState<any>([]);
  const { usersFavorites, setUsersFavorites } =
    React.useContext(UserCardContext);

  const getUserData = React.useCallback(async () => {
    if (user) {
      setIsLoading(true);
      const userPrivateRef = doc(db, `users/${user.uid}/private`, 'library');
      const docPrivateSnap = await getDoc(userPrivateRef);
      if (docPrivateSnap.exists()) {
        const userPrivateData = docPrivateSnap.data();
        setUsersFavorites(userPrivateData?.favorites);
        setIsLoading(false);
      } else {
        setUsersFavorites([]);
      }
    } else {
      setIsLoading(false);
    }
  }, [user, db, setUsersFavorites]);

  React.useEffect(() => {
    async function getFav() {
      if (usersFavorites.length > 0) {
        const userFavData = await query(
          collection(db, 'users'),
          where('id', 'in', [...usersFavorites])
        );
        const docLibrarySnapForSize = await getDocs(userFavData);
        if (docLibrarySnapForSize.size > 0) {
          const data = docLibrarySnapForSize?.docs?.map((doc) => doc.data());
          setUserFavoritesData(data);
          setIsLoading(false);
        }
      } else {
        setUserFavoritesData([]);
        setIsLoading(false);
      }
    }
    getFav();
  }, [db, usersFavorites, setUserFavoritesData]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  function goToFav() {
    navigate(ROUTES.FAVORITE);
  }

  function goToLibrary() {
    navigate(ROUTES.BASE);
  }
  const routes = [{ path: '/favorite' }];

  const location = useLocation();

  const route = matchRoutes(routes, location);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('loggedUser.favorite.pageTitle')}</title>
        <meta
          name="description"
          content={t('loggedUser.favorite.descriptionPage')}
        />
      </Helmet>
      <Header
        style={{ alignItems: 'unset' }}
        firstButton={
          <Button
            style={{
              color:
                route && route[0].pathname === '/'
                  ? 'var(--blue)'
                  : 'var(--neutral400)',
            }}
            onClick={goToLibrary}
            mode={BUTTON_MODE.MENU}
            type={BUTTON_TYPE.PRIMARY}
            size={
              windowSize.width > 500
                ? BUTTON_SIZE.WITH_ICON
                : BUTTON_SIZE.ONLY_ICON
            }
            leadingIcon={
              <FeatherIcon
                strokeWidth="2px"
                icon="message-square"
                color={
                  route && route[0].pathname === '/'
                    ? 'var(--blue)'
                    : 'var(--neutral400)'
                }
                size="20"
              />
            }
          >
            {t('header.loggedUser.library')}
          </Button>
        }
        secondButton={
          <Button
            isActive={route !== null}
            onClick={goToFav}
            mode={BUTTON_MODE.MENU}
            type={BUTTON_TYPE.PRIMARY}
            size={
              windowSize.width > 500
                ? BUTTON_SIZE.WITH_ICON
                : BUTTON_SIZE.ONLY_ICON
            }
            leadingIcon={
              <FeatherIcon
                strokeWidth="2px"
                icon="heart"
                color={'var(--blue)'}
                size="20"
              />
            }
          >
            {t('header.loggedUser.favorite')}
          </Button>
        }
      />

      <div className={styles.container}>
        <div className={styles.wrapper}>
          {userFavoritesData.length > 0 ? (
            <>
              <SuccessMessageOnBoarding
                title={t('loggedUser.favorite.title')}
                message=""
                noPadding
              />
              <div className={styles.cardContainer}>
                {userFavoritesData.map((userCardInfo: any) => (
                  <UserCard
                    firstname={userCardInfo?.firstname}
                    describeYourself={userCardInfo?.describeYourself}
                    voiceType={userCardInfo?.voiceType}
                    userId={userCardInfo?.id}
                    key={userCardInfo?.id}
                    language={userCardInfo?.language}
                    urlRec={userCardInfo?.URL_recording}
                    profilePic={userCardInfo?.profile_photo}
                    isLogged={user && user}
                    avatarPhoto={userCardInfo?.avatar_photo}
                    isModelReady={userCardInfo?.isModelReady}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className={styles.noFavoriteContainer}>
              <SuccessMessageOnBoarding
                isCenter
                title=""
                message={t('loggedUser.favorite.noFavorite.message')}
                leadingIcon={
                  <Button
                    style={{
                      backgroundColor: getCssVariable('orange-light'),
                      cursor: 'default',
                    }}
                    type={BUTTON_TYPE.RECORDING}
                    size={BUTTON_SIZE.ONLY_ICON}
                    leadingIcon={
                      <FeatherIcon
                        strokeWidth="1px"
                        icon="heart"
                        color={'var(--orange)'}
                        size="40"
                      />
                    }
                  ></Button>
                }
              />
              <Button
                onClick={goToLibrary}
                type={BUTTON_TYPE.PRIMARY}
                mode={BUTTON_MODE.BASIC}
              >
                {t('loggedUser.favorite.noFavorite.label')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
