import * as React from 'react';
import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Input from '@components/Input';
import { emailRegex } from '@utils/index';
import Button, { BUTTON_TYPE } from '@components/Button';
import Select from 'react-select';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import LibraryCaption from '@components/LibraryCaption';
import vectorUnderline from '@assets/img/vector-underline.svg';
import InputCheckBox, { CHECKBOX_MODE } from '@components/InputCheckbox';
import Spinner from '@components/Spinner';
import platform from 'platform';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import { sample } from 'lodash';
// import FeatherIcon from 'feather-icons-react';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  birthday: string;
  language: string;
  gender: string;
  country: string;
  whyNeedVoice: string;
  therapist: string;
  phone: string;
  aacModel?: string;
  otherModel: string;
  agreeTerms: boolean;
  agreeTerms2: boolean;
  agreeTerms3: boolean;
  avatar_photo?: string;
};

type Dirty = {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  password: boolean;
  birthday: boolean;
  language: boolean;
  gender: boolean;
  country: boolean;
  whyNeedVoice: boolean;
  therapist: boolean;
  phone: boolean;
  aacModel: boolean;
  otherModel: boolean;
  agreeTerms: boolean;
  agreeTerms2: boolean;
  agreeTerms3: boolean;
};
type Props = {
  isLoading: boolean;
  step: number;

  error: { type: string; message: string };
  setStep: (step: number) => void;
  onSubmit: ({
    firstName,
    lastName,
    email,
    password,
    birthday,
    language,
    country,
    gender,
    whyNeedVoice,
    aacModel,
    otherModel,
    therapist,
    phone,
    avatar_photo,
    agreeTerms,
    agreeTerms2,
    agreeTerms3,
  }: User) => void;
};

export default function SignUpForm({
  isLoading,
  onSubmit,
  step,
  error,
  setStep,
}: Props) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onSubmit', shouldFocusError: false });

  const [currentCountry, setCurrentCountry] = React.useState<any>();

  const getCountryCode = React.useCallback(async () => {
    try {
      const response = await fetch('https://api.country.is');
      const country = await response.json();
      setCurrentCountry(country.country ?? 'ZZ');
    } catch (error) {
      setCurrentCountry('ZZ');
    }
  }, []);

  React.useEffect(() => {
    getCountryCode();
  }, [getCountryCode]);

  const [isIOs, setIsIOs] = React.useState<any>();
  const [isAndroid, setIsAndroid] = React.useState<any>();

  const [randomProfileImages, setRandomProfileImages] = React.useState([]);

  const URL_API = `https://api.unsplash.com/collections/nVzn36a7qik/photos?per_page=100&client_id=${process.env.REACT_APP_UNSPLASH_API}`;

  async function getImage() {
    try {
      const res = await fetch(URL_API);
      const data = await res.json();

      setRandomProfileImages(
        data?.map((image: any) => image?.urls.thumb) ?? []
      );
    } catch (err) {
      console.log(err);
      setRandomProfileImages([]);
    }
  }

  const {
    register: registerSecondForm,
    handleSubmit: handleSubmitStep1,
    watch: watchSecondForm,
    control,
    formState: { errors: errorsSecondForm },
  } = useForm({ mode: 'onSubmit', shouldFocusError: false });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const password = watch('password');
  const phone = watchSecondForm('phone');
  const birthday = watchSecondForm('birthday');
  const gender = watchSecondForm('gender');
  const language = watchSecondForm('language');
  const country = watchSecondForm('country');
  const whyNeedVoice = watchSecondForm('whyNeedVoice');
  const aacModel = watchSecondForm('aacModel');
  const therapist = watchSecondForm('therapist');
  const otherModel = watchSecondForm('otherModel');
  const agree = watchSecondForm('agreeTerms');
  const secondAgree = watchSecondForm('agreeTerms2');
  const thirdAgree = watchSecondForm('agreeTerms3');

  React.useEffect(() => {
    clearErrors();
  }, [email, clearErrors, password]);

  React.useEffect(() => {
    if (platform?.os) {
      setIsIOs(platform?.os?.family?.includes('iOS'));
    }
    if (platform?.os) {
      setIsAndroid(platform?.os?.family?.includes('Android'));
    }
  }, []);

  const [partialUser, setPartialUser] = React.useState<Partial<User>>({});
  const [isDirty, setIsDirty] = React.useState<Dirty>({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    birthday: false,
    language: false,
    gender: false,
    country: false,
    whyNeedVoice: false,
    therapist: false,
    phone: false,
    aacModel: false,
    otherModel: false,
    agreeTerms: false,
    agreeTerms2: false,
    agreeTerms3: false,
  });

  const { t } = useTranslation(['signUp', 'countries']);

  const { ref: firstNameRef, ...firstNameInputProps } = register('firstName', {
    required: t('signUp:form.errorsState.required.firstname'),
  });

  const { ref: lastNameRef, ...lastNameInputProps } = register('lastName', {
    required: t('signUp:form.errorsState.required.lastname'),
  });

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('signUp:form.errorsState.invalid.email'),
    pattern: {
      value: emailRegex,
      message: t('signUp:form.errorsState.invalid.email'),
    },
  });

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: t('signUp:form.errorsState.invalid.password'),

    minLength: {
      value: 6,
      message: t('signUp:form.errorsState.invalid.passwordMinLength'),
    },
  });

  const { ref: birthdayRef, ...birthdayProps } = registerSecondForm(
    'birthday',
    {
      required: t('signUp:form.errorsState.required.generic'),
    }
  );

  const { ref: therapistRef, ...therapistInputProps } = registerSecondForm(
    'therapist',
    {
      required: t('signUp:form.errorsState.required.generic'),
    }
  );

  const { ref: otherModelRef, ...otherModelInputProps } = registerSecondForm(
    'otherModel',
    {
      required:
        aacModel === 'other'
          ? t('signUp:form.errorsState.required.generic')
          : false,
    }
  );

  // const goToAutoDonate = React.useCallback(() => {
  //   window.open(`${process.env.REACT_APP_VFP_DONATE_URL}/autodonate`, '_blank');
  // }, []);

  const { ref: agreeTermsRef, ...agreeTermsProps } = registerSecondForm(
    'agreeTerms',
    {
      required: t('signUp:form.errorsState.required.generic'),
    }
  );

  const { ref: agreeTerms2Ref, ...agreeTerms2Props } = registerSecondForm(
    'agreeTerms2',
    {
      required: t('signUp:form.errorsState.required.generic'),
    }
  );

  const { ref: agreeTerms3Ref, ...agreeTerms3Props } = registerSecondForm(
    'agreeTerms3',
    {
      required: t('signUp:form.errorsState.required.generic'),
    }
  );

  function createDownloadLinkPDF(
    prefix: string,
    language: string,
    nameFile: string,
    pageNumber?: number
  ) {
    const splitLang = language ? language.split('-')[0] : 'en';
    return `/${prefix}-${nameFile}-${splitLang}.pdf${
      pageNumber ? '#page=' + pageNumber.toString() : ''
    }`;
  }

  React.useEffect(() => {
    if (error?.message) {
      setError(error.type, { type: 'manual', message: error.message });
    }
  }, [error, setError]);

  async function handleNextStep({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) {
    setPartialUser({ email, lastName, firstName, password });
    setIsDirty({
      email: email.length > 0,
      password: password.length > 0,
      firstName: firstName.length > 0,
      lastName: lastName.length > 0,
    } as Dirty);

    await getImage();

    setStep(step + 1);
  }

  function handleNextStep2({
    birthday,
    gender,
    language,
    country,
    whyNeedVoice,
    aacModel,
    otherModel,
    therapist,
    phone,
    agreeTerms,
    agreeTerms2,
  }: {
    birthday: string;
    gender: string;
    language: string;
    country: string;
    whyNeedVoice: string;
    aacModel: string;
    otherModel?: string | undefined;
    therapist: string;
    phone: string;
    agreeTerms: boolean;
    agreeTerms2: boolean;
  }) {
    setPartialUser({
      ...partialUser,
      birthday,
      language,
      gender,
      country,
      whyNeedVoice,
      aacModel,
      otherModel: otherModel ?? '',
      therapist,
      phone,
      agreeTerms,
      agreeTerms2,
    });

    setIsDirty({
      birthday: birthday.length > 0,
      language: language.length > 0,
      gender: gender.length > 0,
      country: country.length > 0,
      whyNeedVoice: whyNeedVoice.length > 0,
      aacModel: aacModel.length > 0,
      otherModel: otherModel && otherModel.length > 0,
      therapist: therapist.length > 0,
      phone: phone.length > 0,
      agreeTerms: agreeTerms ?? false,
      agreeTerms2: agreeTerms2 ?? false,
    } as Dirty);

    // setStep(step + 1);

    onSubmit({
      ...partialUser,
      birthday,
      gender,
      language,
      country,
      whyNeedVoice,
      aacModel,
      otherModel,
      therapist,
      phone,
      agreeTerms,
      agreeTerms2,
      avatar_photo:
        randomProfileImages.length > 0 && sample(randomProfileImages),
    } as User);
  }

  const customStylesLanguage = {
    container: (base: any) => ({
      ...base,
      '&:not(:last-child)': {
        marginBottom: '16px !important',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      color: '#000',
      border: '1px solid var(--neutral300)',
      borderColor: 'transparent',
      marginTop: '4px',
      borderRadius: '4px',
      backgroundColor: state.isSelected ? '#f1f1f1' : '#fff',
      boxShadow: state.isFocused
        ? '0 0 0 2px var(--blue) !important'
        : errorsSecondForm.language
        ? '0 0 0 2px var(--red)'
        : '0px 1px 4px rgba(0, 0, 0, 0.04)',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '4px',
      },
      '&:hover': {
        border: '1px solid var(--neutral400)',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '48px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      fontSize: '16px',
      color: 'var(--neutral600)',
    }),
    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: language ? 'block' : 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      // display: 'none',
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
  };

  const customStylesCountry = {
    container: (base: any) => ({
      ...base,
      '&:not(:last-child)': {
        marginBottom: '16px !important',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      color: '#000',
      border: '1px solid var(--neutral300)',
      marginTop: '4px',
      borderColor: 'transparent',
      borderRadius: '4px',
      backgroundColor: state.isSelected ? '#f1f1f1' : '#fff',
      boxShadow: state.isFocused
        ? '0 0 0 2px var(--blue) !important'
        : errorsSecondForm.country
        ? '0 0 0 2px var(--red)'
        : '0px 1px 4px rgba(0, 0, 0, 0.04)',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '4px',
      },
      '&:hover': {
        border: '1px solid var(--neutral400)',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '48px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      fontSize: '16px',
      color: 'var(--neutral600)',
    }),
    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: country ? 'block' : 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      // display: 'none',
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
    menuList: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'transparent',
    }),
  };

  const customStylesVoice = {
    container: (base: any) => ({
      ...base,
      '&:not(:last-child)': {
        marginBottom: '16px !important',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      marginTop: '4px',
      color: '#000',
      border: '1px solid var(--neutral300)',
      borderColor: 'transparent',
      borderRadius: '4px',
      backgroundColor: state.isSelected ? '#f1f1f1' : '#fff',
      boxShadow: state.isFocused
        ? '0 0 0 2px var(--blue) !important'
        : errorsSecondForm.whyNeedVoice
        ? '0 0 0 2px var(--red)'
        : '0px 1px 4px rgba(0, 0, 0, 0.04)',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '4px',
      },
      '&:hover': {
        border: '1px solid var(--neutral400)',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '48px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      fontSize: '16px',
      color: 'var(--neutral600)',
    }),
    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: whyNeedVoice ? 'block' : 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      // display: 'none',
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
  };

  const customStylesModel = {
    container: (base: any) => ({
      ...base,
      '&:not(:last-child)': {
        marginBottom: '16px !important',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      marginTop: '4px',
      color: '#000',
      border: '1px solid var(--neutral300)',
      borderColor: 'transparent',
      borderRadius: '4px',
      backgroundColor: state.isSelected ? '#f1f1f1' : '#fff',
      boxShadow: state.isFocused
        ? '0 0 0 2px var(--blue) !important'
        : errorsSecondForm.aacModel
        ? '0 0 0 2px var(--red)'
        : '0px 1px 4px rgba(0, 0, 0, 0.04)',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '4px',
      },
      '&:hover': {
        border: '1px solid var(--neutral400)',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '48px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      fontSize: '16px',
      color: 'var(--neutral600)',
    }),
    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: aacModel ? 'block' : 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      // display: 'none',
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
  };

  const customStylesGender = {
    container: (base: any) => ({
      ...base,
      '&:not(:last-child)': {
        marginBottom: '16px !important',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      marginBottom: '0 !important',
      marginTop: '4px',
      color: '#000',
      border: '1px solid var(--neutral300)',
      borderColor: 'transparent',
      borderRadius: '4px',
      backgroundColor: state.isSelected ? '#f1f1f1' : '#fff',
      boxShadow: state.isFocused
        ? '0 0 0 2px var(--blue) !important'
        : errorsSecondForm.gender
        ? '0 0 0 2px var(--red)'
        : '0px 1px 4px rgba(0, 0, 0, 0.04)',
      '&:focus': {
        boxShadow: '0 0 0 1px #cccccc',
        border: 'none',
        borderRadius: '4px',
      },
      '&:hover': {
        border: '1px solid var(--neutral400)',
        boxShadow: 'none',
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginBottom: '0px !important',
      height: '48px',
      borderColor: '#cccccc',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      fontSize: '16px',
      color: 'var(--neutral600)',
    }),
    input: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      marginBottom: '0 !important',
      '& > div': {
        marginBottom: '0 !important',
      },
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      display: gender ? 'block' : 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      // display: 'none',
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '16px',
      marginBottom: '0 !important',
      padding: '4px 0',
    }),
  };

  const placeholderColor = !birthday
    ? 'var(--neutral300)'
    : 'var(--neutral600)';

  const whyNeedVoiceSelect = [
    {
      label: t('signUp:form.select.whyNeedVoice.reason1'),
      value:
        'I have been diagnosed a nervous system disease which is likely to impact my ability to speak in the future',
    },
    {
      label: t('signUp:form.select.whyNeedVoice.reason2'),
      value:
        'I have a nervous system disease which prevents me from using my voice to speak',
    },
    {
      label: t('signUp:form.select.whyNeedVoice.reason3'),
      value: 'Other',
    },
  ];

  const aacModelSelect = [
    {
      label: t('signUp:form.select.aacModel.model1'),
      value: 'Yes',
    },
    {
      label: t('signUp:form.select.aacModel.model2'),
      value: 'No',
    },
  ];

  const genderSelect = [
    { label: t('signUp:form.select.gender.male'), value: 'male' },
    { label: t('signUp:form.select.gender.female'), value: 'female' },
  ];

  const languagesSelect = [
    { value: 'en-EN', label: t('signUp:form.select.languages.english') },
    { value: 'fr-FR', label: t('signUp:form.select.languages.french') },
    { value: 'de-DE', label: t('signUp:form.select.languages.german') },
    { value: 'it-IT', label: t('signUp:form.select.languages.italian') },
    { value: 'es-ES', label: t('signUp:form.select.languages.spanish') },
  ];

  const countrySelect = [
    { label: t('countries:AF'), value: 'AF', callingCodes: ['93'] },
    { label: t('countries:AX'), value: 'AX', callingCodes: ['358'] },
    { label: t('countries:AL'), value: 'AL', callingCodes: ['355'] },
    { label: t('countries:DZ'), value: 'DZ', callingCodes: ['213'] },
    { label: t('countries:AS'), value: 'AS', callingCodes: ['1684'] },
    { label: t('countries:AD'), value: 'AD', callingCodes: ['376'] },
    { label: t('countries:AO'), value: 'AO', callingCodes: ['244'] },
    { label: t('countries:AI'), value: 'AI', callingCodes: ['1264'] },
    { label: t('countries:AQ'), value: 'AQ', callingCodes: ['672'] },
    { label: t('countries:AG'), value: 'AG', callingCodes: ['1268'] },
    { label: t('countries:AR'), value: 'AR', callingCodes: ['54'] },
    { label: t('countries:AM'), value: 'AM', callingCodes: ['374'] },
    { label: t('countries:AW'), value: 'AW', callingCodes: ['297'] },
    { label: t('countries:AU'), value: 'AU', callingCodes: ['61'] },
    { label: t('countries:AT'), value: 'AT', callingCodes: ['43'] },
    { label: t('countries:AZ'), value: 'AZ', callingCodes: ['994'] },
    { label: t('countries:BS'), value: 'BS', callingCodes: ['1242'] },
    { label: t('countries:BH'), value: 'BH', callingCodes: ['973'] },
    { label: t('countries:BD'), value: 'BD', callingCodes: ['880'] },
    { label: t('countries:BB'), value: 'BB', callingCodes: ['1246'] },
    { label: t('countries:BY'), value: 'BY', callingCodes: ['375'] },
    { label: t('countries:BE'), value: 'BE', callingCodes: ['32'] },
    { label: t('countries:BZ'), value: 'BZ', callingCodes: ['501'] },
    { label: t('countries:BJ'), value: 'BJ', callingCodes: ['229'] },
    { label: t('countries:BM'), value: 'BM', callingCodes: ['1441'] },
    { label: t('countries:BT'), value: 'BT', callingCodes: ['975'] },
    {
      label: t('countries:BO'),
      value: 'BO',
      callingCodes: ['591'],
    },
    {
      label: t('countries:BQ'),
      value: 'BQ',
      callingCodes: ['5997'],
    },
    { label: t('countries:BA'), value: 'BA', callingCodes: ['387'] },
    { label: t('countries:BW'), value: 'BW', callingCodes: ['267'] },
    { label: t('countries:BV'), value: 'BV', callingCodes: [''] },
    { label: t('countries:BR'), value: 'BR', callingCodes: ['55'] },
    {
      label: t('countries:IO'),
      value: 'IO',
      callingCodes: ['246'],
    },
    {
      label: t('countries:UM'),
      value: 'UM',
      callingCodes: [''],
    },
    { label: t('countries:VG'), value: 'VG', callingCodes: ['1284'] },
    { label: t('countries:VI'), value: 'VI', callingCodes: ['1 340'] },
    { label: t('countries:BN'), value: 'BN', callingCodes: ['673'] },
    { label: t('countries:BG'), value: 'BG', callingCodes: ['359'] },
    { label: t('countries:BF'), value: 'BF', callingCodes: ['226'] },
    { label: t('countries:BI'), value: 'BI', callingCodes: ['257'] },
    { label: t('countries:KH'), value: 'KH', callingCodes: ['855'] },
    { label: t('countries:CM'), value: 'CM', callingCodes: ['237'] },
    { label: t('countries:CA'), value: 'CA', callingCodes: ['1'] },
    { label: t('countries:CV'), value: 'CV', callingCodes: ['238'] },
    { label: t('countries:KY'), value: 'KY', callingCodes: ['1345'] },
    { label: t('countries:CF'), value: 'CF', callingCodes: ['236'] },
    { label: t('countries:TD'), value: 'TD', callingCodes: ['235'] },
    { label: t('countries:CL'), value: 'CL', callingCodes: ['56'] },
    { label: t('countries:CN'), value: 'CN', callingCodes: ['86'] },
    { label: t('countries:CX'), value: 'CX', callingCodes: ['61'] },
    { label: t('countries:CC'), value: 'CC', callingCodes: ['61'] },
    { label: t('countries:CO'), value: 'CO', callingCodes: ['57'] },
    { label: t('countries:KM'), value: 'KM', callingCodes: ['269'] },
    { label: t('countries:CG'), value: 'CG', callingCodes: ['242'] },
    {
      label: t('countries:CD'),
      value: 'CD',
      callingCodes: ['243'],
    },
    { label: t('countries:CK'), value: 'CK', callingCodes: ['682'] },
    { label: t('countries:CR'), value: 'CR', callingCodes: ['506'] },
    { label: t('countries:HR'), value: 'HR', callingCodes: ['385'] },
    { label: t('countries:CU'), value: 'CU', callingCodes: ['53'] },
    { label: t('countries:CW'), value: 'CW', callingCodes: ['599'] },
    { label: t('countries:CY'), value: 'CY', callingCodes: ['357'] },
    { label: t('countries:CZ'), value: 'CZ', callingCodes: ['420'] },
    { label: t('countries:DK'), value: 'DK', callingCodes: ['45'] },
    { label: t('countries:DJ'), value: 'DJ', callingCodes: ['253'] },
    { label: t('countries:DM'), value: 'DM', callingCodes: ['1767'] },
    {
      label: t('countries:DO'),
      value: 'DO',
      callingCodes: ['1809', '1829', '1849'],
    },
    { label: t('countries:EC'), value: 'EC', callingCodes: ['593'] },
    { label: t('countries:EG'), value: 'EG', callingCodes: ['20'] },
    { label: t('countries:SV'), value: 'SV', callingCodes: ['503'] },
    { label: t('countries:GQ'), value: 'GQ', callingCodes: ['240'] },
    { label: t('countries:ER'), value: 'ER', callingCodes: ['291'] },
    { label: t('countries:EE'), value: 'EE', callingCodes: ['372'] },
    { label: t('countries:ET'), value: 'ET', callingCodes: ['251'] },
    {
      label: t('countries:FK'),
      value: 'FK',
      callingCodes: ['500'],
    },
    { label: t('countries:FO'), value: 'FO', callingCodes: ['298'] },
    { label: t('countries:FJ'), value: 'FJ', callingCodes: ['679'] },
    { label: t('countries:FI'), value: 'FI', callingCodes: ['358'] },
    { label: t('countries:FR'), value: 'FR', callingCodes: ['33'] },
    { label: t('countries:GF'), value: 'GF', callingCodes: ['594'] },
    { label: t('countries:PF'), value: 'PF', callingCodes: ['689'] },
    { label: t('countries:TF'), value: 'TF', callingCodes: [''] },
    { label: t('countries:GA'), value: 'GA', callingCodes: ['241'] },
    { label: t('countries:GM'), value: 'GM', callingCodes: ['220'] },
    { label: t('countries:GE'), value: 'GE', callingCodes: ['995'] },
    { label: t('countries:DE'), value: 'DE', callingCodes: ['49'] },
    { label: t('countries:GH'), value: 'GH', callingCodes: ['233'] },
    { label: t('countries:GI'), value: 'GI', callingCodes: ['350'] },
    { label: t('countries:GR'), value: 'GR', callingCodes: ['30'] },
    { label: t('countries:GL'), value: 'GL', callingCodes: ['299'] },
    { label: t('countries:GD'), value: 'GD', callingCodes: ['1473'] },
    { label: t('countries:GP'), value: 'GP', callingCodes: ['590'] },
    { label: t('countries:GU'), value: 'GU', callingCodes: ['1671'] },
    { label: t('countries:GT'), value: 'GT', callingCodes: ['502'] },
    { label: t('countries:GG'), value: 'GG', callingCodes: ['44'] },
    { label: t('countries:GN'), value: 'GN', callingCodes: ['224'] },
    { label: t('countries:GW'), value: 'GW', callingCodes: ['245'] },
    { label: t('countries:GY'), value: 'GY', callingCodes: ['592'] },
    { label: t('countries:HT'), value: 'HT', callingCodes: ['509'] },
    {
      label: t('countries:HM'),
      value: 'HM',
      callingCodes: [''],
    },
    { label: t('countries:VA'), value: 'VA', callingCodes: ['379'] },
    { label: t('countries:HN'), value: 'HN', callingCodes: ['504'] },
    { label: t('countries:HK'), value: 'HK', callingCodes: ['852'] },
    { label: t('countries:HU'), value: 'HU', callingCodes: ['36'] },
    { label: t('countries:IS'), value: 'IS', callingCodes: ['354'] },
    { label: t('countries:IN'), value: 'IN', callingCodes: ['91'] },
    { label: t('countries:ID'), value: 'ID', callingCodes: ['62'] },
    { label: t('countries:CI'), value: 'CI', callingCodes: ['225'] },
    { label: t('countries:IR'), value: 'IR', callingCodes: ['98'] },
    { label: t('countries:IQ'), value: 'IQ', callingCodes: ['964'] },
    { label: t('countries:IE'), value: 'IE', callingCodes: ['353'] },
    { label: t('countries:IM'), value: 'IM', callingCodes: ['44'] },
    { label: t('countries:IL'), value: 'IL', callingCodes: ['972'] },
    { label: t('countries:IT'), value: 'IT', callingCodes: ['39'] },
    { label: t('countries:JM'), value: 'JM', callingCodes: ['1876'] },
    { label: t('countries:JP'), value: 'JP', callingCodes: ['81'] },
    { label: t('countries:JE'), value: 'JE', callingCodes: ['44'] },
    { label: t('countries:JO'), value: 'JO', callingCodes: ['962'] },
    { label: t('countries:KZ'), value: 'KZ', callingCodes: ['76', '77'] },
    { label: t('countries:KE'), value: 'KE', callingCodes: ['254'] },
    { label: t('countries:KI'), value: 'KI', callingCodes: ['686'] },
    { label: t('countries:KW'), value: 'KW', callingCodes: ['965'] },
    { label: t('countries:KG'), value: 'KG', callingCodes: ['996'] },
    {
      label: t('countries:LA'),
      value: 'LA',
      callingCodes: ['856'],
    },
    { label: t('countries:LV'), value: 'LV', callingCodes: ['371'] },
    { label: t('countries:LB'), value: 'LB', callingCodes: ['961'] },
    { label: t('countries:LS'), value: 'LS', callingCodes: ['266'] },
    { label: t('countries:LR'), value: 'LR', callingCodes: ['231'] },
    { label: t('countries:LY'), value: 'LY', callingCodes: ['218'] },
    { label: t('countries:LI'), value: 'LI', callingCodes: ['423'] },
    { label: t('countries:LT'), value: 'LT', callingCodes: ['370'] },
    { label: t('countries:LU'), value: 'LU', callingCodes: ['352'] },
    { label: t('countries:MO'), value: 'MO', callingCodes: ['853'] },
    {
      label: t('countries:MK'),
      value: 'MK',
      callingCodes: ['389'],
    },
    { label: t('countries:MG'), value: 'MG', callingCodes: ['261'] },
    { label: t('countries:MW'), value: 'MW', callingCodes: ['265'] },
    { label: t('countries:MY'), value: 'MY', callingCodes: ['60'] },
    { label: t('countries:MV'), value: 'MV', callingCodes: ['960'] },
    { label: t('countries:ML'), value: 'ML', callingCodes: ['223'] },
    { label: t('countries:MT'), value: 'MT', callingCodes: ['356'] },
    { label: t('countries:MH'), value: 'MH', callingCodes: ['692'] },
    { label: t('countries:MQ'), value: 'MQ', callingCodes: ['596'] },
    { label: t('countries:MR'), value: 'MR', callingCodes: ['222'] },
    { label: t('countries:MU'), value: 'MU', callingCodes: ['230'] },
    { label: t('countries:YT'), value: 'YT', callingCodes: ['262'] },
    { label: t('countries:MX'), value: 'MX', callingCodes: ['52'] },
    {
      label: t('countries:FM'),
      value: 'FM',
      callingCodes: ['691'],
    },
    { label: t('countries:MD'), value: 'MD', callingCodes: ['373'] },
    { label: t('countries:MC'), value: 'MC', callingCodes: ['377'] },
    { label: t('countries:MN'), value: 'MN', callingCodes: ['976'] },
    { label: t('countries:ME'), value: 'ME', callingCodes: ['382'] },
    { label: t('countries:MS'), value: 'MS', callingCodes: ['1664'] },
    { label: t('countries:MA'), value: 'MA', callingCodes: ['212'] },
    { label: t('countries:MZ'), value: 'MZ', callingCodes: ['258'] },
    { label: t('countries:MM'), value: 'MM', callingCodes: ['95'] },
    { label: t('countries:NA'), value: 'NA', callingCodes: ['264'] },
    { label: t('countries:NR'), value: 'NR', callingCodes: ['674'] },
    { label: t('countries:NP'), value: 'NP', callingCodes: ['977'] },
    { label: t('countries:NL'), value: 'NL', callingCodes: ['31'] },
    { label: t('countries:NC'), value: 'NC', callingCodes: ['687'] },
    { label: t('countries:NZ'), value: 'NZ', callingCodes: ['64'] },
    { label: t('countries:NI'), value: 'NI', callingCodes: ['505'] },
    { label: t('countries:NE'), value: 'NE', callingCodes: ['227'] },
    { label: t('countries:NG'), value: 'NG', callingCodes: ['234'] },
    { label: t('countries:NU'), value: 'NU', callingCodes: ['683'] },
    { label: t('countries:NF'), value: 'NF', callingCodes: ['672'] },
    {
      label: t('countries:KP'),
      value: 'KP',
      callingCodes: ['850'],
    },
    { label: t('countries:MP'), value: 'MP', callingCodes: ['1670'] },
    { label: t('countries:NO'), value: 'NO', callingCodes: ['47'] },
    { label: t('countries:OM'), value: 'OM', callingCodes: ['968'] },
    { label: t('countries:PK'), value: 'PK', callingCodes: ['92'] },
    { label: t('countries:PW'), value: 'PW', callingCodes: ['680'] },
    { label: t('countries:PS'), value: 'PS', callingCodes: ['970'] },
    { label: t('countries:PA'), value: 'PA', callingCodes: ['507'] },
    { label: t('countries:PG'), value: 'PG', callingCodes: ['675'] },
    { label: t('countries:PY'), value: 'PY', callingCodes: ['595'] },
    { label: t('countries:PE'), value: 'PE', callingCodes: ['51'] },
    { label: t('countries:PH'), value: 'PH', callingCodes: ['63'] },
    { label: t('countries:PN'), value: 'PN', callingCodes: ['64'] },
    { label: t('countries:PL'), value: 'PL', callingCodes: ['48'] },
    { label: t('countries:PT'), value: 'PT', callingCodes: ['351'] },
    { label: t('countries:PR'), value: 'PR', callingCodes: ['1787', '1939'] },
    { label: t('countries:QA'), value: 'QA', callingCodes: ['974'] },
    { label: t('countries:XK'), value: 'XK', callingCodes: ['383'] },
    { label: t('countries:RE'), value: 'RE', callingCodes: ['262'] },
    { label: t('countries:RO'), value: 'RO', callingCodes: ['40'] },
    { label: t('countries:RU'), value: 'RU', callingCodes: ['7'] },
    { label: t('countries:RW'), value: 'RW', callingCodes: ['250'] },
    { label: t('countries:BL'), value: 'BL', callingCodes: ['590'] },
    {
      label: t('countries:SH'),
      value: 'SH',
      callingCodes: ['290'],
    },
    { label: t('countries:KN'), value: 'KN', callingCodes: ['1869'] },
    { label: t('countries:LC'), value: 'LC', callingCodes: ['1758'] },
    { label: t('countries:MF'), value: 'MF', callingCodes: ['590'] },
    { label: t('countries:PM'), value: 'PM', callingCodes: ['508'] },
    {
      label: t('countries:VC'),
      value: 'VC',
      callingCodes: ['1784'],
    },
    { label: t('countries:WS'), value: 'WS', callingCodes: ['685'] },
    { label: t('countries:SM'), value: 'SM', callingCodes: ['378'] },
    { label: t('countries:ST'), value: 'ST', callingCodes: ['239'] },
    { label: t('countries:SA'), value: 'SA', callingCodes: ['966'] },
    { label: t('countries:SN'), value: 'SN', callingCodes: ['221'] },
    { label: t('countries:RS'), value: 'RS', callingCodes: ['381'] },
    { label: t('countries:SC'), value: 'SC', callingCodes: ['248'] },
    { label: t('countries:SL'), value: 'SL', callingCodes: ['232'] },
    { label: t('countries:SG'), value: 'SG', callingCodes: ['65'] },
    { label: t('countries:SX'), value: 'SX', callingCodes: ['1721'] },
    { label: t('countries:SK'), value: 'SK', callingCodes: ['421'] },
    { label: t('countries:SI'), value: 'SI', callingCodes: ['386'] },
    { label: t('countries:SB'), value: 'SB', callingCodes: ['677'] },
    { label: t('countries:SO'), value: 'SO', callingCodes: ['252'] },
    { label: t('countries:ZA'), value: 'ZA', callingCodes: ['27'] },
    {
      label: t('countries:GS'),
      value: 'GS',
      callingCodes: ['500'],
    },
    { label: t('countries:KR'), value: 'KR', callingCodes: ['82'] },
    { label: t('countries:SS'), value: 'SS', callingCodes: ['211'] },
    { label: t('countries:ES'), value: 'ES', callingCodes: ['34'] },
    { label: t('countries:LK'), value: 'LK', callingCodes: ['94'] },
    { label: t('countries:SD'), value: 'SD', callingCodes: ['249'] },
    { label: t('countries:SR'), value: 'SR', callingCodes: ['597'] },
    { label: t('countries:SJ'), value: 'SJ', callingCodes: ['4779'] },
    { label: t('countries:SZ'), value: 'SZ', callingCodes: ['268'] },
    { label: t('countries:SE'), value: 'SE', callingCodes: ['46'] },
    { label: t('countries:CH'), value: 'CH', callingCodes: ['41'] },
    { label: t('countries:SY'), value: 'SY', callingCodes: ['963'] },
    { label: t('countries:TW'), value: 'TW', callingCodes: ['886'] },
    { label: t('countries:TJ'), value: 'TJ', callingCodes: ['992'] },
    {
      label: t('countries:TZ'),
      value: 'TZ',
      callingCodes: ['255'],
    },
    { label: t('countries:TH'), value: 'TH', callingCodes: ['66'] },
    { label: t('countries:TL'), value: 'TL', callingCodes: ['670'] },
    { label: t('countries:TG'), value: 'TG', callingCodes: ['228'] },
    { label: t('countries:TK'), value: 'TK', callingCodes: ['690'] },
    { label: t('countries:TO'), value: 'TO', callingCodes: ['676'] },
    { label: t('countries:TT'), value: 'TT', callingCodes: ['1868'] },
    { label: t('countries:TN'), value: 'TN', callingCodes: ['216'] },
    { label: t('countries:TR'), value: 'TR', callingCodes: ['90'] },
    { label: t('countries:TM'), value: 'TM', callingCodes: ['993'] },
    { label: t('countries:TC'), value: 'TC', callingCodes: ['1649'] },
    { label: t('countries:TV'), value: 'TV', callingCodes: ['688'] },
    { label: t('countries:UG'), value: 'UG', callingCodes: ['256'] },
    { label: t('countries:UA'), value: 'UA', callingCodes: ['380'] },
    { label: t('countries:AE'), value: 'AE', callingCodes: ['971'] },
    {
      label: t('countries:GB'),
      value: 'GB',
      callingCodes: ['44'],
    },
    { label: t('countries:US'), value: 'US', callingCodes: ['1'] },
    { label: t('countries:UY'), value: 'UY', callingCodes: ['598'] },
    { label: t('countries:UZ'), value: 'UZ', callingCodes: ['998'] },
    { label: t('countries:VU'), value: 'VU', callingCodes: ['678'] },
    {
      label: t('countries:VE'),
      value: 'VE',
      callingCodes: ['58'],
    },
    { label: t('countries:VN'), value: 'VN', callingCodes: ['84'] },
    { label: t('countries:WF'), value: 'WF', callingCodes: ['681'] },
    { label: t('countries:EH'), value: 'EH', callingCodes: ['212'] },
    { label: t('countries:YE'), value: 'YE', callingCodes: ['967'] },
    { label: t('countries:ZM'), value: 'ZM', callingCodes: ['260'] },
    { label: t('countries:ZW'), value: 'ZW', callingCodes: ['263'] },
  ];

  return (
    <div className={styles.container}>
      {step === 0 ? (
        <>
          <LibraryCaption
            smallText
            beginPhrase={t('signUp:titlePage.beginPhrase')}
            underlineWords={t('signUp:titlePage.underline')}
            closingPhrase={t('signUp:titlePage.closingPhrase')}
            vector={vectorUnderline}
          />
          <form onSubmit={handleSubmit(handleNextStep as any)} noValidate>
            <fieldset className={styles.isHalf}>
              <Input
                hasValue={isDirty?.firstName ?? false}
                label={t('signUp:form.labels.firstName')}
                type="text"
                placeholder={t('signUp:form.placeholder.firstName')}
                inputRef={firstNameRef}
                {...firstNameInputProps}
                error={errors?.firstName?.message}
              />

              <Input
                hasValue={isDirty?.lastName ?? false}
                label={t('signUp:form.labels.lastName')}
                type="text"
                placeholder={t('signUp:form.placeholder.lastName')}
                inputRef={lastNameRef}
                {...lastNameInputProps}
                error={errors?.lastName?.message}
              />
            </fieldset>
            <fieldset>
              <Input
                hasValue={isDirty?.email ?? false}
                label={t('signUp:form.labels.emailAddress')}
                type="email"
                placeholder={t('signUp:form.placeholder.emailAddress')}
                inputRef={emailRef}
                {...emailInputProps}
                error={errors?.email?.message}
              />

              <Input
                hasValue={isDirty?.password ?? false}
                label={t('signUp:form.labels.password')}
                type="password"
                placeholder={t('signUp:form.placeholder.password')}
                inputRef={passwordRef}
                {...passwordInputProps}
                error={errors?.password?.message}
              />
            </fieldset>
            <Button
              type={BUTTON_TYPE.PRIMARY}
              disabled={!firstName || !lastName || !email || !password}
              fullWidth
            >
              {t('signUp:form.labels.continue')}
            </Button>
            <p className={styles.goToSignInWrap}>
              {t('signUp:form.alreadyHaveAccount')}{' '}
              <Link to={ROUTES.SIGN_IN}>
                <span className={styles.signInIfAlready}>
                  {t('signUp:form.signIn')}
                </span>
              </Link>
            </p>

            {/* <div
              className={styles.autodonateContainer}
              onClick={goToAutoDonate}
            >
              <div className={styles.icon}>
                <FeatherIcon
                  strokeWidth="1.7px"
                  icon="message-square"
                  color={'var(--blue2)'}
                  size="24"
                />
              </div>
              <div className={styles.text}>
                <h4>{t('signUp:autodonate.title')}</h4>
                <p> {t('signUp:autodonate.description')}</p>
              </div>
              <FeatherIcon
                strokeWidth="2px"
                icon="chevron-right"
                color={'var(--neutral400)'}
                size="40"
              />
            </div> */}
          </form>
        </>
      ) : (
        ''
      )}

      {step === 1 ? (
        <>
          <HeaderOnBoardingWrapper title={t('signUp:titlePage.step1')} />
          <form onSubmit={handleSubmitStep1(handleNextStep2 as any)}>
            <fieldset>
              <Input
                className={`${birthday ? 'notEmpty' : ''} ${
                  isIOs ? 'isIos' : ''
                } ${isAndroid ? 'isAndroid' : ''}`}
                style={{ color: placeholderColor }}
                hasValue={isDirty?.birthday ?? false}
                label={t('signUp:form.labels.birthday')}
                type="date"
                // onFocus={onFocusDate}
                inputRef={birthdayRef}
                placeholder={t('signUp:form.placeholder.date')}
                {...birthdayProps}
                // onBlur={onBlurDate}
                error={errorsSecondForm?.birthday?.message}
              />

              <Controller
                control={control}
                name="gender"
                rules={{
                  required: {
                    value: true,
                    message: t('signUp:form.errorsState.required.generic'),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <label className="controlled-component-label">
                      {t('signUp:form.labels.gender')}
                    </label>
                    <Select
                      options={genderSelect}
                      isClearable={true}
                      noOptionsMessage={() =>
                        t('signUp:form.select.notFound.gender')
                      }
                      value={
                        value ? genderSelect.find((c) => c.value === value) : ''
                      }
                      styles={customStylesGender}
                      onChange={(val: any) => onChange(val?.value)}
                      placeholder={t('signUp:form.placeholder.gender')}
                    />
                  </>
                )}
              />
              {errorsSecondForm.gender && (
                <p
                  className={styles.errorMessage}
                  style={{
                    fontWeight: 400,
                    marginTop: '-8px',
                    marginBottom: '16px',
                  }}
                >
                  {errorsSecondForm.gender.message}
                </p>
              )}

              <Controller
                control={control}
                name="country"
                rules={{
                  required: {
                    value: true,
                    message: t('signUp:form.errorsState.required.generic'),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <label className="controlled-component-label">
                      {t('signUp:form.labels.country')}
                    </label>
                    <Select
                      options={countrySelect}
                      isClearable={true}
                      noOptionsMessage={() =>
                        t('signUp:form.select.notFound.country')
                      }
                      value={
                        value
                          ? countrySelect.find((c) => c.value === value)
                          : ''
                      }
                      styles={customStylesCountry}
                      onChange={(val: any) => onChange(val?.value)}
                      placeholder={t('signUp:form.placeholder.country')}
                    />
                  </>
                )}
              />
              {errorsSecondForm.country && (
                <p
                  className={styles.errorMessage}
                  style={{
                    fontWeight: 400,
                    marginTop: '-8px',
                    marginBottom: '16px',
                  }}
                >
                  {errorsSecondForm.country.message}
                </p>
              )}

              <Controller
                control={control}
                name="language"
                rules={{
                  required: {
                    value: true,
                    message: t('signUp:form.errorsState.required.generic'),
                  },
                }}
                render={({ field: { onChange, value } }: any) => (
                  <>
                    <label className="controlled-component-label">
                      {t('signUp:form.labels.language')}
                    </label>
                    <Select
                      options={languagesSelect}
                      isClearable={true}
                      noOptionsMessage={() =>
                        t('signUp:form.select.notFound.language')
                      }
                      value={
                        value
                          ? languagesSelect.find((c) => c.value === value)
                          : ''
                      }
                      styles={customStylesLanguage}
                      onChange={(val: any) => onChange(val?.value)}
                      placeholder={t('signUp:form.placeholder.language')}
                    />
                  </>
                )}
              />
              {errorsSecondForm.language && (
                <p
                  className={styles.errorMessage}
                  style={{
                    fontWeight: 400,
                    marginTop: '-8px',
                    marginBottom: '16px',
                  }}
                >
                  {errorsSecondForm.language.message}
                </p>
              )}

              <Controller
                control={control}
                name="whyNeedVoice"
                rules={{
                  required: {
                    value: true,
                    message: t('signUp:form.errorsState.required.generic'),
                  },
                }}
                render={({ field: { onChange, value } }: any) => (
                  <>
                    <label className="controlled-component-label">
                      {t('signUp:form.labels.whyNeedVoice')}
                    </label>
                    <Select
                      options={whyNeedVoiceSelect}
                      isClearable={true}
                      noOptionsMessage={() =>
                        t('signUp:form.select.notFound.whyNeedVoice')
                      }
                      value={
                        value
                          ? whyNeedVoiceSelect.find((c) => c.value === value)
                          : ''
                      }
                      styles={customStylesVoice}
                      onChange={(val: any) => onChange(val?.value)}
                      placeholder={t('signUp:form.placeholder.whyNeedVoice')}
                    />
                  </>
                )}
              />
              {errorsSecondForm.whyNeedVoice && (
                <p
                  className={styles.errorMessage}
                  style={{
                    fontWeight: 400,
                    marginTop: '-8px',
                    marginBottom: '16px',
                  }}
                >
                  {errorsSecondForm.whyNeedVoice.message}
                </p>
              )}

              <Controller
                control={control}
                name="aacModel"
                rules={{
                  required: {
                    value: true,
                    message: t('signUp:form.errorsState.required.generic'),
                  },
                }}
                render={({ field: { onChange, value } }: any) => (
                  <>
                    <label className="controlled-component-label">
                      {t('signUp:form.labels.aacModel')}
                    </label>
                    <Select
                      options={aacModelSelect}
                      isClearable={true}
                      noOptionsMessage={() =>
                        t('signUp:form.select.notFound.aacModel')
                      }
                      value={
                        value
                          ? aacModelSelect.find((c) => c.value === value)
                          : ''
                      }
                      styles={customStylesModel}
                      onChange={(val: any) => onChange(val?.value)}
                      placeholder={t('signUp:form.placeholder.aacModel')}
                    />
                  </>
                )}
              />
              {aacModel && aacModel === 'other' && (
                <Input
                  hasValue={isDirty?.otherModel ?? false}
                  // label={t('signUp:form.labels.therapist')}
                  type="text"
                  placeholder={t('signUp:form.placeholder.otherModel')}
                  {...otherModelInputProps}
                  inputRef={otherModelRef}
                  error={errorsSecondForm?.otherModel?.message}
                />
              )}
              {errorsSecondForm.aacModel && (
                <p
                  className={styles.errorMessage}
                  style={{
                    fontWeight: 400,
                    marginTop: '-8px',
                    marginBottom: '16px',
                  }}
                >
                  {errorsSecondForm.aacModel.message}
                </p>
              )}

              <Input
                hasValue={isDirty?.therapist ?? false}
                label={t('signUp:form.labels.therapist')}
                type="text"
                placeholder={t('signUp:form.placeholder.therapist')}
                inputRef={therapistRef}
                {...therapistInputProps}
                error={errorsSecondForm?.therapist?.message}
              />

              <Controller
                control={control}
                name="phone"
                rules={{
                  required: true,
                  validate: (value) => value && isValidPhoneNumber(value),
                }}
                render={({ field: { onChange, value } }: any) => (
                  <>
                    <label className="controlled-component-label">
                      {t('signUp:form.labels.phone')}
                    </label>
                    <PhoneInput
                      defaultCountry={currentCountry}
                      className={errorsSecondForm?.phone ? 'error' : ''}
                      placeholder={t('signUp:form.placeholder.phone')}
                      value={value}
                      onChange={onChange}
                    />
                  </>
                )}
              />

              {errorsSecondForm?.phone?.type === 'validate' ? (
                <p
                  className={styles.errorMessage}
                  style={{
                    fontWeight: 400,
                    marginBottom: '16px',
                  }}
                >
                  {t('signUp:form.errorsState.invalid.phone')}
                </p>
              ) : (
                <p
                  className={styles.errorMessage}
                  style={{
                    fontWeight: 400,
                    marginBottom: '16px',
                  }}
                >
                  {errorsSecondForm?.phone?.message}
                </p>
              )}
              {/* </fieldset>

            <div className={styles.agreeFooter}>
              <div className={styles.agreeFooterWrap}> */}
              <div className={styles.agreement}>
                <InputCheckBox
                  mode={CHECKBOX_MODE.DEFAULT}
                  hasValue={isDirty?.agreeTerms ?? false}
                  // label={t('signUp:form.labels.agreeTerms')}
                  label={
                    <p style={{ fontSize: '14px' }}>
                      {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Trans
                          defaults={t('signUp:form.labels.agreeTerms')}
                          components={{
                            VFPPrivacyPolicy: (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={createDownloadLinkPDF(
                                  'Vfp',
                                  language,
                                  'privacyPolicy'
                                )}
                              ></a>
                            ),
                            MatedubTermsOfUse: (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={createDownloadLinkPDF(
                                  'Matedub',
                                  language,
                                  'termsOfUse'
                                )}
                              ></a>
                            ),
                          }}
                        />
                      }
                    </p>
                  }
                  type="checkbox"
                  inputRef={agreeTermsRef}
                  {...agreeTermsProps}
                  error={errorsSecondForm?.agreeTerms?.message}
                />
                <InputCheckBox
                  mode={CHECKBOX_MODE.DEFAULT}
                  hasValue={isDirty?.agreeTerms2 ?? false}
                  label={
                    <p style={{ fontSize: '14px' }}>
                      {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Trans
                          defaults={t('signUp:form.labels.agreeTerms2')}
                          components={{
                            MatedubTermsOfUse: (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={createDownloadLinkPDF(
                                  'Matedub',
                                  language,
                                  'termsOfUse',
                                  8
                                )}
                              ></a>
                            ),
                          }}
                        />
                      }
                    </p>
                  }
                  type="checkbox"
                  inputRef={agreeTerms2Ref}
                  {...agreeTerms2Props}
                  error={errorsSecondForm?.agreeTerms2?.message}
                />
                <InputCheckBox
                  mode={CHECKBOX_MODE.DEFAULT}
                  hasValue={isDirty?.agreeTerms3 ?? false}
                  label={t('signUp:form.labels.agreeTerms3')}
                  type="checkbox"
                  inputRef={agreeTerms3Ref}
                  {...agreeTerms3Props}
                  error={errorsSecondForm?.agreeTerms3?.message}
                />
              </div>
              <Button
                type={BUTTON_TYPE.PRIMARY}
                disabled={
                  !birthday ||
                  !gender ||
                  !language ||
                  !country ||
                  !agree ||
                  !secondAgree ||
                  !thirdAgree ||
                  !therapist ||
                  !phone ||
                  !whyNeedVoice ||
                  (!aacModel && aacModel !== 'other') ||
                  (!otherModel && aacModel === 'other')
                }
                fullWidth
              >
                <div className={!isLoading ? styles.label : styles.hide}>
                  {t('signUp:form.labels.createAccount')}
                </div>
                {isLoading && (
                  <div className={styles.spinnerContainer}>
                    <Spinner isWhite isMid />
                  </div>
                )}
              </Button>
            </fieldset>
          </form>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
