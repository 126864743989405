import Loader from '@components/Loader';

import UserCardPreview from '@components/UserCardPreview';
import { UserCardContext } from '@contexts/UserCardContext';
import { getCssVariable } from '@utils/index';
import FeatherIcon from 'feather-icons-react';
import Modal from 'react-modal';
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';

interface IProps {
  user: any;
  db: any;
}
export default function RequestedVoices({ user, db }: IProps) {
  const { t } = useTranslation('dashboard');
  const { requestedVoices, setRequestedVoices } =
    React.useContext(UserCardContext);
  // const [showPrompt, setShowPrompt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  // const [cardToRemove, setCardToRemove] = React.useState('');
  const [userRequestedData, setUserRequestedData] = React.useState<any>([]);
  const navigate = useNavigate();
  const getUserData = React.useCallback(async () => {
    if (user) {
      setIsLoading(true);
      const userPrivateRef = doc(db, `users/${user.uid}/private`, 'library');
      const docPrivateSnap = await getDoc(userPrivateRef);
      if (docPrivateSnap.exists()) {
        const userPrivateData = docPrivateSnap.data();
        setRequestedVoices(userPrivateData?.requestedVoices);
        setIsLoading(false);
      } else {
        setRequestedVoices([]);
      }
    } else {
      setIsLoading(false);
    }
  }, [user, db, setRequestedVoices]);

  React.useEffect(() => {
    async function getRequested() {
      if (requestedVoices.length > 0) {
        const userFavData = await query(
          collection(db, 'users'),
          where('id', 'in', [...requestedVoices])
        );
        const docLibrarySnapForSize = await getDocs(userFavData);
        if (docLibrarySnapForSize.size > 0) {
          const data = docLibrarySnapForSize?.docs?.map((doc: any) =>
            doc.data()
          );
          setUserRequestedData(data);
          setIsLoading(false);
        }
      } else {
        setUserRequestedData([]);
        setIsLoading(false);
      }
    }
    getRequested();
  }, [db, requestedVoices, setUserRequestedData]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  function goToLibrary() {
    navigate(ROUTES.BASE);
  }

  // function closeModal() {
  //   setShowPrompt(false);
  // }

  // function openModal() {
  //   setShowPrompt(true);
  // }

  Modal.setAppElement('div');

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      {userRequestedData.length > 0 ? (
        <>
          <div className={styles.cardContainer}>
            {/* <Modal
              isOpen={showPrompt}
              onRequestClose={closeModal}
              overlayClassName={styles.overlay}
              className={styles.modal}
            >
              <DeleteRequestedVoicePrompt
                userToRemove={cardToRemove}
                db={db}
                closeModal={closeModal}
              />
            </Modal> */}
            {userRequestedData.map((userCardInfo: any) => (
              <UserCardPreview
                firstname={userCardInfo?.firstname}
                describeYourself={userCardInfo?.describeYourself}
                voiceType={userCardInfo?.voiceType}
                // openModal={openModal}
                key={userCardInfo?.id}
                language={userCardInfo?.language}
                urlRec={userCardInfo?.URL_recording}
                profilePic={userCardInfo?.profile_photo}
                userId={userCardInfo?.id}
                // setCardToRemove={setCardToRemove}
                avatarPhoto={userCardInfo?.avatar_photo}
                isModelReady={userCardInfo?.isModelReady}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles.noFavoriteContainer}>
          <div className={styles.step}>
            <div className={`${styles.icon} ${styles.icon1}`}>
              <FeatherIcon
                strokeWidth="2px"
                color={getCssVariable('orange')}
                icon="message-square"
                size="20"
              />
            </div>
            <div className={styles.content}>
              <p className="sub-header">
                {t('requestedVoices.noRequestedVoices')}
              </p>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                onClick={goToLibrary}
                type={BUTTON_TYPE.PRIMARY}
                mode={BUTTON_MODE.OUTLINE}
              >
                {t('requestedVoices.labelRequest')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
