import React from 'react';

const ProfileImageContext = React.createContext<any>(null);

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}
const ProfileImageProvider = ({ children }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageURLProfile, setImageURLProfile] = React.useState(null);
  return (
    <ProfileImageContext.Provider
      value={{ imageURLProfile, setImageURLProfile } as any}
    >
      {children}
    </ProfileImageContext.Provider>
  );
};

export { ProfileImageProvider, ProfileImageContext };
