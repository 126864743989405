/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Avatar, { AVATAR_SIZE } from '@components/Avatar';
import { useFirestore, useUser } from 'reactfire';
import FeatherIcon from 'feather-icons-react';
import { arrayRemove, arrayUnion, doc, setDoc } from 'firebase/firestore';
import Player from '@components/Player';
import { getCssVariable } from '@utils/index';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import { UserCardContext } from '@contexts/UserCardContext';
import { noop } from 'lodash';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

export type UserCardProps = {
  voiceType: string;
  describeYourself?: string;
  firstname: string;
  userId?: string;
  language: string;
  userType?: string;
  isLogged?: any;
  urlRec?: string;
  profilePic?: string;
  isHidden?: boolean;
  avatarPhoto: string;
  isModelReady: boolean;
};

export default function UserCard({
  firstname,
  userType,
  describeYourself,
  voiceType,
  profilePic,
  isLogged,
  userId,
  isHidden,
  language,
  avatarPhoto,
  urlRec,
  isModelReady,
}: UserCardProps) {
  const { t } = useTranslation('library');
  const { data: user } = useUser();
  const db = useFirestore();
  const {
    usersFavorites,
    setUsersFavorites,
    currentRequestedVoice,
    setCurrentRequestedVoice,
    requestedVoices,
  } = React.useContext(UserCardContext);
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentSong, setCurrentSong] = React.useState<any>({ url: urlRec });

  function renderLanguage(lang: string) {
    switch (lang) {
      case 'en-EN':
        return t('userCard.language.en');
      case 'it-IT':
        return t('userCard.language.it');
      case 'es-ES':
        return t('userCard.language.es');
      case 'de-DE':
        return t('userCard.language.de');
      case 'fr-FR':
        return t('userCard.language.fr');
      default:
        return '';
    }
  }

  const addToFav = React.useCallback(
    async (userId: string) => {
      if (user) {
        await setDoc(
          doc(db, `users/${user.uid}/private`, 'library'),
          {
            favorites: arrayUnion(userId),
          },
          { merge: true }
        );
      }
    },
    [db, user]
  );

  const removeFromFav = React.useCallback(
    async (userId: string) => {
      if (user) {
        await setDoc(
          doc(db, `users/${user.uid}/private`, 'library'),
          {
            favorites: arrayRemove(userId),
          },
          { merge: true }
        );
      }
    },
    [db, user]
  );

  function goToDetailsCard() {
    if (user) {
      navigate(`/users/${userId}`);
    } else {
      navigate(ROUTES.CHOOSE_PROFILE);
    }
  }

  function getThisVoice(e: any) {
    e.stopPropagation();
    if (user) {
      navigate(`/users/${userId}`, {
        state: { step: requestedVoices.length < 3 ? 1 : -1 },
      });
    } else {
      navigate(ROUTES.CHOOSE_PROFILE);
    }
  }

  function toggleFav(e: any) {
    e.stopPropagation();
    if (usersFavorites && !usersFavorites?.includes(userId) && userId) {
      addToFav(userId);
      setUsersFavorites([...usersFavorites, userId]);
    } else if (usersFavorites && usersFavorites?.includes(userId) && userId) {
      removeFromFav(userId);
      const favorites = usersFavorites.filter((el: string) => el !== userId);
      setUsersFavorites(favorites);
    }
  }

  const audioElem = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (isPlaying) {
      audioElem?.current?.play();
    } else {
      audioElem?.current?.pause();
    }
  }, [isPlaying]);

  const onPlaying = () => {
    if (audioElem && audioElem?.current && audioElem?.current?.duration) {
      const duration = audioElem?.current?.duration as any;
      const ct = audioElem?.current?.currentTime as any;

      setCurrentSong({
        ...currentSong,
        progress: (ct / duration) * 100,
        length: duration,
      });
    }
  };
  function goToSignUp() {
    navigate(ROUTES.CHOOSE_PROFILE);
  }

  function noProp(e: any) {
    e.stopPropagation();
  }

  function translateVoiceTypeLabel(voice: string) {
    switch (voice) {
      case 'middle':
        return t('userCard.voiceType.middle');
      case 'young':
        return t('userCard.voiceType.young');
      case 'senior':
        return t('userCard.voiceType.senior');
      default:
        return voice;
    }
  }

  return (
    <div
      className={styles.container}
      style={{ pointerEvents: !urlRec ? 'none' : 'auto' }}
      onClick={goToDetailsCard}
    >
      <div className={styles.dataContainer}>
        <div className={styles.userDataWrapper}>
          <div className={styles.avatarContainer}>
            <Avatar
              name={`${firstname}`}
              image={profilePic ? profilePic : avatarPhoto}
              size={AVATAR_SIZE.MEDIUM}
            />
            <Tooltip
              placement="top"
              trigger={'hover'}
              overlay={
                isModelReady === true
                  ? t('userCard.modelStatus.available')
                  : t('userCard.modelStatus.not_available')
              }
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <span
                className={`${styles.availableContainer} ${
                  isModelReady ? styles.available : styles.not_available
                }`}
              ></span>
            </Tooltip>
          </div>
          <div className={styles.userData}>
            {firstname && <h4>{firstname}</h4>}
            <div className={styles.userInfo}>
              <FeatherIcon
                strokeWidth={'2px'}
                aria-hidden="true"
                icon="message-circle"
                size="13"
              />
              <span className={`small ${styles.voiceType}`}>
                {translateVoiceTypeLabel(voiceType)}
              </span>
              <span className={`small ${styles.separator}`}>·</span>

              <FeatherIcon
                strokeWidth={'2px'}
                aria-hidden="true"
                icon="globe"
                size="13"
              />
              <span className="small">{renderLanguage(language)}</span>
            </div>
          </div>
          {user && (
            <div className={styles.like}>
              <FeatherIcon
                strokeWidth={
                  usersFavorites && usersFavorites?.includes(userId)
                    ? '0'
                    : '2px'
                }
                aria-hidden="true"
                color={getCssVariable('neutral400')}
                fill={
                  usersFavorites && usersFavorites?.includes(userId)
                    ? 'var(--orange)'
                    : 'transparent'
                }
                onClick={urlRec ? toggleFav : noop}
                icon="heart"
                size="22"
              />
            </div>
          )}
        </div>
        <div>
          {describeYourself && <p className="small">{describeYourself}</p>}
        </div>
      </div>
      <div className={styles.footerCard}>
        <audio
          onTimeUpdate={onPlaying}
          ref={audioElem}
          src={!isHidden ? urlRec : ''}
        />
        <Player
          currentSong={currentSong}
          audioElem={audioElem}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
        <Button
          style={{ maxHeight: '48px' }}
          // disabled={
          //   currentRequestedVoice !== '' && currentRequestedVoice !== userId
          // }
          onClick={
            !isLogged
              ? goToSignUp
              : requestedVoices.includes(userId) &&
                // : currentRequestedVoice !== '' &&
                //   currentRequestedVoice === userId &&
                isLogged
              ? noProp
              : !urlRec
              ? noop
              : getThisVoice
          }
          type={
            requestedVoices.includes(userId) &&
            // currentRequestedVoice !== '' &&
            // currentRequestedVoice === userId &&
            isLogged
              ? BUTTON_TYPE.REQUESTED
              : BUTTON_TYPE.PRIMARY
          }
          fullWidth
          mode={BUTTON_MODE.OUTLINE}
          size={
            requestedVoices.includes(userId) &&
            // currentRequestedVoice !== '' &&
            // currentRequestedVoice === userId &&
            isLogged
              ? BUTTON_SIZE.WITH_ICON
              : BUTTON_SIZE.TEXT
          }
          leadingIcon={
            requestedVoices.includes(userId) &&
            // currentRequestedVoice !== '' &&
            // currentRequestedVoice === userId &&
            isLogged && (
              <FeatherIcon
                strokeWidth="2px"
                aria-hidden="true"
                color={getCssVariable('white')}
                icon="check"
                size="20"
              />
            )
          }
        >
          {requestedVoices.includes(userId) &&
          // currentRequestedVoice !== '' &&
          // currentRequestedVoice === userId &&
          isLogged
            ? t('userCard.requested')
            : t('userCard.label')}
        </Button>
      </div>
    </div>
  );
}
