import styles from './styles.module.scss';

type Props = {
  beginPhrase: string;
  closingPhrase: string;
  underlineWords?: string;
  description?: string;
  leadingIcon?: React.ReactNode;
  vector?: any;
  smallText?: boolean;
};

export default function LibraryCaption({
  underlineWords = '',
  beginPhrase = '',
  closingPhrase = '',
  description = '',
  leadingIcon,
  vector,
  smallText,
}: Props) {
  return (
    <div
      className={
        smallText ? `${styles.container} ${styles.smallText}` : styles.container
      }
    >
      {leadingIcon}
      {smallText ? (
        <h3>
          {beginPhrase}
          {underlineWords && (
            <span>
              {underlineWords}{' '}
              {vector && <img className={styles.vector} src={vector} />}
            </span>
          )}

          {closingPhrase}
        </h3>
      ) : (
        <h2>
          {beginPhrase}
          {underlineWords && (
            <span>
              {underlineWords}{' '}
              {vector && <img className={styles.vector} src={vector} />}
            </span>
          )}

          {closingPhrase}
        </h2>
      )}
      {description && <p>{description}</p>}
    </div>
  );
}
