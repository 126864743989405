/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser, useFirestore } from 'reactfire';
import FeatherIcon from 'feather-icons-react';
import styles from './styles.module.scss';
import UserCard from '@components/UserCard';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import uuid4 from 'uuid4';
import Spinner from '@components/Spinner';
import { UserCardContext } from '@contexts/UserCardContext';
import { createSearchParams, Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-contexify/dist/ReactContexify.css';
import Filters from '@components/Filters';
import qs from 'query-string';
import { ROUTES } from '@routes/routes';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import { getCssVariable } from '@utils/index';
import BorderlessSelect from '@components/BorderlessSelect';

export interface Option {
  value: string;
  label: string;
}

export default function LibraryLoggedContent() {
  const { t } = useTranslation('library');
  const [totalUserCard, setTotalUserCard] = React.useState<number>(0);
  const [currentUserData, setCurrentUserData] = React.useState<any>();
  const [usersData, setUsersData] = React.useState<any>([]);
  const [lastVisibleDoc, setLastVisibleDoc] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);
  const db = useFirestore();
  const { data: user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const { setUsersFavorites, setRequestedVoices, setCurrentRequestedVoice } =
    React.useContext(UserCardContext);

  const filtersFromUrl = qs.parse(window.location.search);

  const [queryParams, setQueryParams] = React.useState({
    ...filtersFromUrl,
  });

  const limitFilter = 12;

  const currentActiveFacetValue = Object.keys(queryParams).toString();
  const currentActiveFacetValueSplitted =
    currentActiveFacetValue?.split(',') || [];

  const italianAccent = [
    {
      label: 'Milano',
      value: 'milan',
      type: 'radio',
    },
    {
      label: 'Veneto',
      value: 'veneto',
      type: 'radio',
    },
    {
      label: 'Bari',
      value: 'bari',
      type: 'radio',
    },
  ];

  const spanishAccent = [
    {
      label: 'Catalano',
      value: 'catalano',
      type: 'radio',
    },
  ];

  const filtersFacet = [
    {
      id: 'language',
      type: 'radio',
      label: t('filters.labels.languages.lang'),
      icon: 'globe',
      value: [
        {
          label: t('filters.labels.languages.english'),
          value: 'en-EN',
          type: 'radio',
        },
        {
          label: t('filters.labels.languages.italian'),
          value: 'it-IT',
          type: 'radio',
        },
        {
          label: t('filters.labels.languages.german'),
          value: 'de-DE',
          type: 'radio',
        },
        {
          label: t('filters.labels.languages.french'),
          value: 'fr-FR',
          type: 'radio',
        },
        {
          label: t('filters.labels.languages.spanish'),
          value: 'es-ES',
          type: 'radio',
        },
      ],
      isActive: currentActiveFacetValueSplitted.includes('language'),
    },
    // {
    //   id: 'accent',
    //   type: 'radio',
    //   label: 'Accento',
    //   icon: 'radio',
    //   value:
    //     queryParams?.language === 'it-IT'
    //       ? italianAccent
    //       : queryParams?.language === 'es-ES'
    //       ? spanishAccent
    //       : [],
    //   isActive: currentActiveFacetValueSplitted.includes('accent'),
    // },
    {
      id: 'gender',
      type: 'radio',
      label: t('filters.labels.gender'),
      icon: 'user',
      value: [
        {
          label: t('filters.labels.male'),
          value: 'male',
          type: 'radio',
        },
        {
          label: t('filters.labels.female'),
          value: 'female',
          type: 'radio',
        },
        {
          label: t('filters.labels.notBinary'),
          value: 'notBinary',
          type: 'radio',
        },
      ],
      isActive: currentActiveFacetValueSplitted.includes('gender'),
    },

    {
      id: 'age',
      label: t('filters.labels.age.voiceType'),
      icon: 'message-circle',
      value: [
        {
          label: t('filters.labels.age.young'),
          type: 'checkbox',
          value: 'young',
        },
        {
          label: t('filters.labels.age.middle'),
          type: 'checkbox',
          value: 'middle',
        },
        {
          label: t('filters.labels.age.senior'),
          type: 'checkbox',
          value: 'senior',
        },
      ],
      isActive: currentActiveFacetValueSplitted.includes('age'),
      type: 'range',
    },
  ];

  const getUserData = React.useCallback(
    async (queryParams: any) => {
      if (user) {
        setIsLoading(true);
        const userRef = doc(db, 'users', user?.uid);
        const docUserDataSnap = await getDoc(userRef);
        if (docUserDataSnap.exists()) {
          const userData = docUserDataSnap.data();
          setCurrentUserData(userData);
          if (userData.userType === 'donor') {
            navigate(ROUTES.SIGN_UP);
          }
        } else {
          console.log('No library document!');
        }

        let q;
        let librarySnapForSize;

        // !AGE
        // GENDER
        // LANGUAGE

        // if (queryParams?.gender && queryParams?.language && !queryParams?.age) {
        //   setUsersData([]);
        //   librarySnapForSize = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('language', '==', queryParams.language),
        //     where('gender', '==', queryParams.gender)
        //   );

        //   q = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('language', '==', queryParams.language),
        //     where('gender', '==', queryParams.gender),
        //     limit(limitFilter)
        //   );
        // }

        // AGE
        // GENDER
        // !LANGUAGE

        // if (queryParams?.gender && queryParams?.age && !queryParams?.language) {
        //   setUsersData([]);
        //   librarySnapForSize = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('gender', '==', queryParams.gender),
        //     where('voiceType', 'in', [...queryParams.age.split(',')])
        //   );

        //   q = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('gender', '==', queryParams.gender),
        //     where('voiceType', 'in', [...queryParams.age.split(',')]),
        //     limit(limitFilter)
        //   );
        // }

        // AGE
        // !GENDER
        // LANGUAGE

        // if (queryParams?.age && queryParams?.language && !queryParams?.gender) {
        //   setUsersData([]);
        //   librarySnapForSize = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('language', '==', queryParams.language),
        //     where('voiceType', 'in', [...queryParams.age.split(',')])
        //   );

        //   q = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('language', '==', queryParams.language),
        //     where('voiceType', 'in', [...queryParams.age.split(',')]),
        //     limit(limitFilter)
        //   );
        // }

        // !AGE
        // !GENDER
        // LANGUAGE

        // if (
        //   queryParams?.language &&
        //   !queryParams?.gender &&
        //   !queryParams?.age
        // ) {
        //   setUsersData([]);

        //   librarySnapForSize = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('language', '==', queryParams.language)
        //   );
        //   q = query(
        //     collection(db, 'users'),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     // where('URL_recording', '!=', null),
        //     where('language', '==', queryParams.language),
        //     limit(limitFilter)
        //   );
        // }

        // !AGE
        // GENDER
        // !LANGUAGE

        // if (
        //   queryParams?.gender &&
        //   !queryParams?.language &&
        //   !queryParams?.age
        // ) {
        //   setUsersData([]);
        //   librarySnapForSize = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('gender', '==', queryParams.gender)
        //   );

        //   q = query(
        //     collection(db, 'users'),
        //     // where('URL_recording', '!=', null),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('gender', '==', queryParams.gender),
        //     limit(limitFilter)
        //   );
        // }

        // AGE
        // !GENDER
        // !LANGUAGE

        // if (
        //   queryParams?.age &&
        //   !queryParams?.language &&
        //   !queryParams?.gender
        // ) {
        //   setUsersData([]);

        //   librarySnapForSize = query(
        //     collection(db, 'users'),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('voiceType', 'in', [...queryParams.age.split(',')])
        //   );

        //   q = query(
        //     collection(db, 'users'),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('voiceType', 'in', [...queryParams.age.split(',')]),
        //     limit(limitFilter)
        //   );
        // }

        // AGE
        // GENDER
        // LANGUAGE

        // if (queryParams?.language && queryParams?.gender && queryParams?.age) {
        //   setUsersData([]);
        //   librarySnapForSize = query(
        //     collection(db, 'users'),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('language', '==', queryParams.language),
        //     where('gender', '==', queryParams.gender),
        //     where('voiceType', 'in', [...queryParams.age.split(',')])
        //   );

        //   q = query(
        //     collection(db, 'users'),
        //     orderBy('isHiddenFromLibrary'),
        //     where('isHiddenFromLibrary', '!=', true),
        //     orderBy('URL_recording'),
        //     where('language', '==', queryParams.language),
        //     where('gender', '==', queryParams.gender),
        //     where('voiceType', 'in', [...queryParams.age.split(',')]),
        //     limit(limitFilter)
        //   );
        // }

        // !AGE
        // !GENDER
        // !LANGUAGE

        if (Object.keys(queryParams).length === 0) {
          librarySnapForSize = query(
            collection(db, 'users'),
            orderBy('isHiddenFromLibrary'),
            where('isHiddenFromLibrary', '!=', true),
            orderBy('URL_recording')
          );

          q = query(
            collection(db, 'users'),
            orderBy('isHiddenFromLibrary'),
            where('isHiddenFromLibrary', '!=', true),
            orderBy('URL_recording'),
            limit(limitFilter)
          );
          setQueryParams({ ...queryParams, sortBy: 'none' });
        }

        // !AGE
        // !GENDER
        // !LANGUAGE
        // !ACCENT
        // SORT BY

        if (
          !queryParams?.language &&
          !queryParams?.gender &&
          !queryParams?.age &&
          !queryParams?.accent &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording')
              // where('URL_recording', '!=', null)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording')
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording')
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // ------------------------------------------------------------------------
        // !AGE
        // !GENDER
        // !LANGUAGE
        // SORT BY
        // ACCENT

        // if (
        //   !queryParams?.language &&
        //   !queryParams?.gender &&
        //   !queryParams?.age &&
        //   queryParams?.accent &&
        //   queryParams?.sortBy
        // ) {
        //   if (queryParams?.sortBy === '-inserted') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent)
        //       // where('URL_recording', '!=', null)
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('inserted', 'desc'),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       limit(limitFilter)
        //     );
        //   } else if (queryParams?.sortBy === 'inserted') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent)
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('inserted', 'asc'),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       limit(limitFilter)
        //     );
        //   } else if (queryParams?.sortBy === 'none') {
        //     console.log('here!');
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent)
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('describeYourself', 'desc'),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       limit(limitFilter)
        //     );
        //   }
        // }
        // ------------------------------------------------------------------------

        // !ACCENT
        // !AGE
        // !LANGUAGE
        // GENDER
        // SORT BY

        if (
          !queryParams?.language &&
          queryParams?.gender &&
          !queryParams?.accent &&
          !queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              // where('URL_recording', '!=', null),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // !GENDER
        // !LANGUAGE
        // !ACCENT
        // AGE
        // SORT BY

        if (
          !queryParams?.language &&
          !queryParams?.gender &&
          queryParams?.age &&
          !queryParams?.accent &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // !AGE
        // !ACCENT
        // !GENDER
        // LANGUAGE
        // SORT BY

        if (
          queryParams?.language &&
          !queryParams?.gender &&
          !queryParams?.accent &&
          !queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('language', '==', queryParams?.language)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('language', '==', queryParams?.language)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('language', '==', queryParams?.language)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // !ACCENT
        // !AGE
        // GENDER
        // LANGUAGE
        // SORT BY

        if (
          queryParams?.language &&
          queryParams?.gender &&
          !queryParams?.accent &&
          !queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('language', '==', queryParams?.language),
              orderBy('URL_recording')
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('language', '==', queryParams?.language),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('language', '==', queryParams?.language),
              orderBy('URL_recording')
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('language', '==', queryParams?.language),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('gender', '==', queryParams.gender),
              where('language', '==', queryParams?.language)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('language', '==', queryParams?.language),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // !ACCENT
        // !GENDER
        // AGE
        // LANGUAGE
        // SORT BY

        if (
          queryParams?.language &&
          !queryParams?.gender &&
          !queryParams?.accent &&
          queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // !GENDER
        // !AGE
        // ACCENT
        // LANGUAGE
        // SORT BY

        if (
          queryParams?.language &&
          !queryParams?.gender &&
          // queryParams?.accent &&
          !queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('language', '==', queryParams?.language),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              limit(limitFilter)
            );
          }
        }

        // !ACCENT
        // !LANGUAGE
        // AGE
        // GENDER
        // SORT BY

        if (
          !queryParams?.language &&
          queryParams?.gender &&
          !queryParams?.accent &&
          queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('gender', '==', queryParams.gender),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('gender', '==', queryParams.gender),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              where('gender', '==', queryParams.gender),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              where('gender', '==', queryParams.gender),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // ------------------------------------------------------------------------
        // !LANGUAGE
        // !GENDER
        // AGE
        // ACCENT
        // SORT BY

        // if (
        //   !queryParams?.language &&
        //   !queryParams?.gender &&
        //   queryParams?.accent &&
        //   queryParams?.age &&
        //   queryParams?.sortBy
        // ) {
        //   if (queryParams?.sortBy === '-inserted') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams.accent),
        //       where('voiceType', 'in', [...queryParams.age.split(',')])
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       where('accent', '==', queryParams.accent),
        //       where('voiceType', 'in', [...queryParams.age.split(',')]),
        //       orderBy('inserted', 'desc'),
        //       orderBy('URL_recording'),
        //       limit(limitFilter)
        //     );
        //   } else if (queryParams?.sortBy === 'inserted') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams.accent),
        //       where('voiceType', 'in', [...queryParams.age.split(',')])
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       where('accent', '==', queryParams.accent),
        //       where('voiceType', 'in', [...queryParams.age.split(',')]),
        //       orderBy('inserted', 'asc'),
        //       orderBy('URL_recording'),
        //       limit(limitFilter)
        //     );
        //   } else if (queryParams?.sortBy === 'none') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams.accent),
        //       where('voiceType', 'in', [...queryParams.age.split(',')])
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       where('accent', '==', queryParams.accent),
        //       where('voiceType', 'in', [...queryParams.age.split(',')]),
        //       orderBy('describeYourself', 'desc'),
        //       orderBy('URL_recording'),
        //       limit(limitFilter)
        //     );
        //   }
        // }
        // ------------------------------------------------------------------------

        // ------------------------------------------------------------------------
        // !LANGUAGE
        // !AGE
        // ACCENT
        // GENDER
        // SORT BY

        // if (
        //   !queryParams?.language &&
        //   queryParams?.gender &&
        //   queryParams?.accent &&
        //   !queryParams?.age &&
        //   queryParams?.sortBy
        // ) {
        //   if (queryParams?.sortBy === '-inserted') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       where('gender', '==', queryParams.gender)
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       where('gender', '==', queryParams.gender),
        //       where('voiceType', 'in', [...queryParams.age.split(',')]),
        //       orderBy('inserted', 'desc'),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       limit(limitFilter)
        //     );
        //   } else if (queryParams?.sortBy === 'inserted') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       where('gender', '==', queryParams.gender),
        //       where('voiceType', 'in', [...queryParams.age.split(',')])
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       where('gender', '==', queryParams.gender),
        //       where('voiceType', 'in', [...queryParams.age.split(',')]),
        //       orderBy('inserted', 'asc'),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       limit(limitFilter)
        //     );
        //   } else if (queryParams?.sortBy === 'none') {
        //     setUsersData([]);
        //     librarySnapForSize = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       where('gender', '==', queryParams.gender),
        //       where('voiceType', 'in', [...queryParams.age.split(',')])
        //     );
        //     q = query(
        //       collection(db, 'users'),
        //       orderBy('isHiddenFromLibrary'),
        //       where('isHiddenFromLibrary', '!=', true),
        //       where('gender', '==', queryParams.gender),
        //       where('voiceType', 'in', [...queryParams.age.split(',')]),
        //       orderBy('describeYourself', 'desc'),
        //       orderBy('URL_recording'),
        //       where('accent', '==', queryParams?.accent),
        //       limit(limitFilter)
        //     );
        //   }
        // }
        // ------------------------------------------------------------------------

        // !AGE
        // GENDER
        // LANGUAGE
        // ACCENT
        // SORT BY

        if (
          queryParams?.language &&
          queryParams?.gender &&
          // queryParams?.accent &&
          !queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('gender', '==', queryParams.gender),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('gender', '==', queryParams.gender),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('gender', '==', queryParams.gender),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // !GENDER
        // AGE
        // LANGUAGE
        // ACCENT
        // SORT BY

        if (
          queryParams?.language &&
          !queryParams?.gender &&
          // queryParams?.accent &&
          queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')])
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        // AGE
        // GENDER
        // LANGUAGE
        // ACCENT
        // SORT BY

        if (
          queryParams?.language &&
          queryParams?.gender &&
          // queryParams?.accent &&
          queryParams?.age &&
          queryParams?.sortBy
        ) {
          if (queryParams?.sortBy === '-inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              where('gender', '==', queryParams.gender),
              orderBy('inserted', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'inserted') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              where('gender', '==', queryParams.gender),
              orderBy('inserted', 'asc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          } else if (queryParams?.sortBy === 'none') {
            setUsersData([]);
            librarySnapForSize = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              where('gender', '==', queryParams.gender)
            );
            q = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              // where('accent', '==', queryParams?.accent),
              where('language', '==', queryParams?.language),
              where('voiceType', 'in', [...queryParams.age.split(',')]),
              where('gender', '==', queryParams.gender),
              orderBy('describeYourself', 'desc'),
              orderBy('URL_recording'),
              limit(limitFilter)
            );
          }
        }

        const docLibrarySnapForSize = await getDocs(librarySnapForSize as any);
        setTotalUserCard(docLibrarySnapForSize.size);

        const snapshot = await getDocs(q as any);

        const isCollectionEmpty = snapshot.size === 0;
        if (!isCollectionEmpty) {
          const userData = snapshot.docs.map((userData) => userData.data());

          setUsersData(userData);

          setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
          setIsLoading(false);
        } else {
          console.log('No library document!');
          setIsLoading(false);
        }

        const userPrivateRef = doc(db, `users/${user.uid}/private`, 'library');
        const docPrivateSnap = await getDoc(userPrivateRef);
        if (docPrivateSnap.exists()) {
          const userPrivateData = docPrivateSnap.data();
          setUsersFavorites(userPrivateData?.favorites);
          setCurrentRequestedVoice(userPrivateData?.currentRequestedVoice);
          setRequestedVoices(userPrivateData?.requestedVoices);
        }
      }
    },
    [
      user,
      db,
      navigate,
      setUsersFavorites,
      setCurrentRequestedVoice,
      setRequestedVoices,
    ]
  );

  React.useEffect(() => {
    if (
      !window.location.pathname.includes('users')
      //&&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      //location?.state?.referrer !== 'modal'
    ) {
      getUserData(queryParams);
    }
  }, [getUserData, queryParams, location.state]);

  const fetchMore = React.useCallback(
    async (queryParams: any) => {
      if (usersData.length === 0) {
        console.log('no more doc');
      } else {
        const fetchNextData = async () => {
          setIsLoading(true);

          let next;

          if (Object.keys(queryParams).length === 0) {
            next = query(
              collection(db, 'users'),
              orderBy('isHiddenFromLibrary'),
              where('isHiddenFromLibrary', '!=', true),
              orderBy('URL_recording'),
              startAfter(lastVisibleDoc),
              limit(limitFilter)
            );
          }

          // LANGUAGE
          // GENDER
          // !AGE

          // if (
          //   queryParams?.language &&
          //   queryParams?.gender &&
          //   !queryParams.age
          // ) {
          //   next = query(
          //     collection(db, 'users'),
          //     orderBy('isHiddenFromLibrary'),
          //     where('isHiddenFromLibrary', '!=', true),
          //     orderBy('URL_recording'),
          //     where('language', '==', queryParams.language),
          //     where('gender', '==', queryParams.gender),
          //     startAfter(lastVisibleDoc),
          //     limit(limitFilter)
          //   );
          // }

          // LANGUAGE
          // !GENDER
          // AGE

          // if (
          //   queryParams?.language &&
          //   queryParams?.age &&
          //   !queryParams.gender
          // ) {
          //   next = query(
          //     collection(db, 'users'),
          //     orderBy('isHiddenFromLibrary'),
          //     where('isHiddenFromLibrary', '!=', true),
          //     orderBy('URL_recording'),
          //     where('language', '==', queryParams.language),
          //     where('voiceType', 'in', [...queryParams.age.split(',')]),
          //     startAfter(lastVisibleDoc),
          //     limit(limitFilter)
          //   );
          // }

          // !LANGUAGE
          // GENDER
          // AGE

          // if (
          //   queryParams?.age &&
          //   queryParams?.gender &&
          //   !queryParams.language
          // ) {
          //   next = query(
          //     collection(db, 'users'),
          //     orderBy('isHiddenFromLibrary'),
          //     where('isHiddenFromLibrary', '!=', true),
          //     orderBy('URL_recording'),
          //     where('gender', '==', queryParams.gender),
          //     where('voiceType', 'in', [...queryParams.age.split(',')]),
          //     startAfter(lastVisibleDoc),
          //     limit(limitFilter)
          //   );
          // }

          // LANGUAGE
          // !GENDER
          // !AGE

          // if (
          //   queryParams?.language &&
          //   !queryParams?.gender &&
          //   !queryParams.age
          // ) {
          //   next = query(
          //     collection(db, 'users'),
          //     orderBy('isHiddenFromLibrary'),
          //     where('isHiddenFromLibrary', '!=', true),
          //     orderBy('URL_recording'),
          //     where('language', '==', queryParams.language),
          //     startAfter(lastVisibleDoc),
          //     limit(limitFilter)
          //   );
          // }

          // !LANGUAGE
          // !GENDER
          // AGE

          // if (
          //   queryParams?.age &&
          //   !queryParams?.language &&
          //   !queryParams?.gender
          // ) {
          //   next = query(
          //     collection(db, 'users'),
          //     orderBy('isHiddenFromLibrary'),
          //     where('isHiddenFromLibrary', '!=', true),
          //     orderBy('URL_recording'),
          //     where('voiceType', 'in', [...queryParams.age.split(',')]),
          //     startAfter(lastVisibleDoc),
          //     limit(limitFilter)
          //   );
          // }

          // !LANGUAGE
          // GENDER
          // !AGE

          // if (
          //   queryParams?.gender &&
          //   !queryParams?.language &&
          //   !queryParams?.age
          // ) {
          //   next = query(
          //     collection(db, 'users'),
          //     orderBy('isHiddenFromLibrary'),
          //     where('isHiddenFromLibrary', '!=', true),
          //     orderBy('URL_recording'),
          //     where('gender', '==', queryParams.gender),
          //     startAfter(lastVisibleDoc),
          //     limit(limitFilter)
          //   );
          // }

          // LANGUAGE
          // GENDER
          // AGE

          // if (
          //   queryParams?.language &&
          //   queryParams?.gender &&
          //   queryParams?.age
          // ) {
          //   next = query(
          //     collection(db, 'users'),
          //     orderBy('isHiddenFromLibrary'),
          //     where('isHiddenFromLibrary', '!=', true),
          //     orderBy('URL_recording'),
          //     where('language', '==', queryParams?.language),
          //     where('gender', '==', queryParams?.gender),
          //     where('voiceType', 'in', [...queryParams.age.split(',')]),
          //     startAfter(lastVisibleDoc),
          //     limit(limitFilter)
          //   );
          // }

          // !LANGUAGE
          // !GENDER
          // !AGE
          // !ACCENT
          // SORT BY

          if (
            !queryParams?.language &&
            !queryParams?.gender &&
            !queryParams?.age &&
            !queryParams?.accent &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !ACCENT
          // !LANGUAGE
          // !GENDER
          // AGE
          // SORT BY

          if (
            !queryParams?.language &&
            !queryParams?.gender &&
            !queryParams?.accent &&
            queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !ACCENT
          // !LANGUAGE
          // !AGE
          // GENDER
          // SORT BY

          if (
            !queryParams?.language &&
            !queryParams?.accent &&
            queryParams?.gender &&
            !queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !GENDER
          // !AGE
          // !ACCENT
          // LANGUAGE
          // SORT BY

          if (
            queryParams?.language &&
            !queryParams?.gender &&
            !queryParams?.accent &&
            !queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),

                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),

                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),

                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !ACCENT
          // !LANGUAGE
          // GENDER
          // AGE
          // SORT BY

          if (
            !queryParams?.language &&
            !queryParams?.accent &&
            queryParams?.gender &&
            queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !ACCENT
          // !GENDER
          // LANGUAGE
          // AGE
          // SORT BY

          if (
            queryParams?.language &&
            !queryParams?.accent &&
            !queryParams?.gender &&
            queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                // where('accent', '==', queryParams.accent),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                // where('accent', '==', queryParams.accent),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                // where('accent', '==', queryParams.accent),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !ACCENT
          // !AGE
          // GENDER
          // LANGUAGE
          // SORT BY

          if (
            queryParams?.language &&
            !queryParams?.accent &&
            queryParams?.gender &&
            !queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('gender', '==', queryParams.gender),
                where('language', '==', queryParams?.language),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !GENDER
          // !AGE
          // ACCENT
          // LANGUAGE
          // SORT BY

          if (
            queryParams?.language &&
            !queryParams?.gender &&
            // queryParams?.accent &&
            !queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !GENDER
          // ACCENT
          // LANGUAGE
          // AGE
          // SORT BY

          if (
            queryParams?.language &&
            !queryParams?.gender &&
            // queryParams?.accent &&
            queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                where('language', '==', queryParams?.language),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                where('language', '==', queryParams?.language),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // !AGE
          // ACCENT
          // LANGUAGE
          // GENDER
          // SORT BY

          if (
            queryParams?.language &&
            queryParams?.gender &&
            // queryParams?.accent &&
            !queryParams?.age &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                where('gender', '==', queryParams.gender),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                where('gender', '==', queryParams.gender),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                where('gender', '==', queryParams.gender),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          // LANGUAGE
          // GENDER
          // ACCENT
          // AGE
          // SORT BY

          if (
            queryParams?.language &&
            queryParams?.gender &&
            queryParams?.age &&
            // queryParams?.accent &&
            queryParams?.sortBy
          ) {
            if (queryParams?.sortBy === '-inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                where('gender', '==', queryParams.gender),
                orderBy('inserted', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'inserted') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                where('gender', '==', queryParams.gender),
                orderBy('inserted', 'asc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            } else if (queryParams?.sortBy === 'none') {
              next = query(
                collection(db, 'users'),
                orderBy('isHiddenFromLibrary'),
                where('isHiddenFromLibrary', '!=', true),
                where('language', '==', queryParams?.language),
                where('voiceType', 'in', [...queryParams.age.split(',')]),
                where('gender', '==', queryParams.gender),
                orderBy('describeYourself', 'desc'),
                orderBy('URL_recording'),
                // where('accent', '==', queryParams?.accent),
                startAfter(lastVisibleDoc),
                limit(limitFilter)
              );
            }
          }

          const DocSnapshotNext = await getDocs(next as any);
          const isCollectionEmpty = DocSnapshotNext.size === 0;

          if (!isCollectionEmpty) {
            const moreUsersData = DocSnapshotNext.docs.map((userData) =>
              userData.data()
            );
            setUsersData([...usersData, ...moreUsersData]);
            setLastVisibleDoc(
              DocSnapshotNext.docs[DocSnapshotNext.docs.length - 1]
            );
            setIsLoading(false);
          }

          setIsLoading(false);
        };
        fetchNextData();
      }
    },
    [db, lastVisibleDoc, usersData]
  );

  React.useEffect(() => {
    const path = location?.pathname.includes('user');
    if (!path) {
      navigate({
        pathname: `${ROUTES.BASE}`,
        search: createSearchParams({
          ...(queryParams as any),
        }).toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  function onSetFilter(filters: any): void {
    const activeFiltersKeys: string[] = Object.keys(filters);

    const activeFilters = activeFiltersKeys.reduce((acc, item) => {
      const currentObject = filters[item];

      return {
        ...acc,
        [item]: currentObject[0] && currentObject.join(',').trim(),
      };
    }, {});

    setQueryParams(activeFilters);
  }

  const onSelectChange = (o: Option): void => {
    setQueryParams({ ...queryParams, sortBy: o.value });
  };

  function sortByOptions(t: any): Array<{ value: string; label: string }> {
    return [
      { value: 'none', label: t('orderBy.none') },
      { value: 'inserted', label: t('orderBy.ascInserted') },
      { value: '-inserted', label: t('orderBy.descInserted') },
    ];
  }

  const options = sortByOptions(t);

  const defaultValue =
    options.find(
      (o: any) => o.value === (queryParams?.sortBy as string) || ''
    ) || options[0];

  const ref = React.useRef<HTMLDivElement>(null);

  const handleScroll = React.useCallback(() => {
    if (ref?.current) {
      if (window.innerHeight + window.scrollY >= ref.current.offsetHeight) {
        fetchMore(queryParams);
      }
    }
  }, [fetchMore, queryParams]);

  React.useEffect(() => {
    if (ref?.current) {
      if (window.innerHeight + window.scrollY >= ref.current.offsetHeight) {
        fetchMore(queryParams);
      }
    }
  }, [fetchMore, queryParams]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div className={styles.wrapperFilters}>
        <div className={styles.filterContainer}>
          <Filters
            filters={filtersFacet}
            initialState={filtersFromUrl}
            onSetFilters={onSetFilter}
            currentUserData={currentUserData}
          />
        </div>
        <div className={styles.selectWrapper}>
          <BorderlessSelect
            value={defaultValue}
            onChange={onSelectChange}
            options={options}
          />
        </div>
      </div>

      <div className={styles.cardContainer} ref={ref}>
        {usersData &&
          usersData.map((userCardInfo: any, index: number) => (
            <UserCard
              firstname={userCardInfo?.firstname}
              describeYourself={userCardInfo?.describeYourself}
              voiceType={userCardInfo?.voiceType}
              userId={userCardInfo?.id}
              key={uuid4()}
              language={userCardInfo?.language}
              urlRec={userCardInfo?.URL_recording}
              profilePic={userCardInfo?.profile_photo}
              userType={currentUserData?.userType}
              isLogged={user && user}
              avatarPhoto={userCardInfo?.avatar_photo}
              isModelReady={userCardInfo?.isModelReady}
            />
          ))}
      </div>
      <div className={styles.showMoreContainer}>
        {isLoading && usersData.length < totalUserCard && <Spinner isMid />}
      </div>
      {usersData.length === 0 && !isLoading && (
        <div className={styles.noFavoriteContainer}>
          <SuccessMessageOnBoarding
            isCenter
            title=""
            message={t('noResults')}
            leadingIcon={
              <Button
                style={{
                  backgroundColor: getCssVariable('orange-light'),
                  cursor: 'default',
                }}
                type={BUTTON_TYPE.RECORDING}
                size={BUTTON_SIZE.ONLY_ICON}
                leadingIcon={
                  <FeatherIcon
                    strokeWidth="1px"
                    icon="user-x"
                    color={'var(--orange)'}
                    size="40"
                  />
                }
              ></Button>
            }
          />
        </div>
      )}
      <Outlet />
    </>
  );
}
