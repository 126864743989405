/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { format } from 'date-fns';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Avatar, { AVATAR_SIZE } from '@components/Avatar';
import { useFirestore, useUser } from 'reactfire';
import FeatherIcon from 'feather-icons-react';
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import Player from '@components/Player';
import { getCssVariable } from '@utils/index';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserCardContext } from '@contexts/UserCardContext';
import { noop } from 'lodash';

import Badge, { BADGE_TYPE } from '@components/Badge';

export type UserCardProps = {
  voiceType: string;
  describeYourself?: string;
  firstname: string;
  userId?: string;
  language: string;
  userType?: string;
  urlRec?: string;
  profilePic?: string;
  onClick?: () => void;
  isHidden?: boolean;
  avatarPhoto: string;
  isModelReady: boolean;
};

export default function UserCardModal({
  firstname,
  userType,
  describeYourself,
  voiceType,
  onClick,
  profilePic,
  userId,
  isHidden,
  language,
  avatarPhoto,
  urlRec,
  isModelReady,
}: UserCardProps) {
  const { t } = useTranslation('library');
  const { data: user } = useUser();
  const db = useFirestore();
  const {
    usersFavorites,
    setUsersFavorites,
    setRequestedVoices,
    requestedVoices,
    // currentRequestedVoice,
    setCurrentRequestedVoice,
  } = React.useContext(UserCardContext);
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentSong, setCurrentSong] = React.useState<any>({ url: urlRec });
  const [privateUserData, setPrivateUserData] = React.useState<any>({});
  const [isMaxRequest, setIsMaxRequest] = React.useState<boolean>(
    requestedVoices.length >= 3
  );
  const [step, setStep] = React.useState<number>(0);
  const location: any = useLocation();
  const [error, setError] = React.useState<{ message: string }>({
    message: '',
  });

  // const path = location?.pathname.includes('user');

  function renderLanguage(lang: string) {
    switch (lang) {
      case 'en-EN':
        return t('userCard.language.en');
      case 'it-IT':
        return t('userCard.language.it');
      case 'es-ES':
        return t('userCard.language.es');
      case 'de-DE':
        return t('userCard.language.de');
      case 'fr-FR':
        return t('userCard.language.fr');
      default:
        return '';
    }
  }

  const addToFav = React.useCallback(
    async (userId: string) => {
      if (user) {
        await setDoc(
          doc(db, `users/${user.uid}/private`, 'library'),
          {
            favorites: arrayUnion(userId),
          },
          { merge: true }
        );
      }
    },
    [db, user]
  );

  const removeFromFav = React.useCallback(
    async (userId: string) => {
      if (user) {
        await setDoc(
          doc(db, `users/${user.uid}/private`, 'library'),
          {
            favorites: arrayRemove(userId),
          },
          { merge: true }
        );
      }
    },
    [db, user]
  );
  React.useEffect(() => {
    async function getData() {
      const userPrivateRef = doc(db, `users/${user?.uid}/private`, 'library');
      const docPrivateSnap = await getDoc(userPrivateRef);
      if (docPrivateSnap.exists()) {
        const userPrivateData = docPrivateSnap.data();
        setPrivateUserData(userPrivateData);
      }
    }
    getData();
  }, [db, user?.uid]);

  async function getThisVoice() {
    setStep(step + 1);
    if (step === 1) {
      setCurrentRequestedVoice(userId);
      const date = format(new Date(), 'yyyy-MM-dd--HH:mm:ss');
      try {
        await setDoc(doc(db, `mail/${user?.uid}/notifications`, date as any), {
          from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
          replyTo: user?.email,
          cc: process.env.REACT_APP_FIREBASE_EMAIL_CC,
          to: process.env.REACT_APP_FIREBASE_EMAIL_RECIPIENT,
          message: {
            subject: `Requested voice from ${user?.displayName}`,
            text: `User ${user?.displayName} with email ${user?.email} and id ${user?.uid} requested the voice of donor ${firstname} with ID ${userId}. \n Patient info: \n First name: ${privateUserData?.firstname} \n Last name: ${privateUserData?.lastname} \n Gender: ${privateUserData?.gender}  \n Aac Model: ${privateUserData?.aacModel} \n Therapist: ${privateUserData?.therapist}  \n Why need voice: ${privateUserData?.whyNeedVoice} \n Age: ${privateUserData?.age} \n Phone: ${privateUserData?.phone}  \n Birthday: ${privateUserData?.birthday} \n Country: ${privateUserData?.country} \n Email: ${privateUserData?.email}. \n Click here to enable the donor user to record the voice model: ${process.env.REACT_APP_ENABLE_RECORDING_FN}${userId} \n The link activate/enable the button to start recording the voice model in his dashboard.`,
          },
        });

        const chosenVoicesRefPublic = doc(db, 'chosenVoices', userId as any);
        const docSnapPublic = await getDoc(chosenVoicesRefPublic);

        if (docSnapPublic.exists()) {
          await updateDoc(doc(db, 'chosenVoices', userId as any), {
            hasChosenMyVoice: increment(1),
          });
        } else {
          await setDoc(doc(db, 'chosenVoices', userId as any), {
            hasChosenMyVoice: 1,
          });
        }

        await setDoc(
          doc(db, `users/${user?.uid}/private`, 'library'),
          {
            currentRequestedVoice: userId,
          },
          { merge: true }
        );
        setRequestedVoices([...requestedVoices, userId]);
        await setDoc(
          doc(db, `users/${user?.uid}/private`, 'library'),
          {
            requestedVoices: arrayUnion(userId),
          },
          { merge: true }
        );
      } catch (error) {
        console.log(error, 'error sending');
        setError({
          message: t('userCard.modalCard.errors.general'),
        });
      }
    }
  }

  function translateVoiceTypeLabel(voice: string) {
    switch (voice) {
      case 'middle':
        return t('userCard.voiceType.middle');
      case 'young':
        return t('userCard.voiceType.young');
      case 'senior':
        return t('userCard.voiceType.senior');
      default:
        return voice;
    }
  }

  function toggleFav() {
    if (usersFavorites && !usersFavorites?.includes(userId) && userId) {
      addToFav(userId);
      setUsersFavorites([...usersFavorites, userId]);
    } else if (usersFavorites && usersFavorites?.includes(userId) && userId) {
      removeFromFav(userId);
      const favorites = usersFavorites.filter((el: string) => el !== userId);
      setUsersFavorites(favorites);
    }
  }

  const audioElem = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (isPlaying) {
      audioElem?.current?.play();
    } else {
      audioElem?.current?.pause();
    }
  }, [isPlaying]);

  React.useEffect(() => {
    setStep(location?.state?.step ?? 0);
  }, [location]);

  const onPlaying = () => {
    if (audioElem && audioElem?.current && audioElem?.current?.duration) {
      const duration = audioElem?.current?.duration as any;
      const ct = audioElem?.current?.currentTime as any;

      setCurrentSong({
        ...currentSong,
        progress: (ct / duration) * 100,
        length: duration,
      });
    }
  };

  function goToBase() {
    navigate(-1);
    // navigate(ROUTES.BASE, { replace: true, state: { referrer: 'modal' } });
    setIsMaxRequest(requestedVoices.length === 3);
  }

  function maxRequest() {
    setStep(-1);
  }

  return (
    <div className={styles.container} onClick={onClick}>
      {step === 0 && (
        <>
          <div className={styles.dataContainer}>
            <div className={styles.userDataWrapper}>
              <div className={styles.closeModal}>
                <FeatherIcon
                  strokeWidth={'2px'}
                  aria-hidden="true"
                  color={getCssVariable('neutral600')}
                  fill={'var(--neutral600)'}
                  onClick={goToBase}
                  icon="x"
                  size="22"
                />
              </div>
              <div className={styles.avatarContainer}>
                <Avatar
                  name={firstname}
                  image={profilePic ? profilePic : avatarPhoto}
                  size={AVATAR_SIZE.BIG}
                />
              </div>
              <div className={styles.userData}>
                {firstname && <h3>{firstname}</h3>}
                <div className={styles.userInfo}>
                  <FeatherIcon
                    strokeWidth={'2px'}
                    aria-hidden="true"
                    icon="message-circle"
                    size="18"
                  />
                  <p>{translateVoiceTypeLabel(voiceType)}</p>
                  <p className={styles.separator}>·</p>
                  <FeatherIcon
                    strokeWidth={'2px'}
                    aria-hidden="true"
                    icon="globe"
                    size="18"
                  />
                  <p>{renderLanguage(language)}</p>
                </div>
              </div>
            </div>

            <div className={styles.badgeContainer}>
              <Badge
                type={
                  isModelReady ? BADGE_TYPE.AVAILABLE : BADGE_TYPE.NOT_AVAILABLE
                }
              >
                {isModelReady
                  ? t('userCard.modelStatus.available')
                  : t('userCard.modelStatus.not_available')}
              </Badge>
            </div>
            <div className={styles.description}>
              {describeYourself && <p>{describeYourself}</p>}
            </div>
          </div>
          <div className={styles.footerCard}>
            <audio
              onTimeUpdate={onPlaying}
              ref={audioElem}
              src={!isHidden ? urlRec : ''}
            />
            <Player
              currentSong={currentSong}
              audioElem={audioElem}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />

            {urlRec && (
              <>
                <Button
                  style={{ maxHeight: '48px' }}
                  onClick={
                    requestedVoices.includes(userId)
                      ? // currentRequestedVoice !== '' && currentRequestedVoice === userId
                        noop
                      : !isMaxRequest
                      ? getThisVoice
                      : maxRequest
                  }
                  fullWidth
                  mode={BUTTON_MODE.BASIC}
                  // disabled={
                  //   currentRequestedVoice !== '' && currentRequestedVoice !== userId
                  // }
                  type={
                    requestedVoices.includes(userId)
                      ? // currentRequestedVoice !== '' && currentRequestedVoice === userId
                        BUTTON_TYPE.REQUESTED
                      : BUTTON_TYPE.PRIMARY
                  }
                  size={
                    requestedVoices.includes(userId)
                      ? // currentRequestedVoice !== '' && currentRequestedVoice === userId
                        BUTTON_SIZE.WITH_ICON
                      : BUTTON_SIZE.TEXT
                  }
                  leadingIcon={
                    requestedVoices.includes(userId) && (
                      // currentRequestedVoice !== '' &&
                      // currentRequestedVoice === userId && (
                      <FeatherIcon
                        strokeWidth="2px"
                        aria-hidden="true"
                        color={getCssVariable('white')}
                        icon="check"
                        size="20"
                      />
                    )
                  }
                >
                  {requestedVoices?.includes(userId)
                    ? // currentRequestedVoice !== '' && currentRequestedVoice === userId
                      t('userCard.modalCard.step0.label.requested')
                    : t('userCard.modalCard.step0.label.request')}
                </Button>

                <Button
                  onClick={toggleFav}
                  type={
                    usersFavorites && usersFavorites?.includes(userId)
                      ? BUTTON_TYPE.ERROR
                      : BUTTON_TYPE.PRIMARY
                  }
                  fullWidth
                  mode={BUTTON_MODE.OUTLINE}
                  size={BUTTON_SIZE.WITH_ICON}
                  leadingIcon={
                    <FeatherIcon
                      strokeWidth={'2px'}
                      aria-hidden="true"
                      color={getCssVariable(
                        usersFavorites && usersFavorites?.includes(userId)
                          ? 'red'
                          : 'blue'
                      )}
                      fill={
                        usersFavorites && usersFavorites?.includes(userId)
                          ? 'red'
                          : 'transparent'
                      }
                      icon="heart"
                      size="18"
                    />
                  }
                >
                  {usersFavorites && usersFavorites?.includes(userId)
                    ? t('userCard.modalCard.step0.label.removeFav')
                    : t('userCard.modalCard.step0.label.addFav')}
                </Button>
              </>
            )}
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <div className={`${styles.dataContainer} ${styles.step1}`}>
            <div className={styles.userDataWrapper}>
              <div className={styles.closeModal}>
                <FeatherIcon
                  strokeWidth={'2px'}
                  aria-hidden="true"
                  color={getCssVariable('neutral600')}
                  fill={'var(--neutral600)'}
                  onClick={goToBase}
                  icon="x"
                  size="22"
                />
              </div>
              <Button
                style={{
                  backgroundColor: getCssVariable('orange-light'),
                  cursor: 'default',
                  padding: '36px',
                  marginBottom: '16px',
                }}
                type={BUTTON_TYPE.RECORDING}
                size={BUTTON_SIZE.ONLY_ICON}
                leadingIcon={
                  <FeatherIcon
                    strokeWidth="1px"
                    icon="message-square"
                    color={'var(--orange)'}
                    size="48"
                  />
                }
              ></Button>
            </div>

            <h3 style={{ textAlign: 'center', marginBottom: '8px' }}>
              {t('userCard.modalCard.step1.title')}
            </h3>
            <div className={styles.description}>
              {t('userCard.modalCard.step1.subTitle')}
            </div>
            {t('userCard.modalCard.step1.description') && (
              <div className={styles.maxRequest}>
                {requestedVoices.length === 0
                  ? t('userCard.modalCard.step1.description')
                  : requestedVoices.length === 1
                  ? t('userCard.modalCard.step1.description2')
                  : t('userCard.modalCard.step1.description3')}
              </div>
            )}
          </div>
          <div className={styles.footerCard}>
            <Button
              onClick={!isMaxRequest ? getThisVoice : maxRequest}
              type={BUTTON_TYPE.PRIMARY}
              fullWidth
              mode={BUTTON_MODE.BASIC}
            >
              {t('userCard.modalCard.step1.label.request')}
            </Button>

            <Button
              onClick={goToBase}
              type={BUTTON_TYPE.PRIMARY}
              fullWidth
              mode={BUTTON_MODE.OUTLINE}
            >
              {t('userCard.modalCard.step1.label.notNow')}
            </Button>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className={`${styles.dataContainer} ${styles.step1}`}>
            <div className={styles.userDataWrapper}>
              <div className={styles.closeModal}>
                <FeatherIcon
                  strokeWidth={'2px'}
                  aria-hidden="true"
                  color={getCssVariable('neutral600')}
                  fill={'var(--neutral600)'}
                  onClick={goToBase}
                  icon="x"
                  size="22"
                />
              </div>
              <Button
                style={{
                  backgroundColor: getCssVariable(
                    error?.message ? 'red-light' : 'green-light'
                  ),
                  cursor: 'default',
                  padding: '36px',
                  marginBottom: '16px',
                }}
                type={BUTTON_TYPE.RECORDING}
                size={BUTTON_SIZE.ONLY_ICON}
                leadingIcon={
                  <FeatherIcon
                    strokeWidth="1px"
                    icon={error?.message ? 'x' : 'check'}
                    color={error?.message ? 'var(--red)' : 'var(--green)'}
                    size="48"
                  />
                }
              ></Button>
            </div>

            <h3 style={{ textAlign: 'center', marginBottom: '8px' }}>
              {error.message
                ? error?.message
                : t('userCard.modalCard.step2.title')}
            </h3>
            <div className={styles.description}>
              {!error.message && <>{t('userCard.modalCard.step2.subTitle')}</>}
            </div>
          </div>

          <div className={styles.footerCard}>
            <Button
              onClick={goToBase}
              type={BUTTON_TYPE.PRIMARY}
              fullWidth
              mode={BUTTON_MODE.BASIC}
            >
              {!error.message
                ? t('userCard.modalCard.step2.label.thanks')
                : t('userCard.modalCard.step2.label.goBack')}
            </Button>
          </div>
        </>
      )}

      {step === -1 && (
        <>
          <div className={`${styles.dataContainer} ${styles.step1}`}>
            <div className={styles.userDataWrapper}>
              <div className={styles.closeModal}>
                <FeatherIcon
                  strokeWidth={'2px'}
                  aria-hidden="true"
                  color={getCssVariable('neutral600')}
                  fill={'var(--neutral600)'}
                  onClick={goToBase}
                  icon="x"
                  size="22"
                />
              </div>
              <Button
                style={{
                  backgroundColor: getCssVariable('yellow-light'),
                  cursor: 'default',
                  padding: '36px',
                  marginBottom: '16px',
                }}
                type={BUTTON_TYPE.RECORDING}
                size={BUTTON_SIZE.ONLY_ICON}
                leadingIcon={
                  <FeatherIcon
                    strokeWidth="1px"
                    icon="flag"
                    color={'var(--yellow)'}
                    size="48"
                  />
                }
              ></Button>
            </div>

            <h3 style={{ textAlign: 'center', marginBottom: '8px' }}>
              {t('userCard.modalCard.maxRequest.title')}
            </h3>
            <div
              className={styles.description}
              style={{ maxWidth: '360px', lineHeight: '24px' }}
            >
              {t('userCard.modalCard.maxRequest.subTitle')}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
