/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import FeatherIcon from 'feather-icons-react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import PasswordResetForm from '@components/PasswordResetForm';
import { ROUTES } from '@routes/routes';
import { useNavigate } from 'react-router-dom';
import Header from '@components/Header';
import SplitLayout from '@components/SplitLayout';
import LayoutWrapper from '@components/LayoutWrapper';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import SignUpRightSide from '@components/SignUpRightSide';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';

import { getCssVariable } from '@utils/index';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import { useUser } from 'reactfire';
import { Helmet } from 'react-helmet';

export default function PasswordReset() {
  const auth = getAuth();
  const url = `${window.location.protocol}//${window.location.host}${ROUTES.NEW_PASSWORD}`;
  const { t } = useTranslation('passwordReset');
  const [error, setError] = React.useState<string>('');
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [succesfullySent, setFuccesfullySent] = React.useState<boolean>(false);
  const [userEmail, setUserEmail] = React.useState('');
  const navigate = useNavigate();
  const { data: user } = useUser();
  const onResetPassword = React.useCallback(
    async (email: string) => {
      try {
        setUserEmail(email);
        await sendPasswordResetEmail(auth, email, { url });
        setFuccesfullySent(true);
      } catch (err) {
        if (err instanceof Error) {
          setError(err?.message);
          setFuccesfullySent(false);
        }
      } finally {
        setIsSending(false);
      }
    },
    [auth]
  );

  function onSubmitResetPasswordForm({ email }: { email: string }): void {
    setIsSending(true);
    onResetPassword(email);
  }

  function goToSignIn() {
    navigate(ROUTES.SIGN_IN);
  }
  function goToLibrary() {
    navigate(ROUTES.BASE);
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
        firstButton={
          user ? (
            <Button
              onClick={goToLibrary}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
              size={BUTTON_SIZE.WITH_ICON}
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  icon="message-square"
                  color={'var(--blue)'}
                  size="20"
                />
              }
            >
              Library
            </Button>
          ) : (
            <Button
              onClick={goToSignIn}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
            >
              Sign in
            </Button>
          )
        }
      />

      <LayoutWrapper>
        <SplitLayout
          left={
            <>
              <div className={styles.wrapper}>
                {succesfullySent ? (
                  <>
                    <SuccessMessageOnBoarding
                      title={t('successMessage.title')}
                      message={t('successMessage.message', {
                        value: userEmail,
                      })}
                      leadingIcon={
                        <Button
                          style={{
                            backgroundColor: getCssVariable('orange-light'),
                            cursor: 'default',
                          }}
                          type={BUTTON_TYPE.RECORDING}
                          size={BUTTON_SIZE.ONLY_ICON}
                          leadingIcon={
                            <FeatherIcon
                              strokeWidth="1px"
                              icon="mail"
                              color={'var(--orange)'}
                              size="40"
                            />
                          }
                        ></Button>
                      }
                    />

                    <Button
                      onClick={goToSignIn}
                      type={BUTTON_TYPE.PRIMARY}
                      fullWidth
                    >
                      {t('backToLogin')}
                    </Button>
                  </>
                ) : (
                  <div className={styles.container}>
                    <HeaderOnBoardingWrapper title={t('titlePage')} />
                    <PasswordResetForm
                      onSubmit={onSubmitResetPasswordForm}
                      isSending={isSending}
                    />
                    {error.length ? (
                      <p className={styles.errorMessage}>{error}</p>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          }
          right={<SignUpRightSide />}
        />
      </LayoutWrapper>
    </>
  );
}
