import styles from './styles.module.scss';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import * as React from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { ROUTES } from '@routes/routes';
import { useNavigate } from 'react-router-dom';
import { useUser, useFirestore } from 'reactfire';

import { useForm } from 'react-hook-form';
import { getCssVariable } from '@utils/index';
// import Label, { LABEL_TYPE } from '@components/Label';

export default function OnBoardingContentChooseProfile() {
  const { t } = useTranslation('chooseProfile');
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();

  const { register, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
  });

  const userProfileValue = watch('userProfile');

  const getUserData = React.useCallback(async () => {
    if (user) {
      const userRef = doc(db, `users/${user.uid}/private`, 'library');
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const userType = userData?.userType;

        if (userType === 'both' || userType === 'library') {
          navigate(`${ROUTES.BASE}`);
        } else {
          navigate(`${ROUTES.FINISH_REGISTRATION}`);
        }
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db, navigate]);

  function handleOnSubmit({ userProfile }: { userProfile: string }) {
    if (userProfile === 'autodonate') {
      window.open(
        `${process.env.REACT_APP_VFP_DONATE_URL}/autodonate`,
        '_blank'
      );
    } else {
      navigate(ROUTES.SIGN_UP);
    }
  }

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <div className={styles.container}>
      <HeaderOnBoardingWrapper
        leadingIcon={
          <Button
            style={{
              backgroundColor: getCssVariable('orange-light'),
              cursor: 'default',
            }}
            type={BUTTON_TYPE.RECORDING}
            size={BUTTON_SIZE.ONLY_ICON}
            leadingIcon={
              <FeatherIcon
                strokeWidth="1px"
                icon="message-square"
                color={'var(--orange)'}
                size="40"
              />
            }
          ></Button>
        }
        title={t('headerOnBoardingWrapper.titlePage', {
          name: user?.displayName?.split(' ')[0],
        })}
      />
      <form onSubmit={handleSubmit(handleOnSubmit as any)} noValidate>
        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <div className={styles.radioContainer}>
              <input
                {...register('userProfile', { required: true })}
                type="radio"
                name="userProfile"
                value="autodonate"
                id="autodonate"
                defaultChecked
              />
              <div className={styles.wrapperHelper}>
                <div className={styles.onFocusBorder} />
              </div>
              <span className={styles.checkround} />
            </div>
            <div className={styles.descriptionBox}>
              <div>
                <label htmlFor="autodonate">{t('autodonate.title')}</label>
                <p className="extra-small">{t('autodonate.description')}</p>
              </div>
              {/* <Label type={LABEL_TYPE.PRIMARY}></Label> */}
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <div className={styles.radioContainer}>
              <input
                {...register('userProfile', { required: true })}
                type="radio"
                name="userProfile"
                value="libraryUser"
                id="libraryUser"
              />
              <div className={styles.wrapperHelper}>
                <div className={styles.onFocusBorder} />
              </div>
              <span className={styles.checkround} />
            </div>
            <div className={styles.descriptionBox}>
              <div>
                <label htmlFor="libraryUser">{t('libraryUser.title')}</label>
                <p className="extra-small">{t('libraryUser.description')}</p>
              </div>
            </div>
          </div>
        </div>

        <Button
          type={BUTTON_TYPE.PRIMARY}
          mode={BUTTON_MODE.BASIC}
          fullWidth
          disabled={!userProfileValue}
        >
          {t('letsStart')}
        </Button>
      </form>
    </div>
  );
}
