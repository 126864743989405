export const getCssVariable = (variableName: string): string => {
  const value = getComputedStyle(document.documentElement).getPropertyValue(
    `--${variableName}`
  );
  if (!value) {
    console.log(`${variableName} not found.`);
    return '';
  }
  return value;
};

export const emailRegex = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
  'i'
);

export const mobilePhoneRegex = new RegExp(
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);

export const homePhoneRegex = new RegExp(/^0[0-9]{8,9}$/);

export function truncate(input: string, length: number) {
  if (input.length > length) {
    return input.substring(0, length) + '...';
  }
  return input;
}

export const concatStrings = (strings: any, separator = ' ') => {
  return strings.filter(Boolean).join(separator);
};
