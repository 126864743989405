import { WithChildren } from '@utils/types/helpers';
import styles from './styles.module.scss';

export enum BUTTON_MODE {
  BASIC = 'basic',
  OUTLINE = 'outline',
  GHOST = 'ghost',
  MENU = 'menu',
}
export enum BUTTON_TYPE {
  PRIMARY = 'primary',
  ERROR = 'error',
  DEFAULT = 'default',
  RECORDING = 'recording',
  DONE = 'done',
  REQUESTED = 'requested',
}
export enum BUTTON_SIZE {
  TEXT = 'text',
  WITH_ICON = 'with-icon',
  ONLY_ICON = 'only-icon',
}

type Props = WithChildren<{
  leadingIcon?: React.ReactNode;
  type?: BUTTON_TYPE;
  onClick?: any;
  size?: BUTTON_SIZE;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  mode?: BUTTON_MODE;
  url?: string;
  isActive?: boolean;
}>;

export default function Button({
  children,
  disabled = false,
  onClick,
  fullWidth = false,
  leadingIcon,
  isActive = false,
  url,
  style = {},
  type = BUTTON_TYPE.PRIMARY,
  size = BUTTON_SIZE.TEXT,
  mode = BUTTON_MODE.BASIC,
}: Props) {
  const LeadingIcon = leadingIcon ? leadingIcon : null;

  return (
    <button
      disabled={disabled ? true : false}
      onClick={onClick}
      style={style}
      className={`${styles.container} ${fullWidth ? styles.fullWidth : ''} ${
        styles[type]
      } ${styles[size]} ${styles[mode]} ${isActive ? styles.active : ''}
 
      `}
    >
      {LeadingIcon && <div className={styles.leadingIcon}>{leadingIcon}</div>}
      <span>{children}</span>
    </button>
  );
}
