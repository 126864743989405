import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updateProfile,
} from 'firebase/auth';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import Spinner from '@components/Spinner';
import { getCssVariable } from '@utils/index';

type IProps = {
  closeModal: any;
  currentAuthUser: any;
  newEmail: string;
  db: any;
  user: any;
  firstName: string;
  lastName: string;
};

export default function UserPrompt({
  newEmail,
  closeModal,
  currentAuthUser,

  db,
  user,
  lastName,
  firstName,
}: IProps) {
  const { t } = useTranslation('dashboard');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const oldPassValue = watch('oldPassword');

  const [isLoading, setIsLoading] = React.useState(false);
  const [changeEmailSuccessfully, setChangeEmailSuccessfully] =
    React.useState(false);
  const [error, setError] = React.useState<{ message: string }>({
    message: '',
  });
  const [isDirty, setIsDirty] = React.useState<{
    oldPassword: boolean;
  }>({
    oldPassword: false,
  });

  const { ref: oldPasswordRef, ...oldPasswordInputProps } = register(
    'oldPassword',
    {
      required: t('personalDetails.form.errorsState.invalid.password'),
    }
  );

  const handleOnSubmit = React.useCallback(
    async ({ oldPassword }: any) => {
      try {
        setIsLoading(true);
        setIsDirty({ oldPassword: oldPassword.length > 0 });

        const credential = EmailAuthProvider.credential(
          currentAuthUser.email,
          oldPassword as string
        );

        await reauthenticateWithCredential(currentAuthUser, credential);
        await updateEmail(currentAuthUser, newEmail);
        await updateProfile(currentAuthUser, {
          displayName: `${firstName} ${lastName}`,
        });

        await updateDoc(doc(db, 'users', user.uid), {
          firstname: firstName,
        });

        await setDoc(
          doc(db, `users/${user.uid}/private`, 'library'),
          {
            firstname: firstName,
            lastname: lastName,
            email: newEmail,
          },
          { merge: true }
        );

        await setDoc(
          doc(db, `users/${user.uid}/private`, 'donor'),
          {
            firstname: firstName,
            lastname: lastName,
            email: newEmail,
          },
          { merge: true }
        );

        setChangeEmailSuccessfully(true);
        setIsLoading(false);
        setTimeout(() => {
          setChangeEmailSuccessfully(false);
          closeModal();
        }, 2000);
      } catch (error) {
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error?.code === 'auth/email-already-in-use' ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error?.code === 'auth/invalid-email'
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError({
            message: t('personalDetails.modal.errorsState.invalidEmail'),
          });
          setTimeout(() => {
            setError({ message: '' });
          }, 2000);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        } else if (error?.code === 'auth/wrong-password') {
          setError({
            message: t('personalDetails.modal.errorsState.wrongPassword'),
          });
          setTimeout(() => {
            setError({ message: '' });
          }, 2000);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(error?.message);
          setTimeout(() => {
            setError({ message: '' });
          }, 2000);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [db, closeModal, currentAuthUser, firstName, lastName, newEmail, user.uid]
  );

  return (
    <div className={styles.inner}>
      <div className={styles.header}>
        <h4>{t('personalDetails.modal.title')}</h4>
        <Button
          type={BUTTON_TYPE.DEFAULT}
          mode={BUTTON_MODE.GHOST}
          size={BUTTON_SIZE.ONLY_ICON}
          leadingIcon={
            <FeatherIcon
              strokeWidth="2px"
              aria-hidden="true"
              color={getCssVariable('neutral600')}
              onClick={closeModal}
              icon="x"
              size="24"
            />
          }
        ></Button>
      </div>
      <div className={styles.container}>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <Input
            hasValue={isDirty?.oldPassword ?? false}
            label={t('personalDetails.modal.form.labels.password')}
            type="password"
            placeholder={t('personalDetails.modal.form.placeholder.password')}
            inputRef={oldPasswordRef}
            {...oldPasswordInputProps}
            error={errors?.oldPassword?.message}
          />
          {error?.message && <p className={styles.error}>{error?.message}</p>}
          {changeEmailSuccessfully && (
            <p className={styles.success}>
              {t('personalDetails.modal.form.success.emailChanged')}
            </p>
          )}
        </form>
      </div>
      <div className={styles.footerModal}>
        <div className={styles.buttonsWrapper}>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            mode={BUTTON_MODE.BASIC}
            disabled={!oldPassValue}
            onClick={handleSubmit(handleOnSubmit)}
          >
            {isLoading ? (
              <Spinner isWhite isMid />
            ) : (
              t('personalDetails.modal.form.labels.update')
            )}
          </Button>
          <div className={styles.cancel} onClick={closeModal}>
            {t('personalDetails.modal.form.labels.cancel')}
          </div>
        </div>
      </div>
    </div>
  );
}
