import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import Loader from '@components/Loader';
import OnBoardingRightSide from '@components/OnBoardingRightSide';
import SplitLayout from '@components/SplitLayout';
import { ROUTES } from '@routes/routes';
import { doc, getDoc } from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useFirestore } from 'reactfire';

import OnBoardingContentChooseProfile from '@components/OnBoardingContentChooseProfile';

import { Helmet } from 'react-helmet';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';

export default function ChooseProfile() {
  const { t } = useTranslation('chooseProfile');

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const db = useFirestore();
  const { data: user } = useUser();

  React.useEffect(() => {
    async function getUser() {
      if (user) {
        setIsLoading(true);
        const userRefPublic = doc(db, 'users', user?.uid);
        const docSnapPublic = await getDoc(userRefPublic);

        const userDataPublic = docSnapPublic.data();
        const userType = userDataPublic?.userType;

        if (userType === 'both' || userType === 'library') {
          navigate(`${ROUTES.BASE}`);
        } else {
          navigate(`${ROUTES.FINISH_REGISTRATION}`);
        }
      } else {
        setIsLoading(false);
      }
    }
    getUser();
  }, [db, navigate, user]);

  function goToSignIn() {
    navigate(ROUTES.SIGN_IN);
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header
        firstButton={
          <Button
            onClick={goToSignIn}
            type={BUTTON_TYPE.PRIMARY}
            mode={BUTTON_MODE.GHOST}
          >
            Sign In
          </Button>
        }
      />

      <LayoutWrapper>
        <SplitLayout
          left={<OnBoardingContentChooseProfile />}
          right={<OnBoardingRightSide isChooseProfile />}
        />
      </LayoutWrapper>
    </>
  );
}
